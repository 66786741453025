export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library. The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: any;
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
  /**
   * The `Time` scalar type represents a time. The Time appears in a JSON
   * response as an ISO8601 formatted string, without a date component.
   */
  Time: any;
};

/** An account for an individual logged into Turn */
export type Account = {
  __typename?: "Account";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  languages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lastName?: Maybe<Scalars["String"]>;
  preferredLanguage?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** The required fields for creating an account over the API */
export type AccountInput = {
  email: Scalars["String"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  role?: InputMaybe<RoleType>;
};

export type AccountUpdateInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lastName?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  profilePicture?: InputMaybe<Scalars["String"]>;
};

/** All possible types of rules */
export enum ActionTypeType {
  CANCEL_TIMER = "CANCEL_TIMER",
  DISPATCH_WEBHOOK = "DISPATCH_WEBHOOK",
  REPLY = "REPLY",
  START_STACK = "START_STACK",
  START_THREAD = "START_THREAD",
  START_TIMER = "START_TIMER",
  UPDATE_CONTACT = "UPDATE_CONTACT",
}

/** Actions Card */
export type ActionsCard = {
  __typename?: "ActionsCard";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<ActionsCardType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
  webhookHmacSecret?: Maybe<Scalars["String"]>;
  webhookUrl?: Maybe<Scalars["String"]>;
};

/** Actions Card input object, for creating all types of action cards with */
export type ActionsCardIo = {
  webhookUrl?: InputMaybe<Scalars["String"]>;
};

/** All possible types of actions cards */
export enum ActionsCardType {
  AUTOMATION = "AUTOMATION",
  WEBHOOK = "WEBHOOK",
}

export type ActivatedPlaybook = {
  __typename?: "ActivatedPlaybook";
  importedPlaybook: ImportedPlaybook;
  type: PlaybookType;
  uuid: Scalars["ID"];
};

/** An Actor in the Turn ecosystem */
export type Actor = {
  __typename?: "Actor";
  account?: Maybe<Account>;
  contact?: Maybe<Contact>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  numberTokenSignature?: Maybe<NumberTokenSignature>;
  type?: Maybe<ActorType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** All possible actor types */
export enum ActorType {
  DEFAULT = "DEFAULT",
  SYSTEM = "SYSTEM",
}

export type AddCreditResponse = {
  __typename?: "AddCreditResponse";
  error?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum AiResponseFormat {
  JSON_OBJECT = "JSON_OBJECT",
  JSON_SCHEMA = "JSON_SCHEMA",
  TEXT = "TEXT",
}

/** API credentials */
export type ApiCredentials = {
  __typename?: "ApiCredentials";
  businessId?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  whatsappTemplateAccessToken?: Maybe<Scalars["String"]>;
  whatsappTemplateNamespace?: Maybe<Scalars["String"]>;
};

export type Assistant = {
  __typename?: "Assistant";
  aiModel: Scalars["String"];
  aiVendor: Scalars["String"];
  emoji?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  uuid: Scalars["ID"];
};

/** An attachment, always linked to a number, possibly a chat */
export type Attachment = {
  __typename?: "Attachment";
  autoCaption?: Maybe<Scalars["String"]>;
  autoCaptionStatus?: Maybe<TranscriptionsCaptionsAndTranslationsStatusType>;
  direction?: Maybe<DirectionType>;
  externalId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  mediaObject?: Maybe<Scalars["Json"]>;
  mediaType?: Maybe<MediaType>;
  mimeType?: Maybe<Scalars["String"]>;
  transcriptions?: Maybe<Array<Maybe<AttachmentTranscription>>>;
  transcriptionsStatus?: Maybe<TranscriptionsCaptionsAndTranslationsStatusType>;
  uri?: Maybe<Scalars["String"]>;
};

export type AttachmentTranscription = {
  __typename?: "AttachmentTranscription";
  endTimestampSeconds?: Maybe<Scalars["Float"]>;
  startTimestampSeconds?: Maybe<Scalars["Float"]>;
  text?: Maybe<Scalars["String"]>;
};

/** An author of a message */
export type Author = {
  __typename?: "Author";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
  type?: Maybe<AuthorType>;
};

/** All possible types of authors */
export enum AuthorType {
  /** Automated by Turn */
  AUTOMATOR = "AUTOMATOR",
  /** A human operator */
  OPERATOR = "OPERATOR",
  /** The owner of the chat, this is generally the recipient */
  OWNER = "OWNER",
  /** Sent from Reminders */
  REMINDER = "REMINDER",
  /** Turn Stack */
  STACK = "STACK",
  /** An automated system */
  SYSTEM = "SYSTEM",
  /** Turn Thread */
  THREAD = "THREAD",
}

/** An automation for a number */
export type Automator = {
  __typename?: "Automator";
  config?: Maybe<AutomatorConfig>;
  enabled?: Maybe<Scalars["Boolean"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["String"]>;
};

/** An action for an automator */
export type AutomatorAction = {
  __typename?: "AutomatorAction";
  actionParams?: Maybe<Scalars["Json"]>;
  actionType?: Maybe<ActionTypeType>;
};

/** An action for an automator */
export type AutomatorActionIo = {
  actionParams?: InputMaybe<Scalars["Json"]>;
  actionType?: InputMaybe<ActionTypeType>;
};

/** The config for an automator */
export type AutomatorConfig = {
  __typename?: "AutomatorConfig";
  data?: Maybe<AutomatorRule>;
  version?: Maybe<Scalars["String"]>;
};

export type AutomatorConfigIo = {
  data: AutomatorRuleIo;
  version?: InputMaybe<Scalars["String"]>;
};

/** All possible types of automator operators */
export enum AutomatorOperatorType {
  AND = "AND",
}

/** The config for an automator rule */
export type AutomatorRule = {
  __typename?: "AutomatorRule";
  actions?: Maybe<Array<Maybe<AutomatorAction>>>;
  operator: AutomatorOperatorType;
  triggers?: Maybe<Array<Maybe<AutomatorTrigger>>>;
};

/** The config for an automator rule */
export type AutomatorRuleIo = {
  actions: Array<InputMaybe<AutomatorActionIo>>;
  operator: AutomatorOperatorType;
  triggers: Array<InputMaybe<AutomatorTriggerIo>>;
};

/** A trigger for an automator */
export type AutomatorTrigger = {
  __typename?: "AutomatorTrigger";
  triggerParams?: Maybe<Scalars["Json"]>;
  triggerType?: Maybe<TriggerTypeType>;
};

/** A trigger for an automator */
export type AutomatorTriggerIo = {
  triggerParams?: InputMaybe<Scalars["Json"]>;
  triggerType?: InputMaybe<TriggerTypeType>;
};

/** A record of an operator's availability and the number of chats assigned to them after they are set as available */
export type AvailabilityAndChatsAssignedCount = {
  __typename?: "AvailabilityAndChatsAssignedCount";
  count?: Maybe<Scalars["Int"]>;
  operatorAvailability?: Maybe<OperatorAvailability>;
};

export type AverageMessagesStats = {
  __typename?: "AverageMessagesStats";
  avg?: Maybe<Scalars["Float"]>;
  leastActiveDay?: Maybe<Scalars["Int"]>;
  leastActiveHour?: Maybe<Scalars["Int"]>;
  mostActiveDay?: Maybe<Scalars["Int"]>;
  mostActiveHour?: Maybe<Scalars["Int"]>;
};

/** The backend type for a number */
export enum BackendType {
  CHANNEL = "CHANNEL",
  WHATSAPP = "WHATSAPP",
  WHATSAPP_CAPI = "WHATSAPP_CAPI",
}

export enum BillingCollectionMode {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export type BillingCountrySpend = BillingSpend & {
  __typename?: "BillingCountrySpend";
  authentication: CategoryBillingStats;
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  legacyTemplates: CategoryBillingStats;
  marketing: CategoryBillingStats;
  referralConversion: CategoryBillingStats;
  service: CategoryBillingStats;
  total: CategoryBillingStats;
  utility: CategoryBillingStats;
};

export type BillingCycle = {
  __typename?: "BillingCycle";
  frequency: Scalars["Int"];
  interval?: Maybe<PaddleInterval>;
};

export type BillingDailySpend = BillingSpend & {
  __typename?: "BillingDailySpend";
  authentication: CategoryBillingStats;
  legacyTemplates: CategoryBillingStats;
  marketing: CategoryBillingStats;
  referralConversion: CategoryBillingStats;
  service: CategoryBillingStats;
  title?: Maybe<Scalars["String"]>;
  total: CategoryBillingStats;
  utility: CategoryBillingStats;
};

export type BillingNumberSpend = BillingSpend & {
  __typename?: "BillingNumberSpend";
  authentication: CategoryBillingStats;
  country?: Maybe<Scalars["String"]>;
  legacyTemplates: CategoryBillingStats;
  marketing: CategoryBillingStats;
  number?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  referralConversion: CategoryBillingStats;
  service: CategoryBillingStats;
  title?: Maybe<Scalars["String"]>;
  total: CategoryBillingStats;
  utility: CategoryBillingStats;
};

export type BillingOverview = {
  __typename?: "BillingOverview";
  committedDeposit: Scalars["Decimal"];
  committedSpend: Scalars["Decimal"];
  creditsAvailable: Scalars["Decimal"];
  currentMonthStats: BillingStats;
  freeSpend: Scalars["Decimal"];
  highestDailySpendDate?: Maybe<Scalars["DateTime"]>;
  lastDepositAmount?: Maybe<Scalars["Decimal"]>;
  lastDepositDate?: Maybe<Scalars["DateTime"]>;
  lastMonthStats: BillingStats;
  pendingDeposit: Scalars["Decimal"];
  pendingSpend: Scalars["Decimal"];
  waivedSpend: Scalars["Decimal"];
};

export type BillingSpend = {
  authentication: CategoryBillingStats;
  legacyTemplates: CategoryBillingStats;
  marketing: CategoryBillingStats;
  referralConversion: CategoryBillingStats;
  service: CategoryBillingStats;
  total: CategoryBillingStats;
  utility: CategoryBillingStats;
};

/** billing stats broken down by pricing category */
export type BillingStats = {
  __typename?: "BillingStats";
  authentication: CategoryBillingStats;
  legacyTemplates: CategoryBillingStats;
  marketing: CategoryBillingStats;
  referralConversion: CategoryBillingStats;
  service: CategoryBillingStats;
  total: CategoryBillingStats;
  utility: CategoryBillingStats;
};

export type BillingSubscriptionTransaction = {
  __typename?: "BillingSubscriptionTransaction";
  amount: Scalars["String"];
  billedAt?: Maybe<Scalars["Date"]>;
  checkout?: Maybe<Scalars["String"]>;
  paddleId: Scalars["String"];
  priceName: Array<Scalars["String"]>;
  receiptUrl?: Maybe<Scalars["String"]>;
  status?: Maybe<BillingTransactionState>;
};

export enum BillingTransactionState {
  BILLED = "BILLED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  PAID = "PAID",
  PAST_DUE = "PAST_DUE",
  READY = "READY",
}

/** A button for Buttons Card */
export type Button = {
  __typename?: "Button";
  position?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<ButtonType>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** Button input object */
export type ButtonIo = {
  position: Scalars["Int"];
  title: Scalars["String"];
  type: InteractiveButtonType;
  uuid?: InputMaybe<Scalars["ID"]>;
};

/** The payload for when someone's clicked an interactive button */
export type ButtonReply = {
  __typename?: "ButtonReply";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** All possible types of interactive buttons */
export enum ButtonType {
  REPLY = "REPLY",
}

/** Card */
export type Card = {
  __typename?: "Card";
  answers?: Maybe<Array<Maybe<MultiChoiceCardAnswer>>>;
  attachment?: Maybe<Attachment>;
  buttons?: Maybe<Array<Maybe<Button>>>;
  cardNumberTags?: Maybe<Array<Maybe<CardNumberTag>>>;
  content?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<Scalars["String"]>;
  metadata?: Maybe<CardMetadata>;
  saveToField?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<CardType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * CardAssociation
 *
 * `CardAssociation` instances describe associations between `Card` instances,
 * thereby forming a graph with directed edges describing the flow of a thread.
 *
 * For each card in a thread a `CardAssociation` instance should be provided
 * when creating or updating the thread, with `CardAssociation` instances also
 * returned when retrieving threads.
 *
 * `CardAssociation` instances consist of:
 * - `uuid` - uuid of card in question
 * - `targetCardUuids` - list of uuids of cards associated with the card.
 *
 * For a card that is only connected to a single other card, for example a
 * question card, `targetCardUuids` is a list containing only a single
 * uuid, that of the next card to which the thread should proceed after
 * the card in question has been executed.
 *
 * For a card that is connected to multiple other cards, for example a
 * multiple choice card, `targetCardUuids` is a list containing
 * multiple uuids, with each uuid being a uuid of a card the thread should
 * proceed to if the specific option is selected. `targetCardUuids` is ordered
 * to indicate which option is associated with which card.
 *
 * If no `targetCardUuids` are provided the card is considered a terminating
 * node and as such will not be followed by any other cards/will cause the
 * thread to exit.
 *
 * If the card is connected to multiple other cards, `targetCardUuids` should
 * explicitly contain a uuid or null for each connected card. A null item in
 * `targetCardUuids` indicates that the thread should exit for the corresponding
 * option. It is not possible for the backend to reliably infer which card an
 * option is connected to if `targetCardUuids` does not contain a uuid or null
 * for each option.
 *
 * As an example, consider a question card0 with uuid `00000000-0000-0000-0000-000000000000`
 * that should be followed by another question card1 with uuid `00000001-0001-0001-0001-000000000001`.
 * In this scenario the `CardAssociation` instance for card0 will be:
 *   ```
 *   {
 *     uuid: "00000000-0000-0000-0000-000000000000",
 *     targetCardUuids: ["00000001-0001-0001-0001-000000000001"]
 *   }
 *   ```
 *
 * As another example, consider a multiple choice card, card0 with uuid `00000000-0000-0000-0000-000000000000`
 * that has two options. Option 1 should lead to a question card1 with uuid `00000001-0001-0001-0001-000000000001`.
 * Option 2 should lead to a question card2 with uuid `00000002-0002-0002-0002-000000000002`.
 * In this scenario the `CardAssociation` instance for card0 will be
 *   ```
 *   {
 *     uuid: "00000000-0000-0000-0000-000000000000",
 *     targetCardUuids: ["00000001-0001-0001-0001-000000000001", "00000002-0002-0002-0002-000000000002"]
 *   }
 *   ```
 *
 * As another example, consider a multiple choice card, card0 with uuid `00000000-0000-0000-0000-000000000000`
 * that has two options. Option 1 should exit the thread. Option 2 should lead to a question
 * card2 with uuid `00000002-0002-0002-0002-000000000002`.
 * In this scenario the `CardAssociation` instance for card0 will be
 *   ```
 *   {
 *     uuid: "00000000-0000-0000-0000-000000000000",
 *     targetCardUuids: [null, "00000002-0002-0002-0002-000000000002"]
 *   }
 *   ```
 */
export type CardAssocation = {
  __typename?: "CardAssocation";
  targetCardUuids?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  uuid: Scalars["ID"];
};

/** Metadata information for a card */
export type CardMetadata = {
  __typename?: "CardMetadata";
  footer?: Maybe<Scalars["String"]>;
  header?: Maybe<InteractiveMetadataHeader>;
  noun?: Maybe<Scalars["String"]>;
};

/** A link between a Card and a NumberTag */
export type CardNumberTag = {
  __typename?: "CardNumberTag";
  enabled?: Maybe<Scalars["Boolean"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  numberTag?: Maybe<NumberTag>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CardReference = {
  __typename?: "CardReference";
  card?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type CardReferenceIo = {
  card?: InputMaybe<Scalars["String"]>;
  label?: InputMaybe<Scalars["String"]>;
};

/** All possible types of cards */
export enum CardType {
  BUTTONS = "BUTTONS",
  FAQ = "FAQ",
  LOCATION = "LOCATION",
  MESSAGE = "MESSAGE",
  MULTI = "MULTI",
  OPT_IN = "OPT_IN",
  STICKER = "STICKER",
  TEXT = "TEXT",
}

export type Category = {
  __typename?: "Category";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** billing stats for a specific pricing category */
export type CategoryBillingStats = {
  __typename?: "CategoryBillingStats";
  count: Scalars["Int"];
  currency: Scalars["String"];
  percentage: Scalars["String"];
  spend: Scalars["String"];
};

/**
 * A chat is the full archive of all messages ever sent
 * or received to a recipient via a Number
 */
export type Chat = {
  __typename?: "Chat";
  assignedTo?: Maybe<Account>;
  chatTags?: Maybe<Array<Maybe<ChatTag>>>;
  contact?: Maybe<Contact>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  icon?: Maybe<Scalars["String"]>;
  inboundTimestamp?: Maybe<Scalars["DateTime"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  isCulled?: Maybe<Scalars["Boolean"]>;
  lastMessage?: Maybe<Message>;
  /**
   * Refers to the last reaction received for this Chat;
   * null when the last received reaction has removed a previous reaction.
   */
  lastReaction?: Maybe<MessageReaction>;
  lease?: Maybe<Lease>;
  number?: Maybe<Number>;
  outboundTimestamp?: Maybe<Scalars["DateTime"]>;
  owner?: Maybe<Scalars["String"]>;
  pinnedAt?: Maybe<Scalars["DateTime"]>;
  state?: Maybe<ChatState>;
  stateReason?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateTime"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<ChatType>;
  unreadCount?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * A chat is the full archive of all messages ever sent
 * or received to a recipient via a Number
 */
export type ChatConversationsArgs = {
  limit: Scalars["Int"];
  orderBy: Scalars["String"];
};

export type ChatIntegrationResult = {
  __typename?: "ChatIntegrationResult";
  integration?: Maybe<Integration>;
  result?: Maybe<ChatIntegrationResultDetails>;
  status?: Maybe<IntegrationResultStatusType>;
};

/**
 * The results an integration can return, synchronously or asynchronously
 * when given a chat
 */
export type ChatIntegrationResultDetails = {
  __typename?: "ChatIntegrationResultDetails";
  integrationActions?: Maybe<Array<Maybe<IntegrationAction>>>;
  integrationContextObjectDetails?: Maybe<Array<Maybe<IntegrationContextObjectDetails>>>;
  integrationSuggestedResponses?: Maybe<Array<Maybe<CustomIntegrationSuggestion>>>;
};

/** A mention tagging an account. Only for TURN_CHAT_NOTE source_type messages. */
export type ChatNoteMention = {
  __typename?: "ChatNoteMention";
  authorAccountUuid: Scalars["ID"];
  isCleared: Scalars["Boolean"];
  numberUuid: Scalars["ID"];
  taggedAccountUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type ChatNoteMentionIo = {
  taggedAccountUuid: Scalars["ID"];
};

/** Chat note search results */
export type ChatNoteSearchResults = {
  __typename?: "ChatNoteSearchResults";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<Message>>>;
};

/** Chat Search results */
export type ChatSearchResults = {
  __typename?: "ChatSearchResults";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<Chat>>>;
};

/** The possible status types for a chat */
export enum ChatState {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

/** A chat tag links a chat to a number tag */
export type ChatTag = {
  __typename?: "ChatTag";
  numberTag?: Maybe<NumberTag>;
};

/** The possible chat types for a chat */
export enum ChatType {
  CULLED = "CULLED",
  DIRECT = "DIRECT",
  GROUP = "GROUP",
}

/** How many open chats are assigned to an operator */
export type ChatsAssignedCount = {
  __typename?: "ChatsAssignedCount";
  count?: Maybe<Scalars["Int"]>;
};

/** A collection is a saved search */
export type Collection = {
  __typename?: "Collection";
  chats?: Maybe<Array<Maybe<Chat>>>;
  collectionCount?: Maybe<Scalars["Int"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  messagesOrChatsTotalEstimate?: Maybe<Scalars["Int"]>;
  messagesOrChatsTotalEstimateUpdatedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Number>;
  query?: Maybe<SearchQuery>;
  type?: Maybe<CollectionType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** A collection is a saved search */
export type CollectionChatsArgs = {
  afterUuid?: InputMaybe<Scalars["ID"]>;
  beforeUuid?: InputMaybe<Scalars["ID"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

/** A collection is a saved search */
export type CollectionCollectionCountArgs = {
  afterUuid?: InputMaybe<Scalars["ID"]>;
  beforeUuid?: InputMaybe<Scalars["ID"]>;
  isHandled?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

/** A collection input object */
export type CollectionIo = {
  name: Scalars["String"];
  query?: InputMaybe<SearchQueryIo>;
};

/** The possible collection types */
export enum CollectionType {
  /** @deprecated Set the type to nil instead and let the backend handle it */
  CUSTOM = "CUSTOM",
  CUSTOM_MESSAGE = "CUSTOM_MESSAGE",
  CUSTOM_USER = "CUSTOM_USER",
  DEFAULT = "DEFAULT",
}

/** A Contact object */
export type Contact = {
  __typename?: "Contact";
  details?: Maybe<Scalars["Json"]>;
  failureCount?: Maybe<Scalars["Int"]>;
  isFallbackActive?: Maybe<Scalars["Boolean"]>;
  number?: Maybe<Number>;
  organisation?: Maybe<Organisation>;
  type?: Maybe<Scalars["String"]>;
  urn?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** A contact field */
export type ContactField = {
  __typename?: "ContactField";
  fieldDisplay?: Maybe<Scalars["String"]>;
  fieldName?: Maybe<Scalars["String"]>;
  fieldSpec?: Maybe<ContactFieldSpec>;
  fieldType?: Maybe<FieldTypeType>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  isPrivate?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** The contact field spec */
export type ContactFieldSpec = {
  __typename?: "ContactFieldSpec";
  defaultValue?: Maybe<Scalars["Json"]>;
  validatorParams?: Maybe<Scalars["Json"]>;
  validatorType?: Maybe<Scalars["String"]>;
};

export type ContactProfileSerialized = {
  __typename?: "ContactProfileSerialized";
  fields?: Maybe<Scalars["Json"]>;
  generation?: Maybe<Scalars["Int"]>;
  isStagingUser: Scalars["Boolean"];
  schema?: Maybe<Scalars["String"]>;
  uuid: Scalars["ID"];
  version?: Maybe<Scalars["String"]>;
};

/** A contact schema */
export type ContactSchema = {
  __typename?: "ContactSchema";
  contactFields?: Maybe<Array<Maybe<ContactField>>>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  latest?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** A contact schema */
export type ContactSchemaContactFieldsArgs = {
  fieldTypes?: InputMaybe<Array<InputMaybe<FieldTypeType>>>;
};

export type ContactSchemaFieldsEnumIo = {
  display?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type ContactSchemaFieldsEnumSerialized = {
  __typename?: "ContactSchemaFieldsEnumSerialized";
  display?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type ContactSchemaFieldsIo = {
  default?: InputMaybe<Scalars["Json"]>;
  display?: InputMaybe<Scalars["String"]>;
  enum?: InputMaybe<Array<InputMaybe<ContactSchemaFieldsEnumIo>>>;
  isPrivate?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type ContactSchemaFieldsSerialized = {
  __typename?: "ContactSchemaFieldsSerialized";
  custom?: Maybe<Scalars["Boolean"]>;
  default?: Maybe<Scalars["Json"]>;
  display?: Maybe<Scalars["String"]>;
  enum?: Maybe<Array<Maybe<ContactSchemaFieldsEnumSerialized>>>;
  isPrivate?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  null?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ContactSchemaIo = {
  fields?: InputMaybe<Array<InputMaybe<ContactSchemaFieldsIo>>>;
  version?: InputMaybe<Scalars["String"]>;
};

export type ContactSchemaSerialized = {
  __typename?: "ContactSchemaSerialized";
  fields?: Maybe<Array<Maybe<ContactSchemaFieldsSerialized>>>;
  uuid?: Maybe<Scalars["ID"]>;
  version?: Maybe<Scalars["String"]>;
};

export type ContactsSegment = {
  __typename?: "ContactsSegment";
  accountUuid?: Maybe<Scalars["ID"]>;
  color?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  includeBlockedContacts?: Maybe<Scalars["Boolean"]>;
  isPredefined?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  numberUuid: Scalars["ID"];
  queryString?: Maybe<Scalars["String"]>;
  uuid: Scalars["ID"];
};

export type ContentIo = {
  answers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  attachmentId?: InputMaybe<Scalars["ID"]>;
  buttons?: InputMaybe<Array<InputMaybe<ButtonIo>>>;
  content?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<InteractiveMetadataIo>;
  noun?: InputMaybe<Scalars["String"]>;
  numberTags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  saveToField?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** Conversations with a user, linked to a chat */
export type Conversation = {
  __typename?: "Conversation";
  chat?: Maybe<Chat>;
  closedAt?: Maybe<Scalars["DateTime"]>;
  closedBy?: Maybe<Account>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  lastActivity?: Maybe<Scalars["DateTime"]>;
  openedAt?: Maybe<Scalars["DateTime"]>;
  openedBy?: Maybe<Account>;
  state?: Maybe<ConversationStateType>;
  type?: Maybe<ConversationType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export enum ConversationStateType {
  CLOSED = "CLOSED",
  EXPIRED = "EXPIRED",
  OPEN = "OPEN",
}

export enum ConversationType {
  DEFAULT = "DEFAULT",
}

/** A content suggestion from a custom integration */
export type CustomIntegrationSuggestion = {
  __typename?: "CustomIntegrationSuggestion";
  body?: Maybe<Scalars["String"]>;
  chatUuid?: Maybe<Scalars["ID"]>;
  confidence?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationSuggestionType>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** Details on the custom phone number requested by a client */
export type CustomNumberRequest = {
  __typename?: "CustomNumberRequest";
  country: Scalars["String"];
  state?: Maybe<Scalars["String"]>;
};

export type DefaultDictionary = {
  __typename?: "DefaultDictionary";
  frontMatter: FrontMatter;
  items: Array<DefaultKeyValuePair>;
  name: Scalars["String"];
};

export type DefaultKeyValuePair = {
  __typename?: "DefaultKeyValuePair";
  key: Scalars["String"];
  type: KeyValuePairType;
  value?: Maybe<Scalars["String"]>;
};

/** All possible reasons through which a message can be deleted */
export enum DeletedReasonType {
  ACCOUNT = "ACCOUNT",
  API_TOKEN = "API_TOKEN",
  SYSTEM = "SYSTEM",
}

export type DeletedUuid = {
  __typename?: "DeletedUuid";
  uuid?: Maybe<Scalars["ID"]>;
};

export type Dictionary = {
  __typename?: "Dictionary";
  frontMatter: FrontMatter;
  items: Array<KeyValuePair>;
  name: Scalars["String"];
};

export type DictionaryIo = {
  frontMatter: FrontMatterIo;
  items: Array<KeyValuePairIo>;
  name: Scalars["String"];
};

/** All possible directions for a message */
export enum DirectionType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export type Export = {
  __typename?: "Export";
  finishedAt?: Maybe<Scalars["DateTime"]>;
  initiatedAt?: Maybe<Scalars["DateTime"]>;
  notifiedAt?: Maybe<Scalars["DateTime"]>;
  numberUuid?: Maybe<Scalars["ID"]>;
  objectUuid?: Maybe<Scalars["ID"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  uploadedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type Expression = {
  __typename?: "Expression";
  items?: Maybe<Array<ExpressionItem>>;
  raw: Scalars["String"];
  type: ExpressionType;
};

export type ExpressionArg = {
  type: ExpressionArgType;
};

export type ExpressionArgAtom = ExpressionArg & {
  __typename?: "ExpressionArgAtom";
  atom: Scalars["String"];
  type: ExpressionArgType;
};

export type ExpressionArgAttribute = ExpressionArg & {
  __typename?: "ExpressionArgAttribute";
  attribute: Array<Scalars["String"]>;
  type: ExpressionArgType;
};

export type ExpressionArgBooleanLiteral = ExpressionArg & {
  __typename?: "ExpressionArgBooleanLiteral";
  booleanLiteral: Scalars["Boolean"];
  type: ExpressionArgType;
};

export type ExpressionArgFloatLiteral = ExpressionArg & {
  __typename?: "ExpressionArgFloatLiteral";
  floatLiteral: Scalars["Float"];
  type: ExpressionArgType;
};

export type ExpressionArgIntegerLiteral = ExpressionArg & {
  __typename?: "ExpressionArgIntegerLiteral";
  integerLiteral: Scalars["Int"];
  type: ExpressionArgType;
};

export type ExpressionArgListOfStringsLiteral = ExpressionArg & {
  __typename?: "ExpressionArgListOfStringsLiteral";
  listOfStrings: Array<Scalars["String"]>;
  type: ExpressionArgType;
};

export type ExpressionArgStringLiteral = ExpressionArg & {
  __typename?: "ExpressionArgStringLiteral";
  stringLiteral: Scalars["String"];
  type: ExpressionArgType;
};

export enum ExpressionArgType {
  ATOM = "ATOM",
  ATTRIBUTE = "ATTRIBUTE",
  BOOLEAN_LITERAL = "BOOLEAN_LITERAL",
  FLOAT_LITERAL = "FLOAT_LITERAL",
  INTEGER_LITERAL = "INTEGER_LITERAL",
  LIST_OF_STRINGS = "LIST_OF_STRINGS",
  STRING_LITERAL = "STRING_LITERAL",
}

export type ExpressionArgsIo = {
  atom?: InputMaybe<Scalars["String"]>;
  attribute?: InputMaybe<Array<Scalars["String"]>>;
  booleanLiteral?: InputMaybe<Scalars["Boolean"]>;
  floatLiteral?: InputMaybe<Scalars["Float"]>;
  integerLiteral?: InputMaybe<Scalars["Int"]>;
  listOfStrings?: InputMaybe<Array<Scalars["String"]>>;
  stringLiteral?: InputMaybe<Scalars["String"]>;
  type: ExpressionArgType;
};

export type ExpressionBasicIo = {
  connector?: InputMaybe<ExpressionConnectorType>;
  function?: InputMaybe<ExpressionFunctionIo>;
  type: ExpressionInputType;
};

export type ExpressionConnector = {
  __typename?: "ExpressionConnector";
  connector: ExpressionConnectorType;
};

export enum ExpressionConnectorType {
  AND = "AND",
  OR = "OR",
}

export type ExpressionFunction = {
  __typename?: "ExpressionFunction";
  args?: Maybe<Array<ExpressionArg>>;
  hasNegation: Scalars["Boolean"];
  type: Scalars["String"];
};

export type ExpressionFunctionIo = {
  args: Array<ExpressionArgsIo>;
  hasNegation: Scalars["Boolean"];
  type: Scalars["String"];
};

export enum ExpressionInputType {
  CONNECTOR = "CONNECTOR",
  FUNCTION = "FUNCTION",
}

export type ExpressionIo = {
  advanced?: InputMaybe<Scalars["String"]>;
  basic?: InputMaybe<Array<ExpressionBasicIo>>;
  type: ExpressionType;
};

/** Result of the expression parsing process. Can be a function or a connector. */
export type ExpressionItem = ExpressionConnector | ExpressionFunction;

export enum ExpressionType {
  ADVANCED = "ADVANCED",
  BASIC = "BASIC",
}

/** A Facebook Business. A Facebook Business owns zero or more WABAs. */
export type FacebookBusiness = {
  __typename?: "FacebookBusiness";
  fbGraphId: Scalars["ID"];
  name: Scalars["String"];
  uuid: Scalars["ID"];
  verificationStatus?: Maybe<FacebookBusinessVerificationStatus>;
};

/** The verification status of a Facebook Business. */
export enum FacebookBusinessVerificationStatus {
  FAILED = "FAILED",
  NOT_VERIFIED = "NOT_VERIFIED",
  PENDING = "PENDING",
  PENDING_NEED_MORE_INFO = "PENDING_NEED_MORE_INFO",
  PENDING_SUBMISSION = "PENDING_SUBMISSION",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
  VERIFIED = "VERIFIED",
}

/** All possible types of fields */
export enum FieldTypeType {
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  ENUM = "ENUM",
  FLOAT = "FLOAT",
  INTEGER = "INTEGER",
  LOCATION = "LOCATION",
  STRING = "STRING",
}

/** The flow container for a stack container, generally this shouldn't be useful in the frontend */
export type FlowContainer = {
  __typename?: "FlowContainer";
  uuid?: Maybe<Scalars["ID"]>;
};

export type FrontMatter = {
  __typename?: "FrontMatter";
  columns: Array<FrontMatterColumn>;
  version: Scalars["String"];
};

export type FrontMatterColumn = {
  __typename?: "FrontMatterColumn";
  key: Scalars["String"];
  type: FrontMatterColumnType;
};

export type FrontMatterColumnIo = {
  key: Scalars["String"];
  type: FrontMatterColumnType;
};

export enum FrontMatterColumnType {
  BUTTON = "BUTTON",
}

export type FrontMatterIo = {
  columns: Array<FrontMatterColumnIo>;
  version: Scalars["String"];
};

export type GeneralStats = {
  __typename?: "GeneralStats";
  totalMessages?: Maybe<Scalars["Int"]>;
  totalOperatorResponse?: Maybe<Scalars["Int"]>;
  totalUsers?: Maybe<Scalars["Int"]>;
};

export type GetConfigForInboxAutoTranslationResponse = {
  __typename?: "GetConfigForInboxAutoTranslationResponse";
  autoTranslate: Scalars["Boolean"];
  originalLanguageCode?: Maybe<Scalars["String"]>;
  translatedLanguageCode?: Maybe<Scalars["String"]>;
};

/** Number account mode (limited trial or live) */
export enum GraphAccountMode {
  LIVE = "LIVE",
  /** Facebook's Limited Trial Experience */
  SANDBOX = "SANDBOX",
}

/** Number name verification status */
export enum GraphNameStatus {
  APPROVED = "APPROVED",
  AVAILABLE_WITHOUT_REVIEW = "AVAILABLE_WITHOUT_REVIEW",
  DECLINED = "DECLINED",
  DEFERRED = "DEFERRED",
  EXPIRED = "EXPIRED",
  NONE = "NONE",
  NON_EXISTS = "NON_EXISTS",
  PENDING_REVIEW = "PENDING_REVIEW",
  REJECTED = "REJECTED",
}

/** Number quality score as computed by Facebook */
export enum GraphQualityScore {
  GREEN = "GREEN",
  RED = "RED",
  UNKNOWN = "UNKNOWN",
  YELLOW = "YELLOW",
}

/** Number connection status */
export enum GraphStatus {
  BANNED = "BANNED",
  CONNECTED = "CONNECTED",
  DELETED = "DELETED",
  DISCONNECTED = "DISCONNECTED",
  FLAGGED = "FLAGGED",
  MIGRATED = "MIGRATED",
  PENDING = "PENDING",
  RATE_LIMITED = "RATE_LIMITED",
  RESTRICTED = "RESTRICTED",
  UNKNOWN = "UNKNOWN",
}

/** The possible digest types */
export enum HmacDigestType {
  SHA256 = "SHA256",
}

/**
 * A WhatsApp HSM object.
 *
 * HSM stands for `Highly Structured Message`. Nowadays these
 * are called `message templates` and have been replaced by the
 * `template` object below
 */
export type Hsm = {
  __typename?: "Hsm";
  elementName?: Maybe<Scalars["String"]>;
  language?: Maybe<HsmLanguage>;
  localizableParams?: Maybe<Array<Maybe<LocalizableParam>>>;
  namespace?: Maybe<Scalars["String"]>;
};

/** A WhatsApp HSM language object */
export type HsmLanguage = {
  __typename?: "HsmLanguage";
  code?: Maybe<Scalars["String"]>;
  policy?: Maybe<Scalars["String"]>;
};

/** The type of WABA impact sector */
export enum ImpactSectorType {
  AGRICULTURE = "AGRICULTURE",
  CIVIC_ENGAGEMENT = "CIVIC_ENGAGEMENT",
  COVID_19 = "COVID_19",
  EDUCATION = "EDUCATION",
  FINANCIAL_INCLUSION = "FINANCIAL_INCLUSION",
  HEALTH = "HEALTH",
  HUMANITARIAN = "HUMANITARIAN",
  JOBS = "JOBS",
  MEDIA = "MEDIA",
  OTHER = "OTHER",
  SUSTAINABILITY = "SUSTAINABILITY",
  TECHNOLOGY = "TECHNOLOGY",
}

export type ImportedPlaybook = {
  __typename?: "ImportedPlaybook";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  organisation?: Maybe<Organisation>;
  playbook?: Maybe<Playbook>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type ImportedPlaybooks = {
  __typename?: "ImportedPlaybooks";
  importedPlaybooks?: Maybe<Array<Maybe<ImportedPlaybook>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** Common summary info summary for any inbox stat. */
export type InboxGroupingSummary = {
  __typename?: "InboxGroupingSummary";
  accountUuid?: Maybe<Scalars["ID"]>;
  count?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  numberUuid: Scalars["ID"];
  operatorAvailability?: Maybe<OperatorAvailability>;
  unread?: Maybe<Scalars["Boolean"]>;
};

/** Overview of the Inbox chat groupings and their states (open chat counts and statuses) */
export type InboxOverview = {
  __typename?: "InboxOverview";
  allChats?: Maybe<InboxGroupingSummary>;
  blocked?: Maybe<InboxGroupingSummary>;
  mentions: InboxGroupingSummary;
  teammates?: Maybe<Array<InboxGroupingSummary>>;
  unassigned?: Maybe<InboxGroupingSummary>;
  withBot?: Maybe<InboxGroupingSummary>;
  yourInbox: InboxGroupingSummary;
};

export type InsightsConversationStats = {
  __typename?: "InsightsConversationStats";
  data?: Maybe<InsightsConversationStatsItem>;
};

export type InsightsConversationStatsItem = {
  __typename?: "InsightsConversationStatsItem";
  conversationClosed?: Maybe<Scalars["Int"]>;
  conversationOpen?: Maybe<Scalars["Int"]>;
};

/**
 * An integration with a third party, belongs to an number
 * and enables a limited set of actions to be performed
 * by the third party
 */
export type Integration = {
  __typename?: "Integration";
  actionNotifications?: Maybe<Array<Maybe<IntegrationActionNotification>>>;
  config?: Maybe<Scalars["Json"]>;
  contextObjects?: Maybe<Array<Maybe<IntegrationContextObject>>>;
  description?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  hasActions?: Maybe<Scalars["Boolean"]>;
  hasSuggestedResponses?: Maybe<Scalars["Boolean"]>;
  hmacDigest?: Maybe<HmacDigestType>;
  hmacSecret?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationType>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * Integration actions provide actions that can be taken in the UI
 * but who are fulfilled by the integrations themselves
 */
export type IntegrationAction = {
  __typename?: "IntegrationAction";
  chatUuid: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  integrationUuid: Scalars["ID"];
  options?: Maybe<Scalars["Json"]>;
  payload?: Maybe<Scalars["Json"]>;
  url?: Maybe<Scalars["String"]>;
};

/** Same as above but then available as an input object */
export type IntegrationActionIo = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["Json"]>;
  payload?: InputMaybe<Scalars["Json"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** This is the result that's stored as result of an action being called */
export type IntegrationActionNotification = {
  __typename?: "IntegrationActionNotification";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  metadata?: Maybe<Scalars["Json"]>;
  subject?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid: Scalars["ID"];
};

/** An integration context object is the context shown on behalf of an integration in a conversation */
export type IntegrationContextObject = {
  __typename?: "IntegrationContextObject";
  code?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationContextObjectType>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * Integration contexts provide extra information, possibly
 * supplied by 3rd parties which can be surfaced within
 * a chat
 */
export type IntegrationContextObjectDetails = {
  __typename?: "IntegrationContextObjectDetails";
  chatUuid?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["Json"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** All possible types for an integration context object */
export enum IntegrationContextObjectType {
  ORDERED_LIST = "ORDERED_LIST",
  TABLE = "TABLE",
}

/** Integration input object, for creating integrations with */
export type IntegrationIo = {
  config?: InputMaybe<Scalars["Json"]>;
  description?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<IntegrationType>;
  uuid?: InputMaybe<Scalars["ID"]>;
};

/** All possible directions for an integration result */
export enum IntegrationResultStatusType {
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
}

/** All possible types for an integration suggested response */
export enum IntegrationSuggestionType {
  TEXT = "TEXT",
}

/** The possible types of integration */
export enum IntegrationType {
  AI_ASSISTANT_V1 = "AI_ASSISTANT_V1",
  CUSTOM_V1 = "CUSTOM_V1",
  G_NLU_V1 = "G_NLU_V1",
  NLU_V1 = "NLU_V1",
  RAPIDPRO_V2 = "RAPIDPRO_V2",
}

/** A WhatsApp interactive session message object */
export type Interactive = {
  __typename?: "Interactive";
  action?: Maybe<InteractiveAction>;
  body?: Maybe<InteractiveBody>;
  buttonReply?: Maybe<ButtonReply>;
  footer?: Maybe<InteractiveFooter>;
  header?: Maybe<InteractiveHeader>;
  listReply?: Maybe<ListReply>;
  type?: Maybe<InteractiveType>;
};

/** The action payload for an interactive session message */
export type InteractiveAction = {
  __typename?: "InteractiveAction";
  /** The text to display with a button */
  button?: Maybe<Scalars["String"]>;
  buttons?: Maybe<Array<Maybe<InteractiveButton>>>;
  name?: Maybe<Scalars["String"]>;
  sections?: Maybe<Array<Maybe<InteractiveListSection>>>;
};

export type InteractiveActionInput = {
  catalogId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<InteractiveParametersInput>;
  productRetailerId?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<InputMaybe<InteractiveListSectionInput>>>;
};

/** The body for an interactive session message */
export type InteractiveBody = {
  __typename?: "InteractiveBody";
  text?: Maybe<Scalars["String"]>;
};

/** The button for an interactive session message */
export type InteractiveButton = {
  __typename?: "InteractiveButton";
  reply?: Maybe<InteractiveQuickReplyButton>;
  type?: Maybe<InteractiveButtonType>;
};

/** All possible types for the quick reply buttons */
export enum InteractiveButtonType {
  REPLY = "REPLY",
}

/** The footer for an interactive session message */
export type InteractiveFooter = {
  __typename?: "InteractiveFooter";
  text?: Maybe<Scalars["String"]>;
};

/** The header for an interactive session message */
export type InteractiveHeader = {
  __typename?: "InteractiveHeader";
  document?: Maybe<Scalars["Json"]>;
  image?: Maybe<Scalars["Json"]>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<InteractiveHeaderType>;
  video?: Maybe<Scalars["Json"]>;
};

/** All possible types of interactive headers */
export enum InteractiveHeaderType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

/** A list section for an interactive session message */
export type InteractiveListSection = {
  __typename?: "InteractiveListSection";
  rows?: Maybe<Array<Maybe<InteractiveListSectionRow>>>;
  title?: Maybe<Scalars["String"]>;
};

export type InteractiveListSectionInput = {
  productItems?: InputMaybe<Array<InputMaybe<InteractiveProductRetailerIdInput>>>;
  title?: InputMaybe<Scalars["String"]>;
};

/** A row in a list section for an interactive session message */
export type InteractiveListSectionRow = {
  __typename?: "InteractiveListSectionRow";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Interactive Metadata Header object */
export type InteractiveMetadataHeader = {
  __typename?: "InteractiveMetadataHeader";
  text?: Maybe<Scalars["String"]>;
  type: InteractiveHeaderType;
};

/** Interactive Metadata Header input object */
export type InteractiveMetadataHeaderIo = {
  text?: InputMaybe<Scalars["String"]>;
  type: InteractiveHeaderType;
};

/** Interactive Metadata input object */
export type InteractiveMetadataIo = {
  footer?: InputMaybe<Scalars["String"]>;
  header?: InputMaybe<InteractiveMetadataHeaderIo>;
};

export type InteractiveObjectInput = {
  action?: InputMaybe<InteractiveActionInput>;
  body?: InputMaybe<InteractiveTextInput>;
  footer?: InputMaybe<InteractiveTextInput>;
  header?: InputMaybe<InteractiveTextInput>;
  type: InteractiveType;
};

export type InteractiveParametersBeneficiaryInput = {
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  name: Scalars["String"];
  postalCode: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type InteractiveParametersInput = {
  beneficiaries?: InputMaybe<Array<InputMaybe<InteractiveParametersBeneficiaryInput>>>;
  currency: Scalars["String"];
  order: InteractiveParametersOrderInput;
  /** The name of the pre-configured payment configuration. It must match with a payment configuration set up on the WhatsApp Business Manager. */
  paymentConfiguration: Scalars["String"];
  paymentType: Scalars["String"];
  /** Unique identifier for the order or invoice */
  referenceId: Scalars["String"];
  totalAmount: InteractiveParametersOrderAmountInput;
  type: Scalars["String"];
};

export type InteractiveParametersOrderAmountInput = {
  description?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  value?: InputMaybe<Scalars["Int"]>;
};

export type InteractiveParametersOrderInput = {
  catalogId?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<InteractiveParametersOrderAmountInput>;
  /** the list of items for this order */
  items?: InputMaybe<Array<InputMaybe<InteractiveParametersOrderItemInput>>>;
  shipping?: InputMaybe<InteractiveParametersOrderAmountInput>;
  status: Scalars["String"];
  subtotal?: InputMaybe<InteractiveParametersOrderAmountInput>;
  tax?: InputMaybe<InteractiveParametersOrderAmountInput>;
};

export type InteractiveParametersOrderItemInput = {
  amount: InteractiveParametersOrderAmountInput;
  name: Scalars["String"];
  quantity: Scalars["Int"];
  /** Unique identifier of the Facebook catalog */
  retailerId: Scalars["String"];
  saleAmount?: InputMaybe<InteractiveParametersOrderAmountInput>;
};

export type InteractiveProductRetailerIdInput = {
  productRetailerId?: InputMaybe<Scalars["String"]>;
};

/** The quick reply button for an interactive session message */
export type InteractiveQuickReplyButton = {
  __typename?: "InteractiveQuickReplyButton";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type InteractiveTextInput = {
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** All possible types of interactive session message types */
export enum InteractiveType {
  BUTTON = "BUTTON",
  BUTTON_REPLY = "BUTTON_REPLY",
  CATALOG_MESSAGE = "CATALOG_MESSAGE",
  FLOW = "FLOW",
  LIST = "LIST",
  LIST_REPLY = "LIST_REPLY",
  LOCATION_REQUEST_MESSAGE = "LOCATION_REQUEST_MESSAGE",
  NFM_REPLY = "NFM_REPLY",
  ORDER_DETAILS = "ORDER_DETAILS",
  PRODUCT = "PRODUCT",
  PRODUCT_LIST = "PRODUCT_LIST",
}

export type JourneyData = {
  __typename?: "JourneyData";
  data: JourneyDataPayload;
  name: Scalars["String"];
  uuid: Scalars["ID"];
};

export type JourneyDataDictionary = JourneyDataPayload & {
  __typename?: "JourneyDataDictionary";
  dictionary: Array<KeyValuePair>;
  id: Scalars["ID"];
  type: JourneyDataPayloadType;
};

export type JourneyDataIo = {
  data: JourneyDataPayloadIo;
  name: Scalars["String"];
};

export type JourneyDataPayload = {
  id: Scalars["ID"];
  type: JourneyDataPayloadType;
};

export type JourneyDataPayloadIo = {
  dictionary?: InputMaybe<Array<KeyValuePairIo>>;
  table?: InputMaybe<JourneyTableIo>;
  type: JourneyDataPayloadType;
};

export enum JourneyDataPayloadType {
  DICTIONARY = "DICTIONARY",
  TABLE = "TABLE",
}

export type JourneyDataTable = JourneyDataPayload & {
  __typename?: "JourneyDataTable";
  id: Scalars["ID"];
  table: JourneyTable;
  type: JourneyDataPayloadType;
};

export type JourneyInsight = {
  __typename?: "JourneyInsight";
  count: Scalars["Int"];
  dropOff: Scalars["Int"];
  identity: Scalars["String"];
};

export type JourneyInsights = {
  __typename?: "JourneyInsights";
  insights: Array<JourneyInsight>;
  total: Scalars["Int"];
};

export type JourneyTable = {
  __typename?: "JourneyTable";
  columns: Array<JourneyTableColumn>;
  rows: Array<JourneyTableRow>;
};

export type JourneyTableColumn = {
  __typename?: "JourneyTableColumn";
  id: Scalars["ID"];
  index: Scalars["Int"];
  key: Scalars["String"];
};

export type JourneyTableColumnIo = {
  id?: InputMaybe<Scalars["ID"]>;
  index: Scalars["Int"];
  key: Scalars["String"];
};

export type JourneyTableIo = {
  columns: Array<JourneyTableColumnIo>;
  rows: Array<JourneyTableRowIo>;
};

export type JourneyTableRow = {
  __typename?: "JourneyTableRow";
  id: Scalars["ID"];
  index: Scalars["Int"];
  items: Array<KeyValuePair>;
};

export type JourneyTableRowIo = {
  id?: InputMaybe<Scalars["ID"]>;
  index: Scalars["Int"];
  items: Array<KeyValuePairIo>;
};

export type KeyValuePair = {
  __typename?: "KeyValuePair";
  key: Scalars["String"];
  type: KeyValuePairType;
  value?: Maybe<Scalars["String"]>;
};

export type KeyValuePairIo = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export enum KeyValuePairType {
  INTEGER = "INTEGER",
  REFERENCE = "REFERENCE",
  STRING = "STRING",
}

/** A language entity representing a spoken language */
export type Language = {
  __typename?: "Language";
  /** A list of additional names or aliases for the language. */
  additionalDisplayNames: Array<Maybe<Scalars["String"]>>;
  /** A unique 3-letter code representing the language based on the ISO 639-3 standard. */
  languageCode: Scalars["String"];
  /** The official name of the language. */
  name: Scalars["String"];
};

/**
 * A lease is a claim that an actor within the Turn system can have on a conversation.
 * The actor owning the lease is the one currently owning & responsible for the conversation.
 */
export type Lease = {
  __typename?: "Lease";
  childLease?: Maybe<Lease>;
  claimedAt: Scalars["DateTime"];
  claimedUntil: Scalars["DateTime"];
  firstMessageUuid?: Maybe<Scalars["ID"]>;
  lastMessageUuid?: Maybe<Scalars["ID"]>;
  scope: LeaseScope;
  scopeResourceAsString: Scalars["String"];
  uuid: Scalars["ID"];
};

export enum LeaseScope {
  ACCOUNT = "ACCOUNT",
  STACK = "STACK",
  THREAD = "THREAD",
  WEBHOOK = "WEBHOOK",
}

export type ListMessagesResults = {
  __typename?: "ListMessagesResults";
  canLoadMore: Scalars["Boolean"];
  results: Array<Message>;
};

/** The payload for when someone's clicked an interactive list item */
export type ListReply = {
  __typename?: "ListReply";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** A localizable parameter for an HSM */
export type LocalizableParam = {
  __typename?: "LocalizableParam";
  default?: Maybe<Scalars["String"]>;
};

/** All possible types of media an attachment can have */
export enum MediaType {
  APPLICATION = "APPLICATION",
  AUDIO = "AUDIO",
  CONTACT = "CONTACT",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  LOCATION = "LOCATION",
  STICKER = "STICKER",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  VOICE = "VOICE",
}

/** A message, either sent or received. */
export type Message = {
  __typename?: "Message";
  attachmentId?: Maybe<Scalars["ID"]>;
  author?: Maybe<Author>;
  cardUuid?: Maybe<Scalars["ID"]>;
  chat?: Maybe<Chat>;
  chatNoteMentions: Array<ChatNoteMention>;
  content?: Maybe<Scalars["String"]>;
  contextForwarded?: Maybe<Scalars["Boolean"]>;
  contextFrequentlyForwarded?: Maybe<Scalars["Boolean"]>;
  deletedByActor?: Maybe<Actor>;
  deletedReason?: Maybe<DeletedReasonType>;
  direction?: Maybe<DirectionType>;
  externalId?: Maybe<Scalars["ID"]>;
  externalTimestamp?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Once the Card consolidation work is done, this goes away. */
  faqUuid?: Maybe<Scalars["ID"]>;
  fromAddr?: Maybe<Scalars["String"]>;
  hsm?: Maybe<Hsm>;
  inReplyTo?: Maybe<Message>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  interactive?: Maybe<Interactive>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  isHandled?: Maybe<Scalars["Boolean"]>;
  lastStatus?: Maybe<StatusType>;
  lastStatusTimestamp?: Maybe<Scalars["DateTime"]>;
  messageAttachments?: Maybe<Array<Maybe<MessageAttachment>>>;
  messageReactions: Array<MessageReaction>;
  messageTags?: Maybe<Array<Maybe<MessageTag>>>;
  messageTranslations?: Maybe<Array<Maybe<MessageTranslation>>>;
  messageType?: Maybe<MessageType>;
  onFallbackChannel?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Once the Card consolidation work is done, this goes away. */
  optInUuid?: Maybe<Scalars["ID"]>;
  sourceType?: Maybe<SourceTypeType>;
  template?: Maybe<Template>;
  templateButtonIndex?: Maybe<Scalars["Int"]>;
  templateButtonTemplateLanguage?: Maybe<Scalars["String"]>;
  templateButtonTemplateName?: Maybe<Scalars["String"]>;
  templateButtonText?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** A message attachment links a message to an attachment, this is where we store the caption */
export type MessageAttachment = {
  __typename?: "MessageAttachment";
  attachment?: Maybe<Attachment>;
  caption?: Maybe<Scalars["String"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** A reaction to a message, either sent or received */
export type MessageReaction = {
  __typename?: "MessageReaction";
  contactUuid?: Maybe<Scalars["ID"]>;
  direction: DirectionType;
  message?: Maybe<Message>;
  numberUuid?: Maybe<Scalars["ID"]>;
  reaction: Scalars["String"];
  timestamp: Scalars["DateTime"];
};

/** Message Search results */
export type MessageSearchResults = {
  __typename?: "MessageSearchResults";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<Message>>>;
};

/** A message tag links a message to a number tag, includes a confidence score between 0 & 1 */
export type MessageTag = {
  __typename?: "MessageTag";
  confidence?: Maybe<Scalars["Float"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  message?: Maybe<Message>;
  metadata?: Maybe<Scalars["Json"]>;
  numberTag?: Maybe<NumberTag>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum MessageTemplateButtonType {
  PHONE_NUMBER = "PHONE_NUMBER",
  QUICK_REPLY = "QUICK_REPLY",
  URL = "URL",
  VOICE_CALL = "VOICE_CALL",
}

/** A valid message template category */
export enum MessageTemplateCategoryType {
  /**
   * Account Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  /**
   * Alert Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  ALERT_UPDATE = "ALERT_UPDATE",
  /**
   * Appointment Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  APPOINTMENT_UPDATE = "APPOINTMENT_UPDATE",
  AUTHENTICATION = "AUTHENTICATION",
  /**
   * Automatic Reply
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  AUTO_REPLY = "AUTO_REPLY",
  /**
   * Issue Resolution
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  ISSUE_RESOLUTION = "ISSUE_RESOLUTION",
  MARKETING = "MARKETING",
  /**
   * One Time Pin
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  OTP = "OTP",
  /**
   * Payment Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  /**
   * Personal Finance Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  PERSONAL_FINANCE_UPDATE = "PERSONAL_FINANCE_UPDATE",
  /**
   * Reservation Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  RESERVATION_UPDATE = "RESERVATION_UPDATE",
  /**
   * Shipping Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  SHIPPING_UPDATE = "SHIPPING_UPDATE",
  /**
   * Ticket Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  TICKET_UPDATE = "TICKET_UPDATE",
  /**
   * Transactional
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  TRANSACTIONAL = "TRANSACTIONAL",
  /**
   * Transportation Update
   * @deprecated WhatsApp will migrate these to AUTHENTICATION, MARKETING or UTILITY before May 5 2023
   */
  TRANSPORTATION_UPDATE = "TRANSPORTATION_UPDATE",
  UTILITY = "UTILITY",
}

/** A WhatsApp message template component */
export type MessageTemplateComponent = {
  __typename?: "MessageTemplateComponent";
  index?: Maybe<Scalars["Int"]>;
  parameters?: Maybe<Array<Maybe<MessageTemplateComponentParameter>>>;
  subType?: Maybe<MessageTemplateComponentSubType>;
  type?: Maybe<MessageTemplateComponentType>;
};

/** A parameter for a message template component */
export type MessageTemplateComponentParameter = {
  type?: Maybe<MessageTemplateComponentParameterType>;
};

export type MessageTemplateComponentParameterDocument = MessageTemplateComponentParameter & {
  __typename?: "MessageTemplateComponentParameterDocument";
  document?: Maybe<MessageTemplateHeaderMediaParametersDocument>;
  type?: Maybe<MessageTemplateComponentParameterType>;
};

export type MessageTemplateComponentParameterImage = MessageTemplateComponentParameter & {
  __typename?: "MessageTemplateComponentParameterImage";
  image?: Maybe<MessageTemplateHeaderMediaParameters>;
  type?: Maybe<MessageTemplateComponentParameterType>;
};

export type MessageTemplateComponentParameterPayload = MessageTemplateComponentParameter & {
  __typename?: "MessageTemplateComponentParameterPayload";
  /**
   * If the template parameter is a payload linking to a Journey, this field
   * contains the Journey name so that the UI can display it.
   */
  linkedJourneyName?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["String"]>;
  type?: Maybe<MessageTemplateComponentParameterType>;
};

export type MessageTemplateComponentParameterText = MessageTemplateComponentParameter & {
  __typename?: "MessageTemplateComponentParameterText";
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<MessageTemplateComponentParameterType>;
};

export enum MessageTemplateComponentParameterType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  PAYLOAD = "PAYLOAD",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export type MessageTemplateComponentParameterVideo = MessageTemplateComponentParameter & {
  __typename?: "MessageTemplateComponentParameterVideo";
  type?: Maybe<MessageTemplateComponentParameterType>;
  video?: Maybe<MessageTemplateHeaderMediaParameters>;
};

export enum MessageTemplateComponentSubType {
  QUICK_REPLY = "QUICK_REPLY",
  URL = "URL",
}

export enum MessageTemplateComponentType {
  BODY = "BODY",
  BUTTON = "BUTTON",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
}

/** Parameters of a media template header */
export type MessageTemplateHeaderMediaParameters = {
  __typename?: "MessageTemplateHeaderMediaParameters";
  /** WhatsApp media ID */
  id?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
};

/** Parameters of a document template header */
export type MessageTemplateHeaderMediaParametersDocument = {
  __typename?: "MessageTemplateHeaderMediaParametersDocument";
  filename?: Maybe<Scalars["String"]>;
  /** WhatsApp media ID */
  id?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
};

/** Message Template input object, for creating Message Templates with */
export type MessageTemplateIo = {
  category: MessageTemplateCategoryType;
  components?: InputMaybe<Array<InputMaybe<MessageTemplateIoComponent>>>;
  /** @deprecated The template body is now part of the components field */
  content?: InputMaybe<Scalars["String"]>;
  language: MessageTemplateLanguageType;
  name: Scalars["String"];
};

/** A message template schema button */
export type MessageTemplateIoButton = {
  /** The example field can only be provided for URL buttons */
  example?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  text: Scalars["String"];
  type: MessageTemplateButtonType;
  url?: InputMaybe<Scalars["String"]>;
};

/** A message template schema component */
export type MessageTemplateIoComponent = {
  buttons?: InputMaybe<Array<InputMaybe<MessageTemplateIoButton>>>;
  example?: InputMaybe<MessageTemplateIoExample>;
  format?: InputMaybe<MessageTemplateSchemaComponentFormat>;
  text?: InputMaybe<Scalars["String"]>;
  type: MessageTemplateSchemaComponentType;
};

/** A message template component example */
export type MessageTemplateIoExample = {
  bodyText?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["String"]>>>>>;
  /** Graph file handle for example media files */
  headerHandle?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerText?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum MessageTemplateLanguagePolicyType {
  DETERMINISTIC = "DETERMINISTIC",
  FALLBACK = "FALLBACK",
}

/** A valid message template language */
export enum MessageTemplateLanguageType {
  /** Afrikaans */
  AF = "AF",
  /** Arabic */
  AR = "AR",
  /** Azerbaijani */
  AZ = "AZ",
  /** Bulgarian */
  BG = "BG",
  /** Bengali */
  BN = "BN",
  /** Catalan */
  CA = "CA",
  /** Czech */
  CS = "CS",
  /** Danish */
  DA = "DA",
  /** German */
  DE = "DE",
  /** Greek */
  EL = "EL",
  /** English */
  EN = "EN",
  /** English (UK) */
  EN_GB = "EN_GB",
  /** English (US) */
  EN_US = "EN_US",
  /** Spanish */
  ES = "ES",
  /** Spanish (ARG) */
  ES_AR = "ES_AR",
  /** Spanish (SPA) */
  ES_ES = "ES_ES",
  /** Spanish (MEX) */
  ES_MX = "ES_MX",
  /** Estonian */
  ET = "ET",
  /** Persian */
  FA = "FA",
  /** Finnish */
  FI = "FI",
  /** Filipino */
  FIL = "FIL",
  /** French */
  FR = "FR",
  /** Irish */
  GA = "GA",
  /** Gujarati */
  GU = "GU",
  /** Hausa */
  HA = "HA",
  /** Hebrew */
  HE = "HE",
  /** Hindi */
  HI = "HI",
  /** Croatian */
  HR = "HR",
  /** Hungarian */
  HU = "HU",
  /** Indonesian */
  ID = "ID",
  /** Italian */
  IT = "IT",
  /** Japanese */
  JA = "JA",
  /** Kazakh */
  KK = "KK",
  /** Kannada */
  KN = "KN",
  /** Korean */
  KO = "KO",
  /** Lao */
  LO = "LO",
  /** Lithuanian */
  LT = "LT",
  /** Latvian */
  LV = "LV",
  /** Macedonian */
  MK = "MK",
  /** Malayalam */
  ML = "ML",
  /** Marathi */
  MR = "MR",
  /** Malay */
  MS = "MS",
  /** Norwegian */
  NB = "NB",
  /** Dutch */
  NL = "NL",
  /** Punjabi */
  PA = "PA",
  /** Polish */
  PL = "PL",
  /** Portuguese (BR) */
  PT_BR = "PT_BR",
  /** Portuguese (POR) */
  PT_PT = "PT_PT",
  /** Romanian */
  RO = "RO",
  /** Russian */
  RU = "RU",
  /** Slovak */
  SK = "SK",
  /** Slovenian */
  SL = "SL",
  /** Albanian */
  SQ = "SQ",
  /** Serbian */
  SR = "SR",
  /** Swedish */
  SV = "SV",
  /** Swahili */
  SW = "SW",
  /** Tamil */
  TA = "TA",
  /** Telugu */
  TE = "TE",
  /** Thai */
  TH = "TH",
  /** Turkish */
  TR = "TR",
  /** Ukrainian */
  UK = "UK",
  /** Urdu */
  UR = "UR",
  /** Uzbek */
  UZ = "UZ",
  /** Vietnamese */
  VI = "VI",
  /** Chinese (CHN) */
  ZH_CN = "ZH_CN",
  /** Chinese (HKG) */
  ZH_HK = "ZH_HK",
  /** Chinese (TAI) */
  ZH_TW = "ZH_TW",
  /** Zulu */
  ZU = "ZU",
}

/** A Message template schema */
export type MessageTemplateSchema = {
  __typename?: "MessageTemplateSchema";
  category?: Maybe<MessageTemplateCategoryType>;
  components?: Maybe<Array<Maybe<MessageTemplateSchemaComponent>>>;
  /** @deprecated The template body is now part of the components field */
  content?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  graphId?: Maybe<Scalars["String"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  language?: Maybe<MessageTemplateLanguageType>;
  name?: Maybe<Scalars["String"]>;
  namespace?: Maybe<Scalars["String"]>;
  rejectedReason?: Maybe<Scalars["String"]>;
  status?: Maybe<MessageTemplateStatusType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** A message template schema button */
export type MessageTemplateSchemaButton = {
  text: Scalars["String"];
  type: MessageTemplateButtonType;
};

/** A message template schema phone number button */
export type MessageTemplateSchemaButtonPhoneNumber = MessageTemplateSchemaButton & {
  __typename?: "MessageTemplateSchemaButtonPhoneNumber";
  phoneNumber: Scalars["String"];
  text: Scalars["String"];
  type: MessageTemplateButtonType;
};

/** A message template schema quick reply button */
export type MessageTemplateSchemaButtonQuickReply = MessageTemplateSchemaButton & {
  __typename?: "MessageTemplateSchemaButtonQuickReply";
  text: Scalars["String"];
  type: MessageTemplateButtonType;
};

/** A message template schema url button */
export type MessageTemplateSchemaButtonUrl = MessageTemplateSchemaButton & {
  __typename?: "MessageTemplateSchemaButtonUrl";
  text: Scalars["String"];
  type: MessageTemplateButtonType;
  url: Scalars["String"];
};

/** A message template schema component */
export type MessageTemplateSchemaComponent = {
  type: MessageTemplateSchemaComponentType;
};

/** A message template schema body component */
export type MessageTemplateSchemaComponentBody = MessageTemplateSchemaComponent & {
  __typename?: "MessageTemplateSchemaComponentBody";
  text: Scalars["String"];
  type: MessageTemplateSchemaComponentType;
};

/** A message template schema buttons component */
export type MessageTemplateSchemaComponentButtons = MessageTemplateSchemaComponent & {
  __typename?: "MessageTemplateSchemaComponentButtons";
  buttons?: Maybe<Array<Maybe<MessageTemplateSchemaButton>>>;
  type: MessageTemplateSchemaComponentType;
};

/** A message template schema footer component */
export type MessageTemplateSchemaComponentFooter = MessageTemplateSchemaComponent & {
  __typename?: "MessageTemplateSchemaComponentFooter";
  text: Scalars["String"];
  type: MessageTemplateSchemaComponentType;
};

export enum MessageTemplateSchemaComponentFormat {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

/** A message template schema header component */
export type MessageTemplateSchemaComponentHeader = MessageTemplateSchemaComponent & {
  __typename?: "MessageTemplateSchemaComponentHeader";
  format: MessageTemplateSchemaComponentFormat;
  text?: Maybe<Scalars["String"]>;
  type: MessageTemplateSchemaComponentType;
};

export enum MessageTemplateSchemaComponentType {
  BODY = "BODY",
  BUTTONS = "BUTTONS",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
}

export enum MessageTemplateStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/** All possible statuses for a message template */
export enum MessageTemplateStatusType {
  /** Approved */
  APPROVED = "APPROVED",
  /** Deleted */
  DELETED = "DELETED",
  /** Disabled */
  DISABLED = "DISABLED",
  /** Flagged */
  FLAGGED = "FLAGGED",
  /** In Appeal */
  IN_APPEAL = "IN_APPEAL",
  /** Paused */
  PAUSED = "PAUSED",
  /** Pending */
  PENDING = "PENDING",
  /** Pending Deletion */
  PENDING_DELETION = "PENDING_DELETION",
  /** Rejected */
  REJECTED = "REJECTED",
}

/** A translation of a message, either sent or received */
export type MessageTranslation = {
  __typename?: "MessageTranslation";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  message?: Maybe<Message>;
  messageAttachmentId?: Maybe<Scalars["ID"]>;
  messageUuid?: Maybe<Scalars["ID"]>;
  originalLanguage?: Maybe<Language>;
  originalLanguageCode?: Maybe<Scalars["String"]>;
  originalMessage?: Maybe<Scalars["String"]>;
  status?: Maybe<TranscriptionsCaptionsAndTranslationsStatusType>;
  translatedLanguage?: Maybe<Language>;
  translatedLanguageCode?: Maybe<Scalars["String"]>;
  translatedMessage?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** All possible message types */
export enum MessageType {
  AUDIO = "AUDIO",
  BUTTON = "BUTTON",
  CALL_CONNECT = "CALL_CONNECT",
  CONTACTS = "CONTACTS",
  DOCUMENT = "DOCUMENT",
  EPHEMERAL = "EPHEMERAL",
  HSM = "HSM",
  IMAGE = "IMAGE",
  INTERACTIVE = "INTERACTIVE",
  LOCATION = "LOCATION",
  REACTION = "REACTION",
  REQUEST_WELCOME = "REQUEST_WELCOME",
  STICKER = "STICKER",
  SYSTEM = "SYSTEM",
  TEMPLATE = "TEMPLATE",
  TEXT = "TEXT",
  UNKNOWN = "UNKNOWN",
  VIDEO = "VIDEO",
  VOICE = "VOICE",
}

export enum MessageTypeType {
  AUDIO = "AUDIO",
  BUTTON = "BUTTON",
  DOCUMENT = "DOCUMENT",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
  IMAGE = "IMAGE",
  INTERACTIVE = "INTERACTIVE",
  LIST = "LIST",
  LIST_CALL_TO_ACTION = "LIST_CALL_TO_ACTION",
  MESSAGE = "MESSAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export type MessagesStats = {
  __typename?: "MessagesStats";
  data?: Maybe<MessagesStatsItem>;
  totalReceived?: Maybe<Scalars["Int"]>;
  totalSent?: Maybe<Scalars["Int"]>;
};

export type MessagesStatsDetails = {
  __typename?: "MessagesStatsDetails";
  count?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MessagesStatsItem = {
  __typename?: "MessagesStatsItem";
  inbound?: Maybe<Array<Maybe<MessagesStatsDetails>>>;
  outbound?: Maybe<Array<Maybe<MessagesStatsDetails>>>;
};

export type MonthlyCountrySpends = {
  __typename?: "MonthlyCountrySpends";
  spendings: Array<BillingCountrySpend>;
};

export type MonthlyDailySpends = {
  __typename?: "MonthlyDailySpends";
  spendings: Array<BillingDailySpend>;
};

export type MonthlyNumberSpends = {
  __typename?: "MonthlyNumberSpends";
  spendings: Array<BillingNumberSpend>;
};

export type MostActiveCountriesStats = {
  __typename?: "MostActiveCountriesStats";
  data?: Maybe<Array<Maybe<MostActiveCountriesStatsDetails>>>;
  totalUsers?: Maybe<Scalars["Int"]>;
};

export type MostActiveCountriesStatsDetails = {
  __typename?: "MostActiveCountriesStatsDetails";
  count?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MostUsedResponsesStats = {
  __typename?: "MostUsedResponsesStats";
  data?: Maybe<Array<Maybe<MostUsedResponsesStatsDetails>>>;
  totalResponses?: Maybe<Scalars["Int"]>;
};

export type MostUsedResponsesStatsDetails = {
  __typename?: "MostUsedResponsesStatsDetails";
  count?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** Multi Choice Card Answer */
export type MultiChoiceCardAnswer = {
  __typename?: "MultiChoiceCardAnswer";
  answer?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
};

export type NotebookCell = {
  section?: Maybe<NotebookCellSectionType>;
  type: NotebookCellType;
};

export type NotebookCellCode = NotebookCell & {
  __typename?: "NotebookCellCode";
  cards: Array<StackCard>;
  issues: Array<StackIssue>;
  meta?: Maybe<NotebookCellMeta>;
  section?: Maybe<NotebookCellSectionType>;
  /**
   * Triggers definitions as parsed from the DSL code present in this code cell.
   * If the code cell contains one or more Trigger definitions, we parse the definitions
   * and return the parsed structured Trigger data inside this `triggers` field.
   */
  triggers: Array<Trigger>;
  type: NotebookCellType;
  value: Scalars["String"];
};

export type NotebookCellDictionary = NotebookCell & {
  __typename?: "NotebookCellDictionary";
  section?: Maybe<NotebookCellSectionType>;
  type: NotebookCellType;
  value: DefaultDictionary;
};

export type NotebookCellIo = {
  code?: InputMaybe<Scalars["String"]>;
  dictionary?: InputMaybe<DictionaryIo>;
  markdown?: InputMaybe<Scalars["String"]>;
  section?: InputMaybe<NotebookSectionIo>;
  table?: InputMaybe<TableIo>;
  type: NotebookCellType;
};

export type NotebookCellMarkdown = NotebookCell & {
  __typename?: "NotebookCellMarkdown";
  section?: Maybe<NotebookCellSectionType>;
  type: NotebookCellType;
  value: Scalars["String"];
};

export type NotebookCellMeta = {
  __typename?: "NotebookCellMeta";
  approximateAudienceSize: Scalars["Int"];
};

export type NotebookCellSectionType = {
  __typename?: "NotebookCellSectionType";
  name: Scalars["String"];
  x: Scalars["Int"];
  y: Scalars["Int"];
};

export type NotebookCellTable = NotebookCell & {
  __typename?: "NotebookCellTable";
  section?: Maybe<NotebookCellSectionType>;
  type: NotebookCellType;
  value: Table;
};

export enum NotebookCellType {
  CODE = "CODE",
  DICTIONARY = "DICTIONARY",
  MARKDOWN = "MARKDOWN",
  TABLE = "TABLE",
}

export type NotebookIo = {
  cells: Array<NotebookCellIo>;
};

/**
 * A Notebook Revision stores a version of a Journey's notebook,
 * allowing users to see and restore previous versions of the Journey.
 */
export type NotebookRevision = {
  __typename?: "NotebookRevision";
  accountUuid?: Maybe<Scalars["ID"]>;
  hash: Scalars["String"];
  insertedAt: Scalars["DateTime"];
  isCanvas: Scalars["Boolean"];
  isNotebookValid: Scalars["Boolean"];
  notebook: NotebookType;
  referencedJourneyUuids: Array<Scalars["ID"]>;
  shortHash: Scalars["String"];
  uuid: Scalars["ID"];
  versionName?: Maybe<Scalars["String"]>;
};

export type NotebookRevisionConnection = {
  __typename?: "NotebookRevisionConnection";
  hasNextPage: Scalars["Boolean"];
  revisions: Array<Maybe<NotebookRevisionPreview>>;
};

/**
 * Preview of a NotebookRevision, returned when listing revisions.
 * Notably it misses the `notebook` field.
 */
export type NotebookRevisionPreview = {
  __typename?: "NotebookRevisionPreview";
  accountUuid?: Maybe<Scalars["ID"]>;
  hash: Scalars["String"];
  insertedAt: Scalars["DateTime"];
  isCanvas: Scalars["Boolean"];
  isNotebookValid: Scalars["Boolean"];
  shortHash: Scalars["String"];
  uuid: Scalars["ID"];
  versionName?: Maybe<Scalars["String"]>;
};

export type NotebookSectionIo = {
  name: Scalars["String"];
  x: Scalars["Int"];
  y: Scalars["Int"];
};

export type NotebookType = {
  __typename?: "NotebookType";
  cells: Array<NotebookCell>;
  dictionaries: Array<Dictionary>;
  doc?: Maybe<Scalars["String"]>;
  stacks: Array<Stack>;
  stacksValid?: Maybe<Scalars["Boolean"]>;
  tables: Array<Table>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type Number = {
  __typename?: "Number";
  about?: Maybe<Scalars["String"]>;
  aboutUpdatedAt?: Maybe<Scalars["DateTime"]>;
  actionsCards?: Maybe<Array<Maybe<ActionsCard>>>;
  address?: Maybe<Scalars["String"]>;
  allowMessagingWithInvalidSubscription?: Maybe<Scalars["Boolean"]>;
  automators?: Maybe<Array<Maybe<Automator>>>;
  automatorsCount?: Maybe<Scalars["Int"]>;
  backendType?: Maybe<BackendType>;
  bigqueryErrors?: Maybe<Scalars["String"]>;
  billingEnabled?: Maybe<Scalars["Boolean"]>;
  /** List all of the language used for any Card for this number */
  cardLanguages: Array<Scalars["String"]>;
  /** List all of the number tags applied to any Card for this number */
  cardNumberTags?: Maybe<Array<Maybe<NumberTag>>>;
  cards?: Maybe<Array<Maybe<Card>>>;
  cardsCount?: Maybe<Scalars["Int"]>;
  chats?: Maybe<Array<Maybe<Chat>>>;
  collections?: Maybe<Array<Maybe<Collection>>>;
  contactSchema?: Maybe<ContactSchema>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  /**
   * List all of the language used for any FAQ for this number
   * @deprecated Use cardLanguages instead
   */
  faqLanguages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**
   * List all of the number tags applied to any FAQ for this number
   * @deprecated Use cardNumberTags instead
   */
  faqNumberTags?: Maybe<Array<Maybe<NumberTag>>>;
  fbGraphId?: Maybe<Scalars["ID"]>;
  fromAddr?: Maybe<Scalars["String"]>;
  generalRateLimit?: Maybe<Scalars["String"]>;
  graphAccountMode?: Maybe<GraphAccountMode>;
  graphNameStatus?: Maybe<GraphNameStatus>;
  graphQualityScore?: Maybe<GraphQualityScore>;
  graphStatus?: Maybe<GraphStatus>;
  hasBigqueryServiceAccount?: Maybe<Scalars["Boolean"]>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  isRoutingEnabled?: Maybe<Scalars["Boolean"]>;
  languages?: Maybe<Array<Maybe<NumberLanguage>>>;
  mediaRateLimit?: Maybe<Scalars["String"]>;
  messageTemplates?: Maybe<Array<Maybe<MessageTemplateSchema>>>;
  messageTemplatesCount?: Maybe<Scalars["Int"]>;
  /**
   * Returns list of distinct languages that are used in existing message templates
   * for the parent number.
   */
  messageTemplatesLanguages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  notificationsEmail?: Maybe<Scalars["String"]>;
  numberCode?: Maybe<Scalars["String"]>;
  numberRoute?: Maybe<Number>;
  numberTags?: Maybe<Array<Maybe<NumberTag>>>;
  numberTokenSignatures?: Maybe<Array<Maybe<NumberTokenSignature>>>;
  numberType?: Maybe<NumberType>;
  operatorCapacityForRouting?: Maybe<Scalars["Int"]>;
  organisation?: Maybe<Organisation>;
  phoneNumberProvider?: Maybe<PhoneNumberProvider>;
  profilePicture?: Maybe<Scalars["String"]>;
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  remindersCount?: Maybe<Scalars["Int"]>;
  secureFormUrl?: Maybe<Scalars["String"]>;
  sentryUrl?: Maybe<Scalars["String"]>;
  signupType?: Maybe<SignupType>;
  streamStates?: Maybe<Array<Maybe<StreamState>>>;
  /** Information on the subscription plan attached to the number. */
  subscription?: Maybe<NumberSubscription>;
  textRateLimit?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["ID"]>;
  vertical?: Maybe<Scalars["String"]>;
  vname?: Maybe<Scalars["String"]>;
  /** WABA that owns the number */
  waba?: Maybe<Waba>;
  wabizClientProvisionState?: Maybe<WabizClientProvisionState>;
  webhookSubscriptions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  webhooks?: Maybe<Array<Maybe<Webhook>>>;
  websites?: Maybe<Array<Maybe<Scalars["String"]>>>;
  whatsappTemplateAccessToken?: Maybe<Scalars["String"]>;
  /** @deprecated Please read the template namespace from the WABA that owns the number */
  whatsappTemplateNamespace?: Maybe<Scalars["String"]>;
  whatsappTier?: Maybe<WhatsappTier>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberActionsCardsArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberAutomatorsArgs = {
  actionType?: InputMaybe<ActionTypeType>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberAutomatorsCountArgs = {
  actionType?: InputMaybe<ActionTypeType>;
  search?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberCardsArgs = {
  language?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type?: InputMaybe<CardType>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberCardsCountArgs = {
  language?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type?: InputMaybe<CardType>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberChatsArgs = {
  fromUuid?: InputMaybe<Scalars["ID"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberCollectionsArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberMessageTemplatesArgs = {
  language?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<MessageTemplateStatus>;
  withQuickReplyButtons?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberMessageTemplatesCountArgs = {
  language?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<MessageTemplateStatus>;
  withQuickReplyButtons?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberNumberTagsArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberRemindersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

/**
 * A number represents a number that a service connected via Turn is accessible on.
 * All chats are linked to a number.
 */
export type NumberRemindersCountArgs = {
  search?: InputMaybe<Scalars["String"]>;
};

/** A number input object, to modify a number via graphql */
export type NumberIo = {
  about?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  bigqueryServiceAccount?: InputMaybe<Scalars["Json"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  isRoutingEnabled?: InputMaybe<Scalars["Boolean"]>;
  notificationsEmail?: InputMaybe<Scalars["String"]>;
  operatorCapacityForRouting?: InputMaybe<Scalars["Int"]>;
  vertical?: InputMaybe<NumberVerticalType>;
  websites?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type NumberLanguage = {
  __typename?: "NumberLanguage";
  code?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type NumberPerformanceMetrics = {
  __typename?: "NumberPerformanceMetrics";
  averageChatsPerDay?: Maybe<Scalars["Int"]>;
  averageCloseTime?: Maybe<Scalars["Int"]>;
};

/** Input object specifying what type of number the client wants to use for Embedded Signup */
export type NumberRequest = {
  clientOwnedNumber?: InputMaybe<Scalars["String"]>;
  customNumberCountry?: InputMaybe<Scalars["String"]>;
  customNumberState?: InputMaybe<Scalars["String"]>;
  poolNumberUuid?: InputMaybe<Scalars["String"]>;
  requestedNumberType: RequestedNumberType;
};

export type NumberSubscription = {
  __typename?: "NumberSubscription";
  additionalInformation?: Maybe<Scalars["String"]>;
  billingTransactions?: Maybe<Array<BillingSubscriptionTransaction>>;
  cancelUrl?: Maybe<Scalars["String"]>;
  collectionMode?: Maybe<BillingCollectionMode>;
  customerId?: Maybe<Scalars["String"]>;
  nextPaymentAmount?: Maybe<Scalars["Float"]>;
  nextPaymentDate?: Maybe<Scalars["Date"]>;
  nextPaymentDiscount?: Maybe<Scalars["Float"]>;
  pausedAt?: Maybe<Scalars["NaiveDateTime"]>;
  priceName?: Maybe<Array<SubscriptionPlan>>;
  productName: SubscriptionProduct;
  recurringTransactionAmount?: Maybe<Scalars["Float"]>;
  scheduledChangeAction?: Maybe<ScheduledChange>;
  scheduledChangeAt?: Maybe<Scalars["Date"]>;
  state?: Maybe<SubscriptionState>;
  subscriptionId?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
};

/**
 * A tag that can be applied to messages, always limited in scope to a number.
 * The color is a CSS color spec.
 */
export type NumberTag = {
  __typename?: "NumberTag";
  color?: Maybe<Scalars["String"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
  value?: Maybe<Scalars["String"]>;
};

/** A token for a number */
export type NumberToken = {
  __typename?: "NumberToken";
  expiresAt?: Maybe<Scalars["DateTime"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  jwt?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** A token signature for a number */
export type NumberTokenSignature = {
  __typename?: "NumberTokenSignature";
  expiresAt?: Maybe<Scalars["DateTime"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Number>;
  signature?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** The number type for the number */
export enum NumberType {
  CHANNEL_HTTP_API = "CHANNEL_HTTP_API",
  DIRECT_NUMBER = "DIRECT_NUMBER",
  VIRTUAL_NUMBER = "VIRTUAL_NUMBER",
  VIRTUAL_NUMBER_PROVIDER = "VIRTUAL_NUMBER_PROVIDER",
}

/** The possible types of a number vertical */
export enum NumberVerticalType {
  AUTOMOTIVE = "AUTOMOTIVE",
  BEAUTY_SPA_AND_SALON = "BEAUTY_SPA_AND_SALON",
  CLOTHING_AND_APPAREL = "CLOTHING_AND_APPAREL",
  EDUCATION = "EDUCATION",
  ENTERTAINMENT = "ENTERTAINMENT",
  EVENT_PLANNING_AND_SERVICE = "EVENT_PLANNING_AND_SERVICE",
  FINANCE_AND_BANKING = "FINANCE_AND_BANKING",
  FOOD_AND_GROCERY = "FOOD_AND_GROCERY",
  HOTEL_AND_LODGING = "HOTEL_AND_LODGING",
  MEDICAL_AND_HEALTH = "MEDICAL_AND_HEALTH",
  NON_PROFIT = "NON_PROFIT",
  OTHER = "OTHER",
  PROFESSIONAL_SERVICES = "PROFESSIONAL_SERVICES",
  PUBLIC_SERVICE = "PUBLIC_SERVICE",
  RESTAURANT = "RESTAURANT",
  SHOPPING_AND_RETAIL = "SHOPPING_AND_RETAIL",
  TRAVEL_AND_TRANSPORTATION = "TRAVEL_AND_TRANSPORTATION",
}

/** An onboarding application stores the state and data for the organisation onboarding flow */
export type OnboardingApplication = {
  __typename?: "OnboardingApplication";
  customNumberRequest?: Maybe<CustomNumberRequest>;
  eulaAccepted: Scalars["Boolean"];
  impactArea?: Maybe<ImpactSectorType>;
  organisationType?: Maybe<OrganisationEntityType>;
  phoneNumber?: Maybe<Scalars["String"]>;
  phoneNumberProvider?: Maybe<PhoneNumberProvider>;
  poolNumber?: Maybe<PoolNumber>;
  status: OnboardingApplicationStatus;
  uuid: Scalars["ID"];
};

/** The status of an onboarding application, used to track where in the onboarding flow an organisation is */
export enum OnboardingApplicationStatus {
  CANCELLED = "CANCELLED",
  CHOOSE_PLAN = "CHOOSE_PLAN",
  COMPLETED = "COMPLETED",
  EMBEDDED_SIGNUP = "EMBEDDED_SIGNUP",
  NUMBER_SELECTION = "NUMBER_SELECTION",
  STARTED = "STARTED",
  WAITING_FOR_CUSTOM_NUMBER = "WAITING_FOR_CUSTOM_NUMBER",
}

/**
 * Public configuration for onboarding, needed by
 * the frontend to configure the Facebook JS SDK.
 */
export type OnboardingConfiguration = {
  __typename?: "OnboardingConfiguration";
  bspBusinessLoginConfigurationId: Scalars["String"];
  bspFacebookAppId: Scalars["String"];
};

/** A record of an operator's availability */
export type OperatorAvailability = {
  __typename?: "OperatorAvailability";
  accountUuid?: Maybe<Scalars["ID"]>;
  isAvailable?: Maybe<Scalars["Boolean"]>;
  numberUuid?: Maybe<Scalars["ID"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type OperatorPerformanceMetrics = {
  __typename?: "OperatorPerformanceMetrics";
  averageChatsPerDay?: Maybe<Scalars["Int"]>;
  averageCloseChatsPerDay?: Maybe<Scalars["Int"]>;
  averageCloseTime?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  operatorUuid?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
};

/**
 * An organisation on Turn.
 * Organisations house numbers.
 */
export type Organisation = {
  __typename?: "Organisation";
  accounts?: Maybe<Array<Maybe<Account>>>;
  activeOnboardingApplication?: Maybe<OnboardingApplication>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  myAccount?: Maybe<Account>;
  name?: Maybe<Scalars["String"]>;
  numbers?: Maybe<Array<Maybe<Number>>>;
  organisationAccounts?: Maybe<Array<Maybe<OrganisationAccount>>>;
  organisationTokens?: Maybe<Array<Maybe<OrganisationToken>>>;
  privacyEnabled?: Maybe<Scalars["Boolean"]>;
  signupReferral?: Maybe<Scalars["String"]>;
  signupType?: Maybe<OrganisationSignupType>;
  type?: Maybe<OrganisationType>;
  uuid?: Maybe<Scalars["ID"]>;
  waba?: Maybe<Waba>;
  website?: Maybe<Scalars["String"]>;
};

/** An organisation account links an account to an organisation */
export type OrganisationAccount = {
  __typename?: "OrganisationAccount";
  account?: Maybe<Account>;
  isAdmin?: Maybe<Scalars["Boolean"]>;
  organisation?: Maybe<Organisation>;
  role?: Maybe<RoleType>;
};

/** The type of organisation entity */
export enum OrganisationEntityType {
  FOR_PROFIT_SOCIAL_ENTERPRISE = "FOR_PROFIT_SOCIAL_ENTERPRISE",
  GOVERNMENT_GOVERNMENT_AGENCY = "GOVERNMENT_GOVERNMENT_AGENCY",
  INGO = "INGO",
  NON_PROFIT_NGO = "NON_PROFIT_NGO",
  UNITED_NATIONS = "UNITED_NATIONS",
}

/** The signup type of an organisation */
export enum OrganisationSignupType {
  CUSTOM = "CUSTOM",
  SELF_SIGNUP = "SELF_SIGNUP",
}

/** A token that's available for any number in the organisation, these are generally tokens required to integrate with external services such as Facebook */
export type OrganisationToken = {
  __typename?: "OrganisationToken";
  createdAt?: Maybe<Scalars["DateTime"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  type?: Maybe<OrganisationTokenType>;
  uuid?: Maybe<Scalars["ID"]>;
};

export enum OrganisationTokenType {
  FACEBOOK = "FACEBOOK",
  META_AI = "META_AI",
  OPENAI = "OPENAI",
}

/** The type of organisation */
export enum OrganisationType {
  GROUP = "GROUP",
  INDIVIDUAL = "INDIVIDUAL",
}

/** All possible output state kinds */
export enum OutputStateKind {
  INTERACTIVE = "INTERACTIVE",
  LOG = "LOG",
  MESSAGE = "MESSAGE",
}

export type PaddleBillingPrice = {
  __typename?: "PaddleBillingPrice";
  billingCycle?: Maybe<BillingCycle>;
  description?: Maybe<PriceDescriptions>;
  id: Scalars["String"];
  trialPeriod?: Maybe<BillingCycle>;
  unitPrice?: Maybe<UnitPrice>;
};

export type PaddleConfiguration = {
  __typename?: "PaddleConfiguration";
  sandboxMode?: Maybe<Scalars["Boolean"]>;
  vendorId: Scalars["Int"];
};

export enum PaddleCurrencyCode {
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

export enum PaddleInterval {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum PaddleVersion {
  BILLING = "BILLING",
  CLASSIC = "CLASSIC",
}

/**
 * Exposes the performance metrics of a given number and its associated operators
 * over a specified number of days.
 */
export type PerformanceMetrics = {
  __typename?: "PerformanceMetrics";
  number?: Maybe<NumberPerformanceMetrics>;
  operators?: Maybe<Array<Maybe<OperatorPerformanceMetrics>>>;
};

/** Event happening for a phone number, for example a received phone call */
export type PhoneEvent = {
  __typename?: "PhoneEvent";
  content: Scalars["String"];
  type: PhoneEventType;
};

/** The type of phone event that was received */
export enum PhoneEventType {
  SMS_RECEIVED = "SMS_RECEIVED",
  VOICE_CALL_RECEIVED = "VOICE_CALL_RECEIVED",
}

/**
 * The provider of the phone number. Records if the phone number is owned
 * by the client or was provided by Turn via Sudonum.
 */
export enum PhoneNumberProvider {
  CLIENT_OWNED = "CLIENT_OWNED",
  SUDONUM = "SUDONUM",
  SUDONUM_CUSTOM = "SUDONUM_CUSTOM",
}

export type Playbook = {
  __typename?: "Playbook";
  account?: Maybe<Account>;
  categories?: Maybe<Array<Maybe<Category>>>;
  contributors?: Maybe<Array<Maybe<Account>>>;
  description?: Maybe<Scalars["String"]>;
  evidence?: Maybe<Scalars["String"]>;
  generation?: Maybe<Scalars["Int"]>;
  headerPicture?: Maybe<Scalars["String"]>;
  initialVersionUuid?: Maybe<Scalars["ID"]>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  latest?: Maybe<Scalars["Boolean"]>;
  license?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Organisation>;
  playbookAttachments: Array<PlaybookAttachment>;
  previousTwoVersions?: Maybe<Array<Maybe<PlaybookEarlierVersion>>>;
  stackConfig?: Maybe<PlaybookStackConfig>;
  threadConfig?: Maybe<Scalars["Json"]>;
  title?: Maybe<Scalars["String"]>;
  type: PlaybookType;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
  version?: Maybe<Scalars["String"]>;
  versionReleaseNotes?: Maybe<Scalars["String"]>;
};

export type PlaybookAttachment = {
  __typename?: "PlaybookAttachment";
  filename: Scalars["String"];
  mediaType: Scalars["String"];
  mimeType: Scalars["String"];
  signedUri: Scalars["String"];
  signedUriExpiresAt: Scalars["String"];
  uuid: Scalars["ID"];
};

/** Playbook attachment object,used for creating Playbooks */
export type PlaybookAttachmentIo = {
  filename: Scalars["String"];
  mediaType: Scalars["String"];
  mimeType: Scalars["String"];
  signedUri: Scalars["String"];
  signedUriExpiresAt: Scalars["String"];
  uuid?: InputMaybe<Scalars["ID"]>;
};

export type PlaybookEarlierVersion = {
  __typename?: "PlaybookEarlierVersion";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
  version?: Maybe<Scalars["String"]>;
  versionReleaseNotes?: Maybe<Scalars["String"]>;
};

export type PlaybookStackConfig = {
  __typename?: "PlaybookStackConfig";
  doc: Scalars["String"];
  isCanvas: Scalars["Boolean"];
  notebook: NotebookType;
  uuid: Scalars["ID"];
};

export enum PlaybookType {
  GENERIC = "GENERIC",
  STACK = "STACK",
  THREAD = "THREAD",
}

export type Playbooks = {
  __typename?: "Playbooks";
  playbooks?: Maybe<Array<Maybe<Playbook>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A number from the pool of numbers available for clients signing up */
export type PoolNumber = {
  __typename?: "PoolNumber";
  fbGraphId: Scalars["String"];
  phoneNumber: Scalars["String"];
  uuid: Scalars["ID"];
};

export enum PriceDescriptions {
  PRO_ANNUAL_NO_TRIAL = "PRO_ANNUAL_NO_TRIAL",
  PRO_ANNUAL_TRIAL = "PRO_ANNUAL_TRIAL",
  PRO_MONTHLY_NO_TRIAL = "PRO_MONTHLY_NO_TRIAL",
  PRO_MONTHLY_TRIAL = "PRO_MONTHLY_TRIAL",
}

export type PricesConfiguration = {
  __typename?: "PricesConfiguration";
  paddleBillingPrices?: Maybe<Array<Maybe<PaddleBillingPrice>>>;
  paddleConfiguration?: Maybe<PaddleConfiguration>;
};

/** A Reminder object */
export type Reminder = {
  __typename?: "Reminder";
  account?: Maybe<Account>;
  attempted: Scalars["Int"];
  delivered: Scalars["Int"];
  error: Scalars["Int"];
  failed: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  isCancelled: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Number>;
  query: Scalars["String"];
  queued: Scalars["Int"];
  read: Scalars["Int"];
  scheduledAt?: Maybe<Scalars["DateTime"]>;
  segment?: Maybe<ContactsSegment>;
  sent: Scalars["Int"];
  sentToFallbackChannel: Scalars["Int"];
  sentToWhatsapp: Scalars["Int"];
  templateParams?: Maybe<Template>;
  total: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  uuid: Scalars["ID"];
};

export type ReminderError = {
  __typename?: "ReminderError";
  code?: Maybe<Scalars["Int"]>;
  count: Scalars["Int"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type Reminders = {
  __typename?: "Reminders";
  reminders: Array<Reminder>;
  totalCount: Scalars["Int"];
};

export type RequestData = {
  __typename?: "RequestData";
  body?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
  interval: Scalars["Int"];
  method: Scalars["String"];
  status?: Maybe<Scalars["Int"]>;
  timestamp: Scalars["Int"];
};

/** Type of number requested by the client */
export enum RequestedNumberType {
  CLIENT_OWNED = "CLIENT_OWNED",
  POOL_NUMBER = "POOL_NUMBER",
  TURN_PROVIDED_CUSTOM = "TURN_PROVIDED_CUSTOM",
  TURN_PROVIDED_DEFAULT = "TURN_PROVIDED_DEFAULT",
}

export type ResponseTypeStats = {
  __typename?: "ResponseTypeStats";
  data?: Maybe<Array<Maybe<ResponseTypeStatsDetails>>>;
  totalResponses?: Maybe<Scalars["Int"]>;
};

export type ResponseTypeStatsDetails = {
  __typename?: "ResponseTypeStatsDetails";
  count?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** Available roles for organisation account role field */
export enum RoleType {
  ADMIN = "ADMIN",
  BUILDER = "BUILDER",
  OPERATOR = "OPERATOR",
}

export type RootMutationType = {
  __typename?: "RootMutationType";
  /** Accept EULA and move to the next step of the onboarding journey */
  acceptEula?: Maybe<Organisation>;
  /** Activate a playbook for an organisation's number, creating its own thread or stack */
  activateImportedPlaybook: ActivatedPlaybook;
  addCredit?: Maybe<AddCreditResponse>;
  /** Archive a playbook for an organisation */
  archivePlaybook?: Maybe<Playbook>;
  /** Assign a number tag to a card */
  assignCardNumberTag?: Maybe<Card>;
  /** Assign a chat to an account */
  assignChat?: Maybe<Chat>;
  /** Assign a number tag to a message */
  assignMessageNumberTag?: Maybe<Message>;
  blockContact?: Maybe<ContactProfileSerialized>;
  /** Cancels the current active onboarding application for the organisation */
  cancelActiveOnboardingApplication?: Maybe<OnboardingApplication>;
  cancelPaddleScheduledPause: Scalars["Boolean"];
  /**
   * Cancel a reminder.
   * If reminder messages are currently being sent, the send
   * will stop immediately.
   */
  cancelReminder?: Maybe<Reminder>;
  /** Convert all threads to journeys */
  convertAllThreadsToJourneys: Scalars["ID"];
  /** Convert thread to journey */
  convertThreadToJourney: ThreadGraph;
  /** Create a new account and invite them to join the organisation via email */
  createAccount?: Maybe<OrganisationAccount>;
  /** Create multiple accounts under a single organization */
  createAccounts?: Maybe<Array<Maybe<OrganisationAccount>>>;
  /** Create an actions card for a number */
  createActionsCard?: Maybe<ActionsCard>;
  /** Create a new automator */
  createAutomator?: Maybe<Automator>;
  /** Create a chat note (Message with 'TURN_CHAT_NOTE' source_type) */
  createChatNote?: Maybe<Message>;
  /** Create a Collection for a number */
  createCollection?: Maybe<Collection>;
  createContactSchema?: Maybe<ContactSchemaSerialized>;
  /** Create a content for a number */
  createContent?: Maybe<Card>;
  /** Create export */
  createExport?: Maybe<Export>;
  /** Create a new integration for a number */
  createIntegration?: Maybe<Integration>;
  /** Create global journey data for a number */
  createJourneyGlobalData: JourneyData;
  /** Create local journey data */
  createJourneyLocalData: JourneyData;
  /** Create a new Paddle Subscription with manual collection mode */
  createManualPaddleSubscription: Scalars["String"];
  /** Create a Message Template for a number */
  createMessageTemplate?: Maybe<MessageTemplateSchema>;
  /** Create a notebook for a number */
  createNotebook: StackContainer;
  /** Create a number tag */
  createNumberTag?: Maybe<NumberTag>;
  /** Submit signup form for a new (non-existing) organisation */
  createOrgAndSubmitSignUpForm?: Maybe<Organisation>;
  createSimulator: Simulator;
  /** Create a stack data export */
  createStackDataExport?: Maybe<Export>;
  /** Create a Templated Message for a template */
  createTemplatedMessage?: Maybe<Message>;
  /** Create a thread edge */
  createThreadEdge?: Maybe<ThreadEdge>;
  /** Create a thread graph */
  createThreadGraph?: Maybe<ThreadGraph>;
  /** Create a thread node */
  createThreadNode?: Maybe<ThreadNode>;
  /** Create a new webhook */
  createWebhook?: Maybe<Webhook>;
  /** Delete an account from an organisation */
  deleteAccount?: Maybe<OrganisationAccount>;
  /** Delete an actions card for a number */
  deleteActionsCard?: Maybe<ActionsCard>;
  /** Delete an automator */
  deleteAutomator?: Maybe<Automator>;
  /** delete bigquery service account */
  deleteBigqueryServiceAccount?: Maybe<Number>;
  /** Delete a chat note (Message with 'TURN_CHAT_NOTE' source_type) */
  deleteChatNote?: Maybe<Message>;
  /** Delete a Collection for a number */
  deleteCollection?: Maybe<Collection>;
  /** Delete an content for a number */
  deleteContent?: Maybe<Card>;
  /** Delete an integration for a number */
  deleteIntegration?: Maybe<Integration>;
  /** Delete global journey data for a number */
  deleteJourneyGlobalData: JourneyData;
  /** Delete local journey data */
  deleteJourneyLocalData: JourneyData;
  /** Delete message */
  deleteMessage?: Maybe<Message>;
  /** Delete a Message Template for a number */
  deleteMessageTemplate?: Maybe<MessageTemplateSchema>;
  /** Delete a notebook for a number */
  deleteNotebook: StackContainer;
  /** Delete a number tag */
  deleteNumberTag?: Maybe<NumberTag>;
  /** Soft delete an API token */
  deleteNumberToken?: Maybe<NumberTokenSignature>;
  /** Delete an Organisation Token */
  deleteOrganisationToken?: Maybe<OrganisationToken>;
  deleteSimulator?: Maybe<Simulator>;
  /** Delete a thread */
  deleteThread?: Maybe<DeletedUuid>;
  /** Delete a new webhook */
  deleteWebhook?: Maybe<Webhook>;
  /** Duplicate a journey for a number */
  duplicateJourney: StackContainer;
  getOrCreatePaddleCustomer: Scalars["String"];
  /** Import a playbook for an organisation */
  importPlaybook?: Maybe<ImportedPlaybook>;
  /** Import thread json to create a new thread */
  importThreadJson?: Maybe<Thread>;
  /** Mark messages as handled/unhandled */
  markMessagesAsHandled?: Maybe<Array<Maybe<Message>>>;
  /** Assign a name to a notebook revision so that it is easier to find */
  nameNotebookRevision: NotebookRevisionPreview;
  /** Sends an email to impact@turn.io when Enterprise billing is chosen */
  newEnterpriseCustomerEmail?: Maybe<Scalars["Boolean"]>;
  /**
   * Onboard embedded signup number after the user completes
   * Facebook's Embedded Signup dialog
   */
  onboardEmbeddedSignupNumber?: Maybe<Number>;
  /** Take ownership by overriding an active lease. */
  overrideLease?: Maybe<Lease>;
  patchContactProfile?: Maybe<ContactProfileSerialized>;
  pausePaddleSubscription: Scalars["Boolean"];
  /** Perform a integration handshake for a number */
  performIntegrationHandshake?: Maybe<Integration>;
  /** Preview a translation */
  previewTranslation?: Maybe<TranslationPreview>;
  /** Publish the latest revision of a notebook */
  publishLatestNotebookRevision: StackContainer;
  /** Publish a playbook for a thread or a stack */
  publishPlaybook?: Maybe<Playbook>;
  /**
   * Release a lease.
   *
   * If the lease has a child lease, it'll will make the child lease the
   * active one again.
   *
   * NOTE that there's no point in asking for the childLease using this mutation
   * as the child will already be the parent once this resolver completes.
   */
  releaseLease?: Maybe<Lease>;
  /** Remove a number tag from a card */
  removeCardNumberTag?: Maybe<Card>;
  /** Remove a number tag from a message */
  removeMessageNumberTag?: Maybe<Message>;
  /** Request API credentials */
  requestApiCredentials?: Maybe<ApiCredentials>;
  /**
   * Request a lease for an operator.
   *
   * Allows an operator to take control of a conversation,
   * disabling all automations while the lease is active.
   */
  requestLease?: Maybe<Lease>;
  /** Request API token */
  requestNumberToken?: Maybe<NumberToken>;
  /** Request a phone number to use for Embedded Signup */
  requestPhoneNumber?: Maybe<Organisation>;
  /** Request Virtual Number */
  requestVirtualNumber?: Maybe<Number>;
  /** Reset an chat's unread count to zero */
  resetUnreadCounter?: Maybe<Chat>;
  /** Restart a lease by re-sending the last message. */
  restartLease?: Maybe<Lease>;
  /** Restore a journey to a previous revision */
  restoreNotebookRevision: StackContainer;
  resumePausedPaddleSubscription: Scalars["Boolean"];
  /** Schedule a reminder */
  scheduleReminder?: Maybe<Reminder>;
  /** Initiate a search on chat objects */
  search?: Maybe<ChatSearchResults>;
  /**
   * Initiate a search on message objects
   * @deprecated Superseded by message_search_results
   */
  searchMessages?: Maybe<MessageSearchResults>;
  /** Send a reaction to a message */
  sendReactionToMessage?: Maybe<Message>;
  /** Set an operator as available and assign them chats */
  setOperatorAsAvailable?: Maybe<AvailabilityAndChatsAssignedCount>;
  /** Set an operator as unavailable and inform the BE whether it should release their chats */
  setOperatorAsUnavailable?: Maybe<OperatorAvailability>;
  /** Set privacy enabled field on an organisation */
  setPrivacyEnabledField?: Maybe<Organisation>;
  setStagingMode?: Maybe<ContactProfileSerialized>;
  /** Set translation option for a specific language */
  setTranslationOption?: Maybe<TranslationOption>;
  /** Start an integration action */
  startIntegrationAction?: Maybe<IntegrationActionNotification>;
  /** Starts a Journey for a given stack container and chat */
  startJourney: Message;
  /**
   * Deprecated - keeping it here so that the frontend compiles. To be removed.
   * @deprecated Deprecated - keeping it here so that the frontend compiles. To be removed.
   */
  startOnboardingApplication?: Maybe<Scalars["Boolean"]>;
  /**
   * Start a new onboarding application for an existing organisation.
   * Useful to start the process of onboarding a new number for an existing organisation.
   */
  startOnboardingApplicationForAdditionalNumber?: Maybe<OnboardingApplication>;
  /** Send an interactive message to the owner of a chat */
  submitInteractiveMessage?: Maybe<Message>;
  /** Submit organisation details via the sign up form and move on to accept EULA */
  submitSignUpOrganisationForm?: Maybe<Organisation>;
  submitSimulatorInput: SimulatorResponse;
  /** Send a message to the owner of a chat */
  submitTextMessage?: Maybe<Message>;
  /** Submit use case description to be used for application for free conversations */
  submitUseCaseDescription?: Maybe<Organisation>;
  /** @deprecated Switch manual->automatic no longer supported, to be removed */
  switchCollectionModeToAutomatic: SwitchRequest;
  /** Tagged account can toggle the is_cleared status of a mention */
  toggleMentionIsCleared?: Maybe<ChatNoteMention>;
  /** Enable/Disable a notebook */
  toggleNotebook: StackContainer;
  /** Toggle a chat pin */
  togglePinChat?: Maybe<Chat>;
  /** Translates messages according to the language pair given */
  translateMessages?: Maybe<TranslateMessagesResult>;
  unblockContact?: Maybe<ContactProfileSerialized>;
  /** Update an account's details */
  updateAccount?: Maybe<Account>;
  /** Update an actions card for a number */
  updateActionsCard?: Maybe<ActionsCard>;
  /** Update an automator */
  updateAutomator?: Maybe<Automator>;
  /** Update a chat note (Message with 'TURN_CHAT_NOTE' source_type) */
  updateChatNote?: Maybe<Message>;
  /** Update a chat's state */
  updateChatState?: Maybe<Chat>;
  /** Update a Collection for a number */
  updateCollection?: Maybe<Collection>;
  updateContactProfile?: Maybe<ContactProfileSerialized>;
  /** Update an content for a number */
  updateContent?: Maybe<Card>;
  /** Update an integration for a number */
  updateIntegration?: Maybe<Integration>;
  /** Update global journey data for a number */
  updateJourneyGlobalData: JourneyData;
  /** Update local journey data */
  updateJourneyLocalData: JourneyData;
  /** Update journey translation */
  updateJourneyTranslations: JourneyTable;
  /** Update a notebook for a number */
  updateNotebook: StackContainer;
  /** Update number */
  updateNumber?: Maybe<Number>;
  /** Update a number tag */
  updateNumberTag?: Maybe<NumberTag>;
  /** Update the admin status for an organisation account */
  updateOrganisationAccount?: Maybe<OrganisationAccount>;
  /** Update a playbook for an organisation */
  updatePlaybook?: Maybe<Playbook>;
  /** Update a thread */
  updateThread?: Maybe<Thread>;
  /** Update a thread edge */
  updateThreadEdge?: Maybe<ThreadEdge>;
  /** Update a thread graph */
  updateThreadGraph?: Maybe<ThreadGraph>;
  /** Update a thread node */
  updateThreadNode?: Maybe<ThreadNode>;
  /** Update a new webhook */
  updateWebhook?: Maybe<Webhook>;
};

export type RootMutationTypeAcceptEulaArgs = {
  eulaVersion?: InputMaybe<Scalars["String"]>;
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeActivateImportedPlaybookArgs = {
  importedPlaybookUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeAddCreditArgs = {
  amount: Scalars["Int"];
  email: Scalars["String"];
  orgUuid: Scalars["ID"];
};

export type RootMutationTypeArchivePlaybookArgs = {
  organisationUuid: Scalars["ID"];
  playbookUuid: Scalars["ID"];
};

export type RootMutationTypeAssignCardNumberTagArgs = {
  cardUuid: Scalars["ID"];
  numberTagUuid: Scalars["ID"];
};

export type RootMutationTypeAssignChatArgs = {
  accountUuid?: InputMaybe<Scalars["ID"]>;
  chatUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeAssignMessageNumberTagArgs = {
  messageUuid: Scalars["ID"];
  numberTagUuid: Scalars["ID"];
};

export type RootMutationTypeBlockContactArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeCancelActiveOnboardingApplicationArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeCancelPaddleScheduledPauseArgs = {
  subscriptionId: Scalars["String"];
};

export type RootMutationTypeCancelReminderArgs = {
  numberUuid: Scalars["ID"];
  reminderUuid: Scalars["ID"];
};

export type RootMutationTypeConvertAllThreadsToJourneysArgs = {
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeConvertThreadToJourneyArgs = {
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootMutationTypeCreateAccountArgs = {
  email: Scalars["String"];
  organisationUuid: Scalars["ID"];
  role?: InputMaybe<RoleType>;
};

export type RootMutationTypeCreateAccountsArgs = {
  accountInputs?: InputMaybe<Array<InputMaybe<AccountInput>>>;
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeCreateActionsCardArgs = {
  action: ActionsCardIo;
  actionType: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateAutomatorArgs = {
  config: AutomatorConfigIo;
  name: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateChatNoteArgs = {
  chatUuid: Scalars["ID"];
  content: Scalars["String"];
  mentions: Array<ChatNoteMentionIo>;
};

export type RootMutationTypeCreateCollectionArgs = {
  collection: CollectionIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateContactSchemaArgs = {
  contactSchema: ContactSchemaIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateContentArgs = {
  content: ContentIo;
  contentType: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateExportArgs = {
  numberUuid: Scalars["ID"];
  objectUuid?: InputMaybe<Scalars["ID"]>;
  type: Scalars["String"];
};

export type RootMutationTypeCreateIntegrationArgs = {
  integration: IntegrationIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateJourneyGlobalDataArgs = {
  data: JourneyDataIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateJourneyLocalDataArgs = {
  data: JourneyDataIo;
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeCreateManualPaddleSubscriptionArgs = {
  addressCountry: Scalars["String"];
  addressFirstLine: Scalars["String"];
  addressPostalCode: Scalars["String"];
  addressRegion: Scalars["String"];
  customerEmail: Scalars["String"];
  customerName: Scalars["String"];
  numberUuid: Scalars["ID"];
  subscriptionPlan: SubscriptionPlan;
};

export type RootMutationTypeCreateMessageTemplateArgs = {
  messageTemplate: MessageTemplateIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateNotebookArgs = {
  name: Scalars["String"];
  notebook: NotebookIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateNumberTagArgs = {
  color?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  value?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeCreateOrgAndSubmitSignUpFormArgs = {
  communicationOptIn: Scalars["Boolean"];
  firstName: Scalars["String"];
  impactArea: ImpactSectorType;
  lastName: Scalars["String"];
  organisationCountry: Scalars["String"];
  organisationName: Scalars["String"];
  organisationType: OrganisationEntityType;
  signupReferral?: InputMaybe<Scalars["String"]>;
  website: Scalars["String"];
  workEmail: Scalars["String"];
};

export type RootMutationTypeCreateSimulatorArgs = {
  notebook: NotebookIo;
  numberUuid: Scalars["ID"];
  stackContainerUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeCreateStackDataExportArgs = {
  numberUuid: Scalars["ID"];
  objectUuid: Scalars["ID"];
  type: Scalars["String"];
};

export type RootMutationTypeCreateTemplatedMessageArgs = {
  attachmentId?: InputMaybe<Scalars["ID"]>;
  chatUuid: Scalars["ID"];
  template: TemplatedMessageTemplateIo;
};

export type RootMutationTypeCreateThreadEdgeArgs = {
  graphUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  sourceNodeUuid: Scalars["ID"];
  targetNodeUuid: Scalars["ID"];
};

export type RootMutationTypeCreateThreadGraphArgs = {
  language?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  published?: InputMaybe<Scalars["Boolean"]>;
  title: Scalars["String"];
};

export type RootMutationTypeCreateThreadNodeArgs = {
  automatorUuid?: InputMaybe<Scalars["ID"]>;
  cardUuid?: InputMaybe<Scalars["ID"]>;
  graphUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeCreateWebhookArgs = {
  enabled: Scalars["Boolean"];
  numberUuid: Scalars["ID"];
  optInOnly: Scalars["Boolean"];
  primary: Scalars["Boolean"];
  subscriptions: Array<InputMaybe<Scalars["String"]>>;
  url: Scalars["String"];
};

export type RootMutationTypeDeleteAccountArgs = {
  accountUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteActionsCardArgs = {
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootMutationTypeDeleteAutomatorArgs = {
  automatorUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteBigqueryServiceAccountArgs = {
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteChatNoteArgs = {
  chatNoteUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteCollectionArgs = {
  collectionUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteContentArgs = {
  contentType: Scalars["String"];
  contentUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteIntegrationArgs = {
  integrationUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteJourneyGlobalDataArgs = {
  globalDataUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteJourneyLocalDataArgs = {
  localDataUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteMessageArgs = {
  messageUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteMessageTemplateArgs = {
  messageTemplateUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteNotebookArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteNumberTagArgs = {
  numberTagUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteNumberTokenArgs = {
  numberUuid: Scalars["ID"];
  signature: Scalars["ID"];
};

export type RootMutationTypeDeleteOrganisationTokenArgs = {
  organisationTokenUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeDeleteSimulatorArgs = {
  uuid: Scalars["ID"];
};

export type RootMutationTypeDeleteThreadArgs = {
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootMutationTypeDeleteWebhookArgs = {
  numberUuid: Scalars["ID"];
  webhookUuid: Scalars["ID"];
};

export type RootMutationTypeDuplicateJourneyArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeGetOrCreatePaddleCustomerArgs = {
  customerEmail: Scalars["String"];
  customerName: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeImportPlaybookArgs = {
  organisationUuid: Scalars["ID"];
  playbookUuid: Scalars["ID"];
};

export type RootMutationTypeImportThreadJsonArgs = {
  numberUuid: Scalars["ID"];
  threadJson?: InputMaybe<Scalars["Json"]>;
};

export type RootMutationTypeMarkMessagesAsHandledArgs = {
  handled: Scalars["Boolean"];
  messagesUuids: Array<InputMaybe<Scalars["ID"]>>;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeNameNotebookRevisionArgs = {
  name: Scalars["String"];
  notebookRevisionUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeNewEnterpriseCustomerEmailArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeOnboardEmbeddedSignupNumberArgs = {
  onboardingApplicationUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeOverrideLeaseArgs = {
  chatUuid: Scalars["ID"];
  eventMessage: Scalars["String"];
  messageUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypePatchContactProfileArgs = {
  chatUuid: Scalars["ID"];
  fields?: InputMaybe<Scalars["Json"]>;
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypePausePaddleSubscriptionArgs = {
  subscriptionId: Scalars["String"];
};

export type RootMutationTypePerformIntegrationHandshakeArgs = {
  integrationUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypePreviewTranslationArgs = {
  chatUuid: Scalars["ID"];
  content: Scalars["String"];
  originalLanguageCode: Scalars["String"];
  translatedLanguageCode: Scalars["String"];
};

export type RootMutationTypePublishLatestNotebookRevisionArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypePublishPlaybookArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description: Scalars["String"];
  evidence?: InputMaybe<Scalars["String"]>;
  headerPicture?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  license: Scalars["String"];
  organisationUuid: Scalars["ID"];
  playbookAttachments: Array<PlaybookAttachmentIo>;
  stackUuid?: InputMaybe<Scalars["ID"]>;
  threadUuid?: InputMaybe<Scalars["ID"]>;
  title: Scalars["String"];
  type: PlaybookType;
  version: Scalars["String"];
  versionReleaseNotes?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeReleaseLeaseArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeRemoveCardNumberTagArgs = {
  contentType: Scalars["String"];
  contentUuid: Scalars["ID"];
  numberTagUuid: Scalars["ID"];
};

export type RootMutationTypeRemoveMessageNumberTagArgs = {
  messageUuid: Scalars["ID"];
  numberTagUuid: Scalars["ID"];
};

export type RootMutationTypeRequestApiCredentialsArgs = {
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeRequestLeaseArgs = {
  chatUuid: Scalars["ID"];
  eventMessage: Scalars["String"];
  messageUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  ttl?: InputMaybe<Scalars["Int"]>;
};

export type RootMutationTypeRequestNumberTokenArgs = {
  expiresAt: Scalars["String"];
  name: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeRequestPhoneNumberArgs = {
  numberRequest: NumberRequest;
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeRequestVirtualNumberArgs = {
  code: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeResetUnreadCounterArgs = {
  chatUuid: Scalars["ID"];
};

export type RootMutationTypeRestartLeaseArgs = {
  chatUuid: Scalars["ID"];
  eventMessage: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeRestoreNotebookRevisionArgs = {
  journeyUuid: Scalars["ID"];
  notebookRevisionUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeResumePausedPaddleSubscriptionArgs = {
  subscriptionId: Scalars["String"];
};

export type RootMutationTypeScheduleReminderArgs = {
  accountUuid: Scalars["ID"];
  attachmentId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  query: Scalars["String"];
  scheduledAt?: InputMaybe<Scalars["DateTime"]>;
  segmentUuid?: InputMaybe<Scalars["ID"]>;
  templateParams: TemplatedMessageTemplateIo;
};

export type RootMutationTypeSearchArgs = {
  numberUuid: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  query: SearchQueryIo;
};

export type RootMutationTypeSearchMessagesArgs = {
  numberUuid: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  query: SearchQueryIo;
  sort?: InputMaybe<SortType>;
};

export type RootMutationTypeSendReactionToMessageArgs = {
  emoji: Scalars["String"];
  messageUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeSetOperatorAsAvailableArgs = {
  accountUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeSetOperatorAsUnavailableArgs = {
  accountUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  releaseChats: Scalars["Boolean"];
};

export type RootMutationTypeSetPrivacyEnabledFieldArgs = {
  organisationUuid: Scalars["ID"];
  privacyEnabled: Scalars["Boolean"];
};

export type RootMutationTypeSetStagingModeArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
  stagingEnabled: Scalars["Boolean"];
};

export type RootMutationTypeSetTranslationOptionArgs = {
  languageCode: Scalars["String"];
  operatorUuid: Scalars["ID"];
  option: Scalars["String"];
};

export type RootMutationTypeStartIntegrationActionArgs = {
  action: IntegrationActionIo;
  chatUuid: Scalars["ID"];
  integrationUuid: Scalars["ID"];
  messageUuid: Scalars["ID"];
  option?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeStartJourneyArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeStartOnboardingApplicationArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeStartOnboardingApplicationForAdditionalNumberArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootMutationTypeSubmitInteractiveMessageArgs = {
  chatUuid: Scalars["ID"];
  interactiveObject: InteractiveObjectInput;
};

export type RootMutationTypeSubmitSignUpOrganisationFormArgs = {
  communicationOptIn: Scalars["Boolean"];
  firstName: Scalars["String"];
  impactArea: ImpactSectorType;
  lastName: Scalars["String"];
  organisationCountry: Scalars["String"];
  organisationName: Scalars["String"];
  organisationType: OrganisationEntityType;
  organisationUuid: Scalars["ID"];
  signupReferral?: InputMaybe<Scalars["String"]>;
  website: Scalars["String"];
  workEmail: Scalars["String"];
};

export type RootMutationTypeSubmitSimulatorInputArgs = {
  notebook: NotebookIo;
  numberUuid: Scalars["ID"];
  userInput: Scalars["String"];
  uuid: Scalars["ID"];
  vars: Scalars["Json"];
};

export type RootMutationTypeSubmitTextMessageArgs = {
  attachmentId?: InputMaybe<Scalars["ID"]>;
  chatUuid: Scalars["ID"];
  content?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<CardType>;
  contentUuid?: InputMaybe<Scalars["ID"]>;
  faqUuid?: InputMaybe<Scalars["ID"]>;
  fromContextId?: InputMaybe<Scalars["ID"]>;
  isTranslationEnabled?: InputMaybe<Scalars["Boolean"]>;
  optInUuid?: InputMaybe<Scalars["ID"]>;
  originalLanguageCode?: InputMaybe<Scalars["String"]>;
  translatedContent?: InputMaybe<Scalars["String"]>;
  translatedLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeSubmitUseCaseDescriptionArgs = {
  organisationUuid: Scalars["ID"];
  useCaseDescription?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeSwitchCollectionModeToAutomaticArgs = {
  chargePastDueOnAutomatic?: InputMaybe<Scalars["Boolean"]>;
  subscriptionId: Scalars["String"];
  targetPrice?: InputMaybe<SwitchTargetPrice>;
};

export type RootMutationTypeToggleMentionIsClearedArgs = {
  isCleared: Scalars["Boolean"];
  mentionUuid: Scalars["ID"];
};

export type RootMutationTypeToggleNotebookArgs = {
  enabled: Scalars["Boolean"];
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeTogglePinChatArgs = {
  chatUuid: Scalars["ID"];
};

export type RootMutationTypeTranslateMessagesArgs = {
  accountLanguageCode: Scalars["String"];
  chatUuid: Scalars["ID"];
  contactLanguageCode: Scalars["String"];
  messageUuids: Array<InputMaybe<Scalars["ID"]>>;
};

export type RootMutationTypeUnblockContactArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateAccountArgs = {
  accountUpdateInput: AccountUpdateInput;
  accountUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateActionsCardArgs = {
  action: ActionsCardIo;
  actionType: Scalars["String"];
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootMutationTypeUpdateAutomatorArgs = {
  automatorUuid: Scalars["ID"];
  config: AutomatorConfigIo;
  enabled: Scalars["Boolean"];
  name: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateChatNoteArgs = {
  chatNoteUuid: Scalars["ID"];
  content: Scalars["String"];
  mentions?: InputMaybe<Array<ChatNoteMentionIo>>;
};

export type RootMutationTypeUpdateChatStateArgs = {
  assignToAccountUuid?: InputMaybe<Scalars["ID"]>;
  chatUuid: Scalars["ID"];
  state: ChatState;
};

export type RootMutationTypeUpdateCollectionArgs = {
  collection: CollectionIo;
  collectionUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateContactProfileArgs = {
  chatUuid: Scalars["ID"];
  fields?: InputMaybe<Scalars["Json"]>;
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateContentArgs = {
  content: ContentIo;
  contentType: Scalars["String"];
  contentUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateIntegrationArgs = {
  integration: IntegrationIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateJourneyGlobalDataArgs = {
  data: JourneyDataIo;
  globalDataUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateJourneyLocalDataArgs = {
  data: JourneyDataIo;
  localDataUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateJourneyTranslationsArgs = {
  journeyUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  translations: JourneyTableIo;
};

export type RootMutationTypeUpdateNotebookArgs = {
  name: Scalars["String"];
  notebook: NotebookIo;
  numberUuid: Scalars["ID"];
  publish?: InputMaybe<Scalars["Boolean"]>;
  stackContainerUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateNumberArgs = {
  number: NumberIo;
  numberUuid: Scalars["ID"];
};

export type RootMutationTypeUpdateNumberTagArgs = {
  color?: InputMaybe<Scalars["String"]>;
  numberTagUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  value?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateOrganisationAccountArgs = {
  accountUuid: Scalars["ID"];
  isAdmin: Scalars["Boolean"];
  organisationUuid: Scalars["ID"];
  role?: InputMaybe<RoleType>;
};

export type RootMutationTypeUpdatePlaybookArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description: Scalars["String"];
  evidence?: InputMaybe<Scalars["String"]>;
  headerPicture?: InputMaybe<Scalars["String"]>;
  license: Scalars["String"];
  organisationUuid: Scalars["ID"];
  playbookAttachments: Array<PlaybookAttachmentIo>;
  playbookUuid: Scalars["ID"];
  stackUuid?: InputMaybe<Scalars["ID"]>;
  threadUuid?: InputMaybe<Scalars["ID"]>;
  title: Scalars["String"];
  version: Scalars["String"];
  versionReleaseNotes?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateThreadArgs = {
  cardAssociations: Array<InputMaybe<ThreadCardAssociationIo>>;
  language?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  published?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
  triggers: Array<InputMaybe<AutomatorTriggerIo>>;
  uuid: Scalars["ID"];
};

export type RootMutationTypeUpdateThreadEdgeArgs = {
  graphUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  sourceNodeUuid?: InputMaybe<Scalars["ID"]>;
  targetNodeUuid?: InputMaybe<Scalars["ID"]>;
  uuid: Scalars["ID"];
};

export type RootMutationTypeUpdateThreadGraphArgs = {
  numberUuid: Scalars["ID"];
  published?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
  uuid: Scalars["ID"];
};

export type RootMutationTypeUpdateThreadNodeArgs = {
  automatorUuid?: InputMaybe<Scalars["ID"]>;
  cardUuid?: InputMaybe<Scalars["ID"]>;
  graphUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootMutationTypeUpdateWebhookArgs = {
  enabled: Scalars["Boolean"];
  numberUuid: Scalars["ID"];
  optInOnly: Scalars["Boolean"];
  primary: Scalars["Boolean"];
  subscriptions: Array<InputMaybe<Scalars["String"]>>;
  url: Scalars["String"];
  webhookUuid: Scalars["ID"];
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  /** List all (OPEN by default) non-blocked, non-culled chats for a given number_uuid */
  allChats?: Maybe<ChatSearchResults>;
  availableCountriesForSubscriptionAddress: Array<Maybe<Scalars["String"]>>;
  /** List available phone numbers for sign up */
  availablePoolNumbers: Array<PoolNumber>;
  averageMessagesStats?: Maybe<AverageMessagesStats>;
  /** Get billing country spendings */
  billingCountrySpends: MonthlyCountrySpends;
  /** Get billing daily spendings */
  billingDailySpends: MonthlyDailySpends;
  /** Get billing number spendings */
  billingNumberSpends: MonthlyNumberSpends;
  /** Get billing details for account */
  billingOverview: BillingOverview;
  /** Paddle Billing Prices public configuration needed to render the subscription plan selection and checkout pages */
  billingPricesConfiguration?: Maybe<PricesConfiguration>;
  /** List all blocked (OPEN by default) non-culled chats for a given number_uuid */
  blockedChats?: Maybe<ChatSearchResults>;
  /** List all playbook categories */
  categories?: Maybe<Array<Maybe<Category>>>;
  /** List details for a single chat */
  chat?: Maybe<Chat>;
  /** List all possible integration details for a single chat */
  chatIntegrationResults?: Maybe<Array<Maybe<ChatIntegrationResult>>>;
  /** List all chat notes (Messages with 'TURN_CHAT_NOTE' source_type) with tagged Mentions on a given number */
  chatNotesWithMentionsForTaggedAccount?: Maybe<ChatNoteSearchResults>;
  /** List all chats for a given number */
  chats?: Maybe<Array<Maybe<Chat>>>;
  /** List all chats for assigned to a given account */
  chatsAssignedToAccount?: Maybe<ChatSearchResults>;
  /** Get details for a collection */
  collection?: Maybe<Collection>;
  /** List the details for a single contact */
  contact?: Maybe<Contact>;
  conversationStats?: Maybe<InsightsConversationStats>;
  /** Get the credit balance for the organisation */
  creditBalance?: Maybe<Scalars["Decimal"]>;
  /** List all exports */
  exports?: Maybe<Array<Maybe<Export>>>;
  /** Filter journey insights */
  filterJourneyInsights: JourneyInsights;
  generalStats?: Maybe<GeneralStats>;
  /**
   * Generate the code for a code cell.
   * @deprecated Please start using `generateCodeForCards` instead
   */
  generateCode: NotebookCellCode;
  /** Generate the code for a list of code cell cards. */
  generateCodeForCards: NotebookCellCode;
  /** Generate expression code */
  generateCodeForExpression: Scalars["String"];
  /** Generate the code for a list Triggers. */
  generateCodeForTriggers: NotebookCellCode;
  /** Get a single card by UUID */
  getCard: Card;
  getConfigForInboxAutoTranslation?: Maybe<GetConfigForInboxAutoTranslationResponse>;
  getContactSchema?: Maybe<ContactSchemaSerialized>;
  /** Get enabled features */
  getEnabledFeatures: Array<Maybe<Scalars["String"]>>;
  /** Get journey insights */
  getJourneyInsights: JourneyInsights;
  /** Get journey translation */
  getJourneyTranslations?: Maybe<JourneyTable>;
  /** Get a notebook revision by UUID */
  getNotebookRevision: NotebookRevision;
  /** Get an operator's availability */
  getOperatorAvailability?: Maybe<OperatorAvailability>;
  getOrCreateContactProfile?: Maybe<ContactProfileSerialized>;
  /** Get a single reminder by UUID */
  getReminder?: Maybe<Reminder>;
  /** Get a stack container for a number */
  getStackContainer: StackContainer;
  /** Get the teammates for an account on a given number */
  getTeammatesForAccountOnNumber?: Maybe<Array<Maybe<TeammateAccountSummary>>>;
  /** Get telemetry for all webhooks */
  getWebhookTelemetry?: Maybe<Array<Maybe<WebhookTelemetry>>>;
  /** Returns the highest number used to save untitled journeys for a number */
  highestUntitledJourneyNumber: Scalars["Int"];
  /** List all playbooks imported by an organisation */
  importedPlaybooks?: Maybe<ImportedPlaybooks>;
  /** Get the Inbox overview for a given number and account */
  inboxOverview?: Maybe<InboxOverview>;
  /** List integration action details */
  integrationActions?: Maybe<Array<Maybe<IntegrationAction>>>;
  /** List integration context object details. */
  integrationContextObjectDetails?: Maybe<Array<Maybe<IntegrationContextObjectDetails>>>;
  /** List all integration suggestions for a chat */
  integrationSuggestions?: Maybe<Array<Maybe<CustomIntegrationSuggestion>>>;
  listAssistants?: Maybe<Array<Maybe<Assistant>>>;
  /** List global journey data for a number */
  listJourneyGlobalData: Array<JourneyData>;
  /** List local journey data */
  listJourneyLocalData: Array<JourneyData>;
  /** Get a list of sample stacks */
  listSampleNotebooks: Array<SampleNotebook>;
  /** List the simulators for the currently logged in account */
  listSimulators: Array<Simulator>;
  /**
   * List stack containers
   * @deprecated Very slow, use listStackContainersPreviews instead
   */
  listStackContainers: Array<StackContainer>;
  /** List stack containers previews (which don't load the notebook and FLOIP data) and their count */
  listStackContainersPreviews: StackContainerPreviewsResults;
  /** Fetch all languages with their names and codes */
  listTranslationLanguages?: Maybe<Array<Maybe<Language>>>;
  /** Search on message objects */
  messageSearchResults?: Maybe<MessageSearchResults>;
  /** List all messages for a given chat */
  messages?: Maybe<ListMessagesResults>;
  messagesStats?: Maybe<MessagesStats>;
  mostActiveCountriesStats?: Maybe<MostActiveCountriesStats>;
  mostUsedResponsesStats?: Maybe<MostUsedResponsesStats>;
  /** List details for a single number */
  number?: Maybe<Number>;
  /** List all numbers available for an account */
  numbers?: Maybe<Array<Maybe<Number>>>;
  /** Retrieve an onboarding application */
  onboardingApplication?: Maybe<OnboardingApplication>;
  /** Onboarding public configuration */
  onboardingConfiguration?: Maybe<OnboardingConfiguration>;
  /** Get the number of open chats assigned to an operator */
  openChatsAssignedCount?: Maybe<ChatsAssignedCount>;
  /** List details for a single organisation */
  organisation?: Maybe<Organisation>;
  /** List all playbooks published by an organisation */
  organisationPlaybooks?: Maybe<Playbooks>;
  /** List all organisations available for an account */
  organisations?: Maybe<Array<Maybe<Organisation>>>;
  /** Paddle version needed to render either Paddle Classic or Paddle Billing data */
  paddleVersionConfiguration?: Maybe<PaddleVersion>;
  /** Parse expression code */
  parseExpression: Expression;
  /** Parse a notebook without saving it to disk */
  parseNotebook?: Maybe<NotebookType>;
  performanceMetrics?: Maybe<PerformanceMetrics>;
  /** Get a single playbook */
  playbook?: Maybe<Playbook>;
  /** Get earlier version history */
  playbookEarlierVersionHistory?: Maybe<Array<Maybe<PlaybookEarlierVersion>>>;
  /** List all playbooks */
  playbooks?: Maybe<Playbooks>;
  /** Get a summary of the reminders that failed to send */
  reminderErrorSummary: Array<ReminderError>;
  /** List all reminders */
  reminders?: Maybe<Reminders>;
  responseTypeStats?: Maybe<ResponseTypeStats>;
  /** Get the approximate number of contacts that satisfy the Triggers of the provided stack. */
  stackApproximateAudienceSize: Scalars["Int"];
  /** @deprecated Switch manual->automatic no longer supported, to be removed */
  switchRequestAlreadySubmitted?: Maybe<Scalars["Boolean"]>;
  /** Get a single thread */
  thread?: Maybe<Thread>;
  /** List all thread graphs */
  threadGraphs?: Maybe<Array<Maybe<ThreadGraph>>>;
  /** Get JSON for a single thread */
  threadJson?: Maybe<ThreadJson>;
  /** List all threads */
  threads?: Maybe<Array<Maybe<Thread>>>;
  /** List, order and search all threads */
  threadsAlpha: Array<Thread>;
  threadsCount: Scalars["Int"];
  /** List all of the language used for all Threads for this number */
  threadsLanguages: Array<Scalars["String"]>;
  titleStats?: Maybe<TitleStats>;
  /** List all unassigned (OPEN by default) non-blocked, non-culled chats without leases for a given number_uuid */
  unassignedChats?: Maybe<ChatSearchResults>;
  usersStats?: Maybe<UsersStats>;
  /** Checks whether WABA is available or taken by an existing organisation */
  wabaIsAvailableForEmbeddedSignup?: Maybe<WabaAvailabilityStatus>;
  /**
   * List all chats for a given number with a bot.
   *
   * Internally this means that the chat has a lease of a different type
   * than account, so the chat was started by something like a stack or a thread.
   */
  withBot?: Maybe<ChatSearchResults>;
};

export type RootQueryTypeAllChatsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeAverageMessagesStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeBillingCountrySpendsArgs = {
  datetime?: InputMaybe<Scalars["DateTime"]>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeBillingDailySpendsArgs = {
  datetime?: InputMaybe<Scalars["DateTime"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeBillingNumberSpendsArgs = {
  datetime?: InputMaybe<Scalars["DateTime"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeBillingOverviewArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeBillingPricesConfigurationArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeBlockedChatsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeChatArgs = {
  chatUuid: Scalars["ID"];
};

export type RootQueryTypeChatIntegrationResultsArgs = {
  chatUuid: Scalars["ID"];
  timeout?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryTypeChatNotesWithMentionsForTaggedAccountArgs = {
  isCleared?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeChatsArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeChatsAssignedToAccountArgs = {
  assignedToUuid: Scalars["ID"];
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeCollectionArgs = {
  collectionUuid: Scalars["ID"];
};

export type RootQueryTypeContactArgs = {
  contactUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeConversationStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeCreditBalanceArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeExportsArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeFilterJourneyInsightsArgs = {
  from: Scalars["Date"];
  journeyUuid: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  until: Scalars["Date"];
};

export type RootQueryTypeGeneralStatsArgs = {
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeGenerateCodeArgs = {
  card: StackCardIo;
  cardCodeGenerator: Scalars["String"];
};

export type RootQueryTypeGenerateCodeForCardsArgs = {
  cards: Array<StackCardGeneratorIo>;
};

export type RootQueryTypeGenerateCodeForExpressionArgs = {
  items: Array<ExpressionBasicIo>;
  triggerType?: InputMaybe<TriggerType>;
};

export type RootQueryTypeGenerateCodeForTriggersArgs = {
  numberUuid: Scalars["ID"];
  triggers: Array<TriggerIo>;
};

export type RootQueryTypeGetCardArgs = {
  cardUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetConfigForInboxAutoTranslationArgs = {
  accountUuid: Scalars["ID"];
  chatUuid: Scalars["ID"];
};

export type RootQueryTypeGetContactSchemaArgs = {
  id: Scalars["String"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetEnabledFeaturesArgs = {
  numberUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeGetJourneyInsightsArgs = {
  journeyUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetJourneyTranslationsArgs = {
  journeyUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetNotebookRevisionArgs = {
  notebookRevisionUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetOperatorAvailabilityArgs = {
  accountUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetOrCreateContactProfileArgs = {
  chatUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
  schema?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeGetReminderArgs = {
  numberUuid?: InputMaybe<Scalars["ID"]>;
  reminderUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeGetStackContainerArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootQueryTypeGetTeammatesForAccountOnNumberArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeGetWebhookTelemetryArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeHighestUntitledJourneyNumberArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeImportedPlaybooksArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
  organisationUuid: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryTypeInboxOverviewArgs = {
  numberUuid: Scalars["ID"];
  onlyYourInbox?: InputMaybe<Scalars["Boolean"]>;
};

export type RootQueryTypeIntegrationActionsArgs = {
  chatUuid: Scalars["ID"];
};

export type RootQueryTypeIntegrationContextObjectDetailsArgs = {
  chatUuid: Scalars["ID"];
};

export type RootQueryTypeIntegrationSuggestionsArgs = {
  chatUuid: Scalars["ID"];
};

export type RootQueryTypeListAssistantsArgs = {
  numberUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeListJourneyGlobalDataArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeListJourneyLocalDataArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootQueryTypeListStackContainersArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeListStackContainersPreviewsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeMessageSearchResultsArgs = {
  numberUuid: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  query: SearchQueryIo;
  sort?: InputMaybe<SortType>;
};

export type RootQueryTypeMessagesArgs = {
  beforeUuid?: InputMaybe<Scalars["ID"]>;
  chatUuid: Scalars["ID"];
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeMessagesStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid: Scalars["ID"];
  organisationUuid?: InputMaybe<Scalars["ID"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeMostActiveCountriesStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeMostUsedResponsesStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeNumberArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeNumbersArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeOnboardingApplicationArgs = {
  onboardingApplicationUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeOpenChatsAssignedCountArgs = {
  accountUuid: Scalars["ID"];
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeOrganisationArgs = {
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeOrganisationPlaybooksArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
  organisationUuid: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryTypeParseExpressionArgs = {
  expression: Scalars["String"];
};

export type RootQueryTypeParseNotebookArgs = {
  notebook: NotebookIo;
  numberUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypePerformanceMetricsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypePlaybookArgs = {
  uuid: Scalars["ID"];
};

export type RootQueryTypePlaybookEarlierVersionHistoryArgs = {
  count?: InputMaybe<Scalars["Int"]>;
  playbookUuid: Scalars["ID"];
};

export type RootQueryTypePlaybooksArgs = {
  orderBy?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryTypeReminderErrorSummaryArgs = {
  numberUuid?: InputMaybe<Scalars["ID"]>;
  reminderUuid?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeRemindersArgs = {
  numberUuid?: InputMaybe<Scalars["ID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryTypeResponseTypeStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeStackApproximateAudienceSizeArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootQueryTypeSwitchRequestAlreadySubmittedArgs = {
  subscriptionId: Scalars["String"];
};

export type RootQueryTypeThreadArgs = {
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootQueryTypeThreadGraphsArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeThreadJsonArgs = {
  numberUuid: Scalars["ID"];
  uuid: Scalars["ID"];
};

export type RootQueryTypeThreadsArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeThreadsAlphaArgs = {
  language?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeThreadsCountArgs = {
  language?: InputMaybe<Scalars["String"]>;
  numberUuid: Scalars["ID"];
  search?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeThreadsLanguagesArgs = {
  numberUuid: Scalars["ID"];
};

export type RootQueryTypeTitleStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeUnassignedChatsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeUsersStatsArgs = {
  days?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  numberUuid?: InputMaybe<Scalars["ID"]>;
  organisationUuid: Scalars["ID"];
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeWabaIsAvailableForEmbeddedSignupArgs = {
  embeddedSignupUserCode: Scalars["String"];
  onboardingApplicationUuid: Scalars["ID"];
  organisationUuid: Scalars["ID"];
};

export type RootQueryTypeWithBotArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  numberUuid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type RootSubscriptionType = {
  __typename?: "RootSubscriptionType";
  /** Get an update when a chat changes */
  onChatChanged?: Maybe<Chat>;
  /** Get an update when the details for an integration in a chat update */
  onChatIntegrationResultChanged?: Maybe<ChatIntegrationResult>;
  /**
   * Get an update when a chat in a collection changes
   * @deprecated This will be removed in the future
   */
  onCollectionChatChanged?: Maybe<Chat>;
  /**
   * Get an update when a chat no longer matches the collection search criteria
   * @deprecated This will be removed in the future
   */
  onCollectionChatRemoved?: Maybe<Chat>;
  /** Get an update when a 3rd party action update happens. */
  onIntegrationActionUpdate?: Maybe<IntegrationAction>;
  /** Get an update when a 3rd party context update happens. */
  onIntegrationContextUpdate?: Maybe<IntegrationContextObjectDetails>;
  /** Get an update when a 3rd party suggestion update happens. */
  onIntegrationSuggestions?: Maybe<Array<Maybe<CustomIntegrationSuggestion>>>;
  /** Get an update when a message changes */
  onMessageChanged?: Maybe<Message>;
  /**
   * Get an update when a number's settings change.
   * Can be used to be notified when the number's billing subscription plan changes
   * or when routing is enabled/disabled.
   */
  onNumberChanged?: Maybe<Number>;
  /** Get an update when any of the number-wide group summary stats change */
  onNumberInboxSummaryChanged?: Maybe<InboxGroupingSummary>;
  /**
   * Receive voice call recordings for a Suonum phone number.
   * Used to receive the WhatsApp OTP verification code for a phone number
   * that is being onboarded via Embedded Signup.
   */
  onPhoneEvent?: Maybe<PhoneEvent>;
  /** Be notified when a stack container has been saved */
  onStackContainerSaved: StackContainer;
  /** Get an update when a stream state changes */
  onStreamStateChanged?: Maybe<Number>;
  /** Get an update when the ChatGroupSummary for a teammate on the number changes */
  onTeammatesInboxSummaryChanged?: Maybe<InboxGroupingSummary>;
  /** Get an update when the Your Inbox summary changes */
  onYourInboxSummaryChanged?: Maybe<InboxGroupingSummary>;
};

export type RootSubscriptionTypeOnChatChangedArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnChatIntegrationResultChangedArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnCollectionChatChangedArgs = {
  collectionUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnCollectionChatRemovedArgs = {
  collectionUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnIntegrationActionUpdateArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnIntegrationContextUpdateArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnIntegrationSuggestionsArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnMessageChangedArgs = {
  chatUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnNumberChangedArgs = {
  numberUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnNumberInboxSummaryChangedArgs = {
  numberUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnPhoneEventArgs = {
  organisationUuid: Scalars["ID"];
  phoneNumber: Scalars["String"];
};

export type RootSubscriptionTypeOnStackContainerSavedArgs = {
  numberUuid: Scalars["ID"];
  stackContainerUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnStreamStateChangedArgs = {
  numberUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnTeammatesInboxSummaryChangedArgs = {
  numberUuid: Scalars["ID"];
};

export type RootSubscriptionTypeOnYourInboxSummaryChangedArgs = {
  numberUuid: Scalars["ID"];
};

export type RunStackParameters = {
  __typename?: "RunStackParameters";
  flowId?: Maybe<Scalars["String"]>;
};

export type SampleNotebook = {
  __typename?: "SampleNotebook";
  name?: Maybe<Scalars["String"]>;
  notebook?: Maybe<NotebookType>;
};

export type ScheduleStackParameters = {
  __typename?: "ScheduleStackParameters";
  flowId?: Maybe<Scalars["String"]>;
  scheduleIn?: Maybe<Scalars["String"]>;
};

export enum ScheduledChange {
  CANCEL = "CANCEL",
  PAUSE = "PAUSE",
  RESUME = "RESUME",
}

/** The parameters for a saved search */
export type SearchQuery = {
  __typename?: "SearchQuery";
  /** The list of assignedTo Account UUIDs to limit results to */
  assignees?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** The uuid of chat to limit search */
  chatUuid?: Maybe<Scalars["ID"]>;
  /** The date to search from in ISO8601 format */
  dateFrom?: Maybe<Scalars["String"]>;
  /** The date to search up until in ISO8601 format */
  dateTo?: Maybe<Scalars["String"]>;
  /** The direction of the message to limit results to. If not set both inbound and outbound messages will be included results. */
  direction?: Maybe<DirectionType>;
  /** The exact messages query to search on */
  messagesQuery?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  /** The list of NumberTag UUIDs to limit results to */
  tags?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

/** The parameters for a saved search */
export type SearchQueryIo = {
  /** The list of assignedTo Account UUIDs to limit results to */
  assignees: Array<InputMaybe<Scalars["ID"]>>;
  /** The state of the chat */
  chatState?: InputMaybe<ChatState>;
  /** The uuid of chat to limit search */
  chatUuid?: InputMaybe<Scalars["ID"]>;
  /** The date to search from in ISO8601 format */
  dateFrom?: InputMaybe<Scalars["String"]>;
  /** The date to search up until in ISO8601 format */
  dateTo?: InputMaybe<Scalars["String"]>;
  /** The direction of the message to limit results to. If not provided both inbound and outbound messages will be included results. */
  direction?: InputMaybe<DirectionType>;
  /** Whether or not the messages should be handled or not */
  isHandled?: InputMaybe<Scalars["Boolean"]>;
  /** The full text query to search messages for */
  messagesQuery: Scalars["String"];
  query: Scalars["String"];
  /** The list of NumberTag UUIDs to limit results to */
  tags: Array<InputMaybe<Scalars["ID"]>>;
};

/**
 * The type of signup. Records if the number was signed up by the client
 * via Embedded Signup or if it was signed up manually by us (the BSP).
 */
export enum SignupType {
  BSP_SIGNUP = "BSP_SIGNUP",
  CUSTOM = "CUSTOM",
  EMBEDDED_SIGNUP = "EMBEDDED_SIGNUP",
}

/** The state of a simulator for someone using Stacks */
export type Simulator = {
  __typename?: "Simulator";
  context?: Maybe<SimulatorContext>;
  currentPosition?: Maybe<SimulatorPosition>;
  history?: Maybe<Array<Maybe<SimulatorInputOutputs>>>;
  lastUserInput?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  stacks: Array<Stack>;
  uuid: Scalars["ID"];
};

/** The context for a simulator session */
export type SimulatorContext = {
  __typename?: "SimulatorContext";
  finished: Scalars["Boolean"];
  language: Scalars["String"];
  log: Array<Scalars["String"]>;
  mode: SimulatorModeType;
  vars: Scalars["Json"];
  waitingForUserInput: Scalars["Boolean"];
};

/** Inputs paired with generated simulator state outputs */
export type SimulatorInputOutputs = {
  __typename?: "SimulatorInputOutputs";
  input?: Maybe<Scalars["String"]>;
  outputs: Array<SimulatorOutputState>;
};

export enum SimulatorModeType {
  RICH_MESSAGING = "RICH_MESSAGING",
}

export enum SimulatorOutputContentType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

/** And output step generated by a simulator */
export type SimulatorOutputMessageTypeContent = {
  __typename?: "SimulatorOutputMessageTypeContent";
  contentType: SimulatorOutputContentType;
  /** The value to be submitted when pressed (in the case of a button) */
  eventValue?: Maybe<Scalars["String"]>;
  mimeType: Scalars["String"];
  /** The raw expression that needs to be evaluated against the current context */
  rawValue?: Maybe<Scalars["String"]>;
  /** The result of the raw_expression when evaluated against the current context */
  value?: Maybe<Scalars["String"]>;
};

/** The output state generated by a simulator */
export type SimulatorOutputState = {
  __typename?: "SimulatorOutputState";
  content: Array<SimulatorOutputStep>;
  kind?: Maybe<OutputStateKind>;
};

/** And output step generated by a simulator */
export type SimulatorOutputStep = {
  __typename?: "SimulatorOutputStep";
  messageType: MessageTypeType;
  messageTypeContent: Array<SimulatorOutputMessageTypeContent>;
};

export type SimulatorPosition = {
  __typename?: "SimulatorPosition";
  card: StackCard;
  cardItem: StackCardItem;
};

/** The response of the simulator to user input events */
export type SimulatorResponse = {
  __typename?: "SimulatorResponse";
  input?: Maybe<Scalars["String"]>;
  outputs: Array<SimulatorOutputState>;
  simulator: Simulator;
  state: Scalars["String"];
};

/** Types of sorting values */
export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
}

/** All possible source types for a message */
export enum SourceTypeType {
  CHANNEL_EVENT = "CHANNEL_EVENT",
  CHANNEL_MESSAGE = "CHANNEL_MESSAGE",
  EXTERNAL_EVENT = "EXTERNAL_EVENT",
  EXTERNAL_MESSAGE = "EXTERNAL_MESSAGE",
  TURN_CHAT_NOTE = "TURN_CHAT_NOTE",
  TURN_EVENT = "TURN_EVENT",
}

export type Stack = {
  __typename?: "Stack";
  cards: Array<StackCard>;
  displayName?: Maybe<Scalars["String"]>;
  entryPoint?: Maybe<Scalars["String"]>;
  issues: Array<StackIssue>;
  languages?: Maybe<Array<Maybe<StackLanguage>>>;
  meta: Array<KeyValuePair>;
  name?: Maybe<Scalars["String"]>;
  opts: Array<KeyValuePair>;
  translations?: Maybe<Array<Maybe<Translation>>>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type StackCard = {
  __typename?: "StackCard";
  condition?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  items: Array<StackCardItem>;
  meta: Array<KeyValuePair>;
  name: Scalars["String"];
  opts: Array<KeyValuePair>;
  plan: Array<StackCardPlan>;
  uuid: Scalars["ID"];
};

export type StackCardGeneratorIo = {
  card: StackCardIo;
  cardCodeGenerator: Scalars["String"];
};

export type StackCardIo = {
  condition?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  items: Array<StackCardItemIo>;
  name: Scalars["String"];
  opts: Array<KeyValuePairIo>;
};

export type StackCardItem = {
  meta: Array<KeyValuePair>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiAddFunction = StackCardItem & {
  __typename?: "StackCardItemAiAddFunction";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiAddFunctionPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiAddFunctionIo = {
  connection: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  parameters: Scalars["String"];
};

export type StackCardItemAiAddFunctionPayload = {
  __typename?: "StackCardItemAiAddFunctionPayload";
  connection: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  parameters: Scalars["String"];
};

export type StackCardItemAiAddMessage = StackCardItem & {
  __typename?: "StackCardItemAiAddMessage";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiAddMessagePayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiAddMessageIo = {
  connection: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
};

export type StackCardItemAiAddMessagePayload = {
  __typename?: "StackCardItemAiAddMessagePayload";
  connection: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
};

export type StackCardItemAiAddMessages = StackCardItem & {
  __typename?: "StackCardItemAiAddMessages";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiAddMessagesPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiAddMessagesIo = {
  connection: Scalars["String"];
  messages: Array<Scalars["String"]>;
  role: Scalars["String"];
};

export type StackCardItemAiAddMessagesPayload = {
  __typename?: "StackCardItemAiAddMessagesPayload";
  connection: Scalars["String"];
  messages: Array<Scalars["String"]>;
  role: Scalars["String"];
};

export type StackCardItemAiAssistant = StackCardItem & {
  __typename?: "StackCardItemAiAssistant";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiAssistantPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiAssistantIo = {
  uuid: Scalars["ID"];
};

export type StackCardItemAiAssistantPayload = {
  __typename?: "StackCardItemAiAssistantPayload";
  uuid: Scalars["ID"];
};

export type StackCardItemAiChatCompletion = StackCardItem & {
  __typename?: "StackCardItemAiChatCompletion";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiChatCompletionPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiChatCompletionIo = {
  connection: Scalars["String"];
  jsonSchema?: InputMaybe<Scalars["Json"]>;
  responseFormat?: InputMaybe<AiResponseFormat>;
};

export type StackCardItemAiChatCompletionPayload = {
  __typename?: "StackCardItemAiChatCompletionPayload";
  connection: Scalars["String"];
  jsonSchema?: Maybe<Scalars["Json"]>;
  responseFormat?: Maybe<AiResponseFormat>;
};

export type StackCardItemAiConnect = StackCardItem & {
  __typename?: "StackCardItemAiConnect";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiConnectPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiConnectIo = {
  model: Scalars["String"];
  token: Scalars["String"];
  vendor: Scalars["String"];
};

export type StackCardItemAiConnectPayload = {
  __typename?: "StackCardItemAiConnectPayload";
  model: Scalars["String"];
  token: Scalars["String"];
  vendor: Scalars["String"];
};

export type StackCardItemAiTextClassification = StackCardItem & {
  __typename?: "StackCardItemAiTextClassification";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemAiTextClassificationPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAiTextClassificationIo = {
  connection: Scalars["String"];
  options: Array<Scalars["String"]>;
  systemPrompt: Scalars["String"];
  userInput: Scalars["String"];
};

export type StackCardItemAiTextClassificationPayload = {
  __typename?: "StackCardItemAiTextClassificationPayload";
  connection: Scalars["String"];
  options: Array<Scalars["String"]>;
  systemPrompt: Scalars["String"];
  userInput: Scalars["String"];
};

export type StackCardItemAsk = StackCardItem & {
  __typename?: "StackCardItemAsk";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemAudio = StackCardItem & {
  __typename?: "StackCardItemAudio";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemButton = StackCardItem & {
  __typename?: "StackCardItemButton";
  items: Array<StackCardItem>;
  meta: Array<KeyValuePair>;
  payload: Array<CardReference>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemButtonBlockIo = {
  items: Array<StackCardItemIo>;
  payload: Array<CardReferenceIo>;
};

export type StackCardItemDocument = StackCardItem & {
  __typename?: "StackCardItemDocument";
  filename?: Maybe<Scalars["String"]>;
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemDocumentIo = {
  filename?: InputMaybe<Scalars["String"]>;
  payload?: InputMaybe<Scalars["String"]>;
};

export type StackCardItemDynamicButton = StackCardItem & {
  __typename?: "StackCardItemDynamicButton";
  items: Array<StackCardItem>;
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemDynamicButtonReference>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemDynamicButtonReference = {
  __typename?: "StackCardItemDynamicButtonReference";
  cardReference: CardReference;
  expression?: Maybe<Scalars["String"]>;
};

export type StackCardItemDynamicList = StackCardItem & {
  __typename?: "StackCardItemDynamicList";
  items: Array<StackCardItem>;
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemDynamicListButtonReference>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemDynamicListButtonReference = {
  __typename?: "StackCardItemDynamicListButtonReference";
  callToAction: Scalars["String"];
  cardReference: CardReference;
  expression: Scalars["String"];
};

export type StackCardItemExpression = StackCardItem & {
  __typename?: "StackCardItemExpression";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemFooter = StackCardItem & {
  __typename?: "StackCardItemFooter";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemHeader = StackCardItem & {
  __typename?: "StackCardItemHeader";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemImage = StackCardItem & {
  __typename?: "StackCardItemImage";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemIo = {
  aiAddFunction?: InputMaybe<StackCardItemAiAddFunctionIo>;
  aiAddMessage?: InputMaybe<StackCardItemAiAddMessageIo>;
  aiAddMessages?: InputMaybe<StackCardItemAiAddMessagesIo>;
  aiAssistant?: InputMaybe<StackCardItemAiAssistantIo>;
  aiChatCompletion?: InputMaybe<StackCardItemAiChatCompletionIo>;
  aiConnect?: InputMaybe<StackCardItemAiConnectIo>;
  aiTextClassification?: InputMaybe<StackCardItemAiTextClassificationIo>;
  ask?: InputMaybe<StackCardItemTextIo>;
  audio?: InputMaybe<StackCardItemTextIo>;
  buttonBlock?: InputMaybe<StackCardItemButtonBlockIo>;
  document?: InputMaybe<StackCardItemDocumentIo>;
  expression?: InputMaybe<StackCardItemTextIo>;
  footer?: InputMaybe<StackCardItemTextIo>;
  header?: InputMaybe<StackCardItemTextIo>;
  image?: InputMaybe<StackCardItemTextIo>;
  lelapaConnect?: InputMaybe<StackCardItemLelapaConnectIo>;
  lelapaEntityRecognition?: InputMaybe<StackCardItemLelapaEntityRecognitionIo>;
  lelapaIntentClassification?: InputMaybe<StackCardItemLelapaIntentClassificationIo>;
  lelapaSentimentAnalysis?: InputMaybe<StackCardItemLelapaSentimentAnalysisIo>;
  lelapaTranslate?: InputMaybe<StackCardItemLelapaTranslateIo>;
  listBlock?: InputMaybe<StackCardItemListBlockIo>;
  openaiAddFunction?: InputMaybe<StackCardItemOpenaiAddFunctionIo>;
  openaiAddImage?: InputMaybe<StackCardItemOpenaiAddImageIo>;
  openaiAddMessage?: InputMaybe<StackCardItemOpenaiAddMessageIo>;
  openaiAddMessages?: InputMaybe<StackCardItemOpenaiAddMessagesIo>;
  openaiChatCompletion?: InputMaybe<StackCardItemOpenaiChatCompletionIo>;
  openaiConnect?: InputMaybe<StackCardItemOpenaiConnectIo>;
  openaiCreateSpeech?: InputMaybe<StackCardItemOpenaiCreateSpeechIo>;
  openaiTranscribe?: InputMaybe<StackCardItemOpenaiTranscribeIo>;
  runStack?: InputMaybe<StackCardItemTextIo>;
  sendContent?: InputMaybe<StackCardItemSendContentIo>;
  setChatProperty?: InputMaybe<StackCardItemSetChatPropertyIo>;
  setMessageProperty?: InputMaybe<StackCardItemSetMessagePropertyIo>;
  text?: InputMaybe<StackCardItemTextIo>;
  then?: InputMaybe<StackCardItemThenIo>;
  type: StackCardPlanStep;
  updateContact?: InputMaybe<StackCardItemUpdateContactIo>;
  updateDictionary?: InputMaybe<StackCardItemUpdateDictionaryIo>;
  variableName?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<StackCardItemTextIo>;
  whatsappTemplateMessage?: InputMaybe<StackCardItemWhatsappTemplateMessageIo>;
  writeResult?: InputMaybe<StackCardItemWriteResultIo>;
};

export type StackCardItemLelapaConnect = StackCardItem & {
  __typename?: "StackCardItemLelapaConnect";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemLelapaConnectPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLelapaConnectIo = {
  token: Scalars["String"];
};

export type StackCardItemLelapaConnectPayload = {
  __typename?: "StackCardItemLelapaConnectPayload";
  token: Scalars["String"];
};

export type StackCardItemLelapaEntityRecognition = StackCardItem & {
  __typename?: "StackCardItemLelapaEntityRecognition";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemLelapaEntityRecognitionPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLelapaEntityRecognitionIo = {
  connection: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemLelapaEntityRecognitionPayload = {
  __typename?: "StackCardItemLelapaEntityRecognitionPayload";
  connection: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemLelapaIntentClassification = StackCardItem & {
  __typename?: "StackCardItemLelapaIntentClassification";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemLelapaIntentClassificationPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLelapaIntentClassificationIo = {
  connection: Scalars["String"];
  examples: Array<Scalars["String"]>;
  input: Scalars["String"];
};

export type StackCardItemLelapaIntentClassificationPayload = {
  __typename?: "StackCardItemLelapaIntentClassificationPayload";
  connection: Scalars["String"];
  examples: Array<Scalars["String"]>;
  input: Scalars["String"];
};

export type StackCardItemLelapaSentimentAnalysis = StackCardItem & {
  __typename?: "StackCardItemLelapaSentimentAnalysis";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemLelapaSentimentAnalysisPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLelapaSentimentAnalysisIo = {
  connection: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemLelapaSentimentAnalysisPayload = {
  __typename?: "StackCardItemLelapaSentimentAnalysisPayload";
  connection: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemLelapaTranslate = StackCardItem & {
  __typename?: "StackCardItemLelapaTranslate";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemLelapaTranslatePayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLelapaTranslateIo = {
  connection: Scalars["String"];
  sourceLang: Scalars["String"];
  targetLang: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemLelapaTranslatePayload = {
  __typename?: "StackCardItemLelapaTranslatePayload";
  connection: Scalars["String"];
  text: Scalars["String"];
};

export type StackCardItemList = StackCardItem & {
  __typename?: "StackCardItemList";
  items: Array<StackCardItem>;
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemListButtonReference>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemListBlockIo = {
  items: Array<StackCardItemIo>;
  payload: StackCardItemListBlockPayloadIo;
};

export type StackCardItemListBlockPayloadIo = {
  callToAction: Scalars["String"];
  listButtons: Array<CardReferenceIo>;
};

export type StackCardItemListButtonReference = {
  __typename?: "StackCardItemListButtonReference";
  callToAction?: Maybe<Scalars["String"]>;
  listButtons: Array<CardReference>;
};

export type StackCardItemLiteral = StackCardItem & {
  __typename?: "StackCardItemLiteral";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemLog = StackCardItem & {
  __typename?: "StackCardItemLog";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiAddFunction = StackCardItem & {
  __typename?: "StackCardItemOpenaiAddFunction";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiAddFunctionPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiAddFunctionIo = {
  connection: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  parameters: Scalars["String"];
};

export type StackCardItemOpenaiAddFunctionPayload = {
  __typename?: "StackCardItemOpenaiAddFunctionPayload";
  connection: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  parameters: Scalars["String"];
};

export type StackCardItemOpenaiAddImage = StackCardItem & {
  __typename?: "StackCardItemOpenaiAddImage";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiAddImagePayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiAddImageIo = {
  connection: Scalars["String"];
  maxTokens: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
  url: Scalars["String"];
};

export type StackCardItemOpenaiAddImagePayload = {
  __typename?: "StackCardItemOpenaiAddImagePayload";
  connection: Scalars["String"];
  maxTokens: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
  url: Scalars["String"];
};

export type StackCardItemOpenaiAddMessage = StackCardItem & {
  __typename?: "StackCardItemOpenaiAddMessage";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiAddMessagePayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiAddMessageIo = {
  connection: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
};

export type StackCardItemOpenaiAddMessagePayload = {
  __typename?: "StackCardItemOpenaiAddMessagePayload";
  connection: Scalars["String"];
  message: Scalars["String"];
  role: Scalars["String"];
};

export type StackCardItemOpenaiAddMessages = StackCardItem & {
  __typename?: "StackCardItemOpenaiAddMessages";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiAddMessagesPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiAddMessagesIo = {
  connection: Scalars["String"];
  messages: Array<Scalars["String"]>;
  role: Scalars["String"];
};

export type StackCardItemOpenaiAddMessagesPayload = {
  __typename?: "StackCardItemOpenaiAddMessagesPayload";
  connection: Scalars["String"];
  messages: Array<Scalars["String"]>;
  role: Scalars["String"];
};

export type StackCardItemOpenaiAssistant = StackCardItem & {
  __typename?: "StackCardItemOpenaiAssistant";
  meta: Array<KeyValuePair>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiChatCompletion = StackCardItem & {
  __typename?: "StackCardItemOpenaiChatCompletion";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiChatCompletionPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiChatCompletionIo = {
  connection: Scalars["String"];
  jsonSchema?: InputMaybe<Scalars["Json"]>;
  responseFormat?: InputMaybe<AiResponseFormat>;
};

export type StackCardItemOpenaiChatCompletionPayload = {
  __typename?: "StackCardItemOpenaiChatCompletionPayload";
  connection: Scalars["String"];
  jsonSchema?: Maybe<Scalars["Json"]>;
  responseFormat?: Maybe<AiResponseFormat>;
};

export type StackCardItemOpenaiConnect = StackCardItem & {
  __typename?: "StackCardItemOpenaiConnect";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiConnectPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiConnectIo = {
  model: Scalars["String"];
  token: Scalars["String"];
};

export type StackCardItemOpenaiConnectPayload = {
  __typename?: "StackCardItemOpenaiConnectPayload";
  model: Scalars["String"];
  token: Scalars["String"];
};

export type StackCardItemOpenaiCreateSpeech = StackCardItem & {
  __typename?: "StackCardItemOpenaiCreateSpeech";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiCreateSpeechPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiCreateSpeechIo = {
  connection: Scalars["String"];
  model: Scalars["String"];
  text: Scalars["String"];
  voice: Scalars["String"];
};

export type StackCardItemOpenaiCreateSpeechPayload = {
  __typename?: "StackCardItemOpenaiCreateSpeechPayload";
  connection: Scalars["String"];
  model: Scalars["String"];
  text: Scalars["String"];
  voice: Scalars["String"];
};

export type StackCardItemOpenaiTranscribe = StackCardItem & {
  __typename?: "StackCardItemOpenaiTranscribe";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemOpenaiTranscribePayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemOpenaiTranscribeIo = {
  connection: Scalars["String"];
  language: Scalars["String"];
  prompt: Scalars["String"];
  url: Scalars["String"];
};

export type StackCardItemOpenaiTranscribePayload = {
  __typename?: "StackCardItemOpenaiTranscribePayload";
  connection: Scalars["String"];
  language: Scalars["String"];
  prompt: Scalars["String"];
  url: Scalars["String"];
};

export type StackCardItemRunStack = StackCardItem & {
  __typename?: "StackCardItemRunStack";
  meta: Array<KeyValuePair>;
  payload?: Maybe<RunStackParameters>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemScheduleStack = StackCardItem & {
  __typename?: "StackCardItemScheduleStack";
  meta: Array<KeyValuePair>;
  payload?: Maybe<ScheduleStackParameters>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemSendContent = StackCardItem & {
  __typename?: "StackCardItemSendContent";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardItemSendContentPayload>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemSendContentIo = {
  contentUuid: Scalars["String"];
  waitForInput?: InputMaybe<Scalars["String"]>;
};

export type StackCardItemSendContentPayload = {
  __typename?: "StackCardItemSendContentPayload";
  contentUuid: Scalars["String"];
  waitForInput: Scalars["String"];
};

export type StackCardItemSetChatProperty = StackCardItem & {
  __typename?: "StackCardItemSetChatProperty";
  meta: Array<KeyValuePair>;
  payload: StackCardSetChatProperty;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemSetChatPropertyIo = {
  assignTo?: InputMaybe<Scalars["String"]>;
};

export type StackCardItemSetMessageProperty = StackCardItem & {
  __typename?: "StackCardItemSetMessageProperty";
  meta: Array<KeyValuePair>;
  payload: StackCardSetMessageProperty;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemSetMessagePropertyIo = {
  label?: InputMaybe<Scalars["String"]>;
};

export type StackCardItemText = StackCardItem & {
  __typename?: "StackCardItemText";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemTextIo = {
  payload?: InputMaybe<Scalars["String"]>;
};

export type StackCardItemThen = StackCardItem & {
  __typename?: "StackCardItemThen";
  meta: Array<KeyValuePair>;
  payload: StackCardItemThenPayload;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemThenIo = {
  card: Scalars["String"];
  expression?: InputMaybe<ExpressionIo>;
};

export type StackCardItemThenPayload = {
  __typename?: "StackCardItemThenPayload";
  card: Scalars["String"];
  expression?: Maybe<Expression>;
};

export type StackCardItemUpdateContact = StackCardItem & {
  __typename?: "StackCardItemUpdateContact";
  meta: Array<KeyValuePair>;
  payload: Array<KeyValuePair>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemUpdateContactIo = {
  payload: Array<KeyValuePairIo>;
};

export type StackCardItemUpdateDictionary = StackCardItem & {
  __typename?: "StackCardItemUpdateDictionary";
  meta: Array<KeyValuePair>;
  payload: UpdateDictionaryParameters;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemUpdateDictionaryIo = {
  key: Scalars["String"];
  reference: Scalars["String"];
  value: Scalars["String"];
};

export type StackCardItemVideo = StackCardItem & {
  __typename?: "StackCardItemVideo";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemWebhook = StackCardItem & {
  __typename?: "StackCardItemWebhook";
  meta: Array<KeyValuePair>;
  payload?: Maybe<StackCardWebhook>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemWhatsappFlow = StackCardItem & {
  __typename?: "StackCardItemWhatsappFlow";
  meta: Array<KeyValuePair>;
  payload?: Maybe<Scalars["String"]>;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemWhatsappTemplateMessage = StackCardItem & {
  __typename?: "StackCardItemWhatsappTemplateMessage";
  buttons?: Maybe<Array<CardReference>>;
  mediaItems?: Maybe<Array<StackCardItem>>;
  meta: Array<KeyValuePair>;
  payload: StackCardItemWhatsappTemplateMessagePayload;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemWhatsappTemplateMessageIo = {
  bodyParams?: InputMaybe<Array<Scalars["String"]>>;
  buttons?: InputMaybe<Array<CardReferenceIo>>;
  headerParams?: InputMaybe<Array<Scalars["String"]>>;
  language: Scalars["String"];
  mediaItems?: InputMaybe<Array<StackCardItemIo>>;
  name: Scalars["String"];
  urlParams?: InputMaybe<Array<Scalars["String"]>>;
};

export type StackCardItemWhatsappTemplateMessagePayload = {
  __typename?: "StackCardItemWhatsappTemplateMessagePayload";
  bodyParams?: Maybe<Array<Scalars["String"]>>;
  headerParams?: Maybe<Array<Scalars["String"]>>;
  language: Scalars["String"];
  name: Scalars["String"];
  urlParams?: Maybe<Array<Scalars["String"]>>;
};

export type StackCardItemWriteResult = StackCardItem & {
  __typename?: "StackCardItemWriteResult";
  label?: Maybe<Scalars["String"]>;
  meta: Array<KeyValuePair>;
  payload: KeyValuePair;
  type: StackCardPlanStep;
  variableName?: Maybe<Scalars["String"]>;
};

export type StackCardItemWriteResultIo = {
  label?: InputMaybe<Scalars["String"]>;
  payload: KeyValuePairIo;
};

export type StackCardPlan = {
  __typename?: "StackCardPlan";
  primary?: Maybe<StackCardPlanStep>;
  secondary?: Maybe<Array<Maybe<StackCardPlanStep>>>;
};

export enum StackCardPlanStep {
  AI_ADD_FUNCTION = "AI_ADD_FUNCTION",
  AI_ADD_MESSAGE = "AI_ADD_MESSAGE",
  AI_ADD_MESSAGES = "AI_ADD_MESSAGES",
  AI_ASSISTANT = "AI_ASSISTANT",
  AI_CHAT_COMPLETION = "AI_CHAT_COMPLETION",
  AI_CONNECT = "AI_CONNECT",
  AI_TEXT_CLASSIFICATION = "AI_TEXT_CLASSIFICATION",
  ASK = "ASK",
  AUDIO = "AUDIO",
  BUTTON = "BUTTON",
  BUTTON_BLOCK = "BUTTON_BLOCK",
  DOCUMENT = "DOCUMENT",
  DYNAMIC_BUTTON = "DYNAMIC_BUTTON",
  DYNAMIC_LIST = "DYNAMIC_LIST",
  EXPRESSION = "EXPRESSION",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
  IMAGE = "IMAGE",
  LELAPA_CONNECT = "LELAPA_CONNECT",
  LELAPA_ENTITY_RECOGNITION = "LELAPA_ENTITY_RECOGNITION",
  LELAPA_INTENT_CLASSIFICATION = "LELAPA_INTENT_CLASSIFICATION",
  LELAPA_SENTIMENT_ANALYSIS = "LELAPA_SENTIMENT_ANALYSIS",
  LELAPA_TRANSLATE = "LELAPA_TRANSLATE",
  LIST = "LIST",
  LIST_BLOCK = "LIST_BLOCK",
  LITERAL = "LITERAL",
  LOG = "LOG",
  OPENAI_ADD_FUNCTION = "OPENAI_ADD_FUNCTION",
  OPENAI_ADD_IMAGE = "OPENAI_ADD_IMAGE",
  OPENAI_ADD_MESSAGE = "OPENAI_ADD_MESSAGE",
  OPENAI_ADD_MESSAGES = "OPENAI_ADD_MESSAGES",
  OPENAI_ASSISTANT = "OPENAI_ASSISTANT",
  OPENAI_CHAT_COMPLETION = "OPENAI_CHAT_COMPLETION",
  OPENAI_CONNECT = "OPENAI_CONNECT",
  OPENAI_CREATE_SPEECH = "OPENAI_CREATE_SPEECH",
  OPENAI_TRANSCRIBE = "OPENAI_TRANSCRIBE",
  PROMPT = "PROMPT",
  RUN_STACK = "RUN_STACK",
  SCHEDULE_STACK = "SCHEDULE_STACK",
  SEND_CONTENT = "SEND_CONTENT",
  SET_CHAT_PROPERTY = "SET_CHAT_PROPERTY",
  SET_MESSAGE_PROPERTY = "SET_MESSAGE_PROPERTY",
  TEXT = "TEXT",
  THEN = "THEN",
  UPDATE_CONTACT = "UPDATE_CONTACT",
  UPDATE_DICTIONARY = "UPDATE_DICTIONARY",
  VIDEO = "VIDEO",
  WEBHOOK = "WEBHOOK",
  WHATSAPP_FLOW = "WHATSAPP_FLOW",
  WHATSAPP_TEMPLATE_MESSAGE = "WHATSAPP_TEMPLATE_MESSAGE",
  WRITE_RESULT = "WRITE_RESULT",
}

export type StackCardSetChatProperty = {
  __typename?: "StackCardSetChatProperty";
  assignedTo?: Maybe<Scalars["String"]>;
};

export type StackCardSetMessageProperty = {
  __typename?: "StackCardSetMessageProperty";
  label: Scalars["String"];
  message: Scalars["String"];
  metadata: Array<KeyValuePair>;
};

export type StackCardWebhook = {
  __typename?: "StackCardWebhook";
  body?: Maybe<Scalars["String"]>;
  cacheTtl?: Maybe<Scalars["Int"]>;
  expression?: Maybe<Scalars["Boolean"]>;
  headers: Array<KeyValuePair>;
  method?: Maybe<WebhookHttpMethodType>;
  mode?: Maybe<WebhookModeType>;
  query?: Maybe<Scalars["String"]>;
  timeout?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
  variableName?: Maybe<Scalars["String"]>;
};

/** A Stack container */
export type StackContainer = {
  __typename?: "StackContainer";
  averageInteractionSeconds: Scalars["Float"];
  completedTimes: Scalars["Int"];
  enabled: Scalars["Boolean"];
  flowContainer?: Maybe<FlowContainer>;
  /** Whether or not the journey has some changes (NotebookRevisions) that are not published yet. */
  hasUnpublishedChanges: Scalars["Boolean"];
  initiatedTimes: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  isCanvas: Scalars["Boolean"];
  isNotebookValid?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  notebook: NotebookType;
  /**
   * Paginated list of revisions for the journey's notebook.
   * Every revision stores a version of the notebook and it is possible
   * to revert the Journey to a previous version of the notebook.
   */
  notebookRevisions: NotebookRevisionConnection;
  /** UUID of the NotebookRevision that is currently published to production. */
  productionNotebookRevisionUuid?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  /** Triggers definitions as present in the DB for this particular StackContainer. */
  triggers: Array<Trigger>;
  updatedAt: Scalars["DateTime"];
  uuid: Scalars["ID"];
};

/** A Stack container */
export type StackContainerNotebookRevisionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onlyNamedRevisions?: InputMaybe<Scalars["Boolean"]>;
};

/** Preview of a stack container, it doesn't contain the notebook and FLOIP container */
export type StackContainerPreview = {
  __typename?: "StackContainerPreview";
  averageInteractionSeconds: Scalars["Float"];
  completedTimes: Scalars["Int"];
  enabled: Scalars["Boolean"];
  hasUnpublishedChanges: Scalars["Boolean"];
  initiatedTimes: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  isCanvas: Scalars["Boolean"];
  isNotebookValid?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  readOnly: Scalars["Boolean"];
  triggers: Array<Trigger>;
  updatedAt: Scalars["DateTime"];
  uuid: Scalars["ID"];
};

/** Stack Container Previews results */
export type StackContainerPreviewsResults = {
  __typename?: "StackContainerPreviewsResults";
  count: Scalars["Int"];
  stackContainerPreviews: Array<StackContainerPreview>;
};

export type StackIssue = {
  __typename?: "StackIssue";
  column?: Maybe<Scalars["Int"]>;
  description: Scalars["String"];
  line?: Maybe<Scalars["Int"]>;
  snippet?: Maybe<Scalars["String"]>;
  type: StackIssueType;
};

export enum StackIssueType {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export type StackLanguage = {
  __typename?: "StackLanguage";
  code: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
};

/** The possible types for a status message */
export enum StatusType {
  DELETED = "DELETED",
  DELIVERED = "DELIVERED",
  ENVELOPE_MISMATCH = "ENVELOPE_MISMATCH",
  FAILED = "FAILED",
  MEDIA_TRANSFER_ERROR = "MEDIA_TRANSFER_ERROR",
  PENDING = "PENDING",
  READ = "READ",
  SENDING = "SENDING",
  SENT = "SENT",
  STRUCTURE_UNAVAILABLE = "STRUCTURE_UNAVAILABLE",
  SUBMITTED = "SUBMITTED",
  UNSENT = "UNSENT",
}

/** Stream state */
export type StreamState = {
  __typename?: "StreamState";
  lastSync?: Maybe<Scalars["DateTime"]>;
  table?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export enum SubscriptionPlan {
  ADDITIONAL_LINE = "ADDITIONAL_LINE",
  ENTERPRISE_ANNUAL_NO_TRIAL = "ENTERPRISE_ANNUAL_NO_TRIAL",
  ENTERPRISE_ANNUAL_TRIAL = "ENTERPRISE_ANNUAL_TRIAL",
  ENTERPRISE_MONTHLY_COMMIT_ANNUAL_NO_TRIAL = "ENTERPRISE_MONTHLY_COMMIT_ANNUAL_NO_TRIAL",
  ENTERPRISE_MONTHLY_COMMIT_ANNUAL_TRIAL = "ENTERPRISE_MONTHLY_COMMIT_ANNUAL_TRIAL",
  ENTERPRISE_MONTHLY_NO_TRIAL = "ENTERPRISE_MONTHLY_NO_TRIAL",
  ENTERPRISE_MONTHLY_RECHARGE = "ENTERPRISE_MONTHLY_RECHARGE",
  ENTERPRISE_MONTHLY_TRIAL = "ENTERPRISE_MONTHLY_TRIAL",
  ENTERPRISE_OFFICE_HOURS_BRONZE = "ENTERPRISE_OFFICE_HOURS_BRONZE",
  ENTERPRISE_OFFICE_HOURS_GOLD = "ENTERPRISE_OFFICE_HOURS_GOLD",
  ENTERPRISE_OFFICE_HOURS_SILVER = "ENTERPRISE_OFFICE_HOURS_SILVER",
  ENTERPRISE_QUARTERLY_COMMIT_ANNUAL_NO_TRIAL = "ENTERPRISE_QUARTERLY_COMMIT_ANNUAL_NO_TRIAL",
  ENTERPRISE_QUARTERLY_COMMIT_ANNUAL_TRIAL = "ENTERPRISE_QUARTERLY_COMMIT_ANNUAL_TRIAL",
  ENTERPRISE_STARTER_PACK = "ENTERPRISE_STARTER_PACK",
  ENTERPRISE_SUPPORT_ANNUAL = "ENTERPRISE_SUPPORT_ANNUAL",
  ENTERPRISE_SUPPORT_MONTHLY = "ENTERPRISE_SUPPORT_MONTHLY",
  ENTERPRISE_UPGRADE = "ENTERPRISE_UPGRADE",
  ONE_TIME_PRO_MONTHLY_NO_TRIAL = "ONE_TIME_PRO_MONTHLY_NO_TRIAL",
  PRIVATE_CLOUD_FEE = "PRIVATE_CLOUD_FEE",
  PRO_ANNUAL_NO_TRIAL = "PRO_ANNUAL_NO_TRIAL",
  PRO_ANNUAL_TRIAL = "PRO_ANNUAL_TRIAL",
  PRO_MONTHLY_NO_TRIAL = "PRO_MONTHLY_NO_TRIAL",
  PRO_MONTHLY_TRIAL = "PRO_MONTHLY_TRIAL",
  PRO_OFFICE_HOURS_BRONZE = "PRO_OFFICE_HOURS_BRONZE",
  PRO_OFFICE_HOURS_GOLD = "PRO_OFFICE_HOURS_GOLD",
  PRO_OFFICE_HOURS_SILVER = "PRO_OFFICE_HOURS_SILVER",
}

export enum SubscriptionProduct {
  ENTERPRISE = "ENTERPRISE",
  OTHER = "OTHER",
  PRO = "PRO",
}

export enum SubscriptionState {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
  TRIALING = "TRIALING",
}

export type SwitchRequest = {
  __typename?: "SwitchRequest";
  /** @deprecated Switch manual->automatic no longer supported */
  checkoutUrl?: Maybe<Scalars["String"]>;
  /** @deprecated Switch manual->automatic no longer supported */
  status?: Maybe<SwitchRequestStatus>;
};

export enum SwitchRequestStatus {
  /** @deprecated Switch manual->automatic no longer supported */
  ERROR = "ERROR",
  /** @deprecated Switch manual->automatic no longer supported */
  PAYMENT_METHOD_NEEDED = "PAYMENT_METHOD_NEEDED",
  /** @deprecated Switch manual->automatic no longer supported */
  RECEIVED = "RECEIVED",
}

export enum SwitchTargetPrice {
  ENTERPRISE_ANNUAL_TRIAL = "ENTERPRISE_ANNUAL_TRIAL",
  ENTERPRISE_MONTHLY_COMMIT_ANNUAL_TRIAL = "ENTERPRISE_MONTHLY_COMMIT_ANNUAL_TRIAL",
  ENTERPRISE_MONTHLY_TRIAL = "ENTERPRISE_MONTHLY_TRIAL",
  ENTERPRISE_QUARTERLY_TRIAL = "ENTERPRISE_QUARTERLY_TRIAL",
  PRO_ANNUAL_TRIAL = "PRO_ANNUAL_TRIAL",
  PRO_MONTHLY_TRIAL = "PRO_MONTHLY_TRIAL",
}

export type Table = {
  __typename?: "Table";
  frontMatter: FrontMatter;
  headers: Array<Scalars["String"]>;
  name: Scalars["String"];
  rows: Array<Array<KeyValuePair>>;
};

export type TableIo = {
  frontMatter: FrontMatterIo;
  headers: Array<Scalars["String"]>;
  name: Scalars["String"];
  rows: Array<Array<KeyValuePairIo>>;
};

/** A summary of the teammate account */
export type TeammateAccountSummary = {
  __typename?: "TeammateAccountSummary";
  firstName?: Maybe<Scalars["String"]>;
  isAvailable: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  uuid: Scalars["ID"];
};

/** A WhatsApp Message Template object */
export type Template = {
  __typename?: "Template";
  components?: Maybe<Array<Maybe<MessageTemplateComponent>>>;
  language?: Maybe<HsmLanguage>;
  name?: Maybe<Scalars["String"]>;
  namespace?: Maybe<Scalars["String"]>;
};

/** A templated message input component */
export type TemplatedMessageComponent = {
  index?: InputMaybe<Scalars["Int"]>;
  parameters?: InputMaybe<Array<InputMaybe<TemplatedMessageComponentParameter>>>;
  subType?: InputMaybe<MessageTemplateComponentSubType>;
  type: MessageTemplateComponentType;
};

/** A templated message input component parameter */
export type TemplatedMessageComponentParameter = {
  document?: InputMaybe<TemplatedMessageHeaderMediaParameters>;
  image?: InputMaybe<TemplatedMessageHeaderMediaParameters>;
  payload?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  type: MessageTemplateComponentParameterType;
  video?: InputMaybe<TemplatedMessageHeaderMediaParameters>;
};

/** Parameters of a media template header */
export type TemplatedMessageHeaderMediaParameters = {
  filename?: InputMaybe<Scalars["String"]>;
  /** WhatsApp media ID */
  id?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
};

/** A templated message template input object */
export type TemplatedMessageTemplateIo = {
  components?: InputMaybe<Array<InputMaybe<TemplatedMessageComponent>>>;
  language: TemplatedMessageTemplateLanguage;
  name: Scalars["String"];
};

/** A templated message template language */
export type TemplatedMessageTemplateLanguage = {
  code?: InputMaybe<MessageTemplateLanguageType>;
  policy?: InputMaybe<MessageTemplateLanguagePolicyType>;
};

export type Thread = {
  __typename?: "Thread";
  actionCards?: Maybe<Array<Maybe<ActionsCard>>>;
  cardAssociations?: Maybe<Array<Maybe<CardAssocation>>>;
  cards?: Maybe<Array<Maybe<Card>>>;
  insertedAt?: Maybe<Scalars["DateTime"]>;
  language?: Maybe<Scalars["String"]>;
  notebook: NotebookType;
  published?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  triggers?: Maybe<Array<Maybe<AutomatorTrigger>>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type ThreadCardAssociationIo = {
  targetCardUuids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  uuid: Scalars["ID"];
};

/**
 * ThreadEdge
 *
 * `ThreadEdge` instances associate `ThreadNode` instances with each other to
 * form a graph with directed edges describing the flow of a thread.
 */
export type ThreadEdge = {
  __typename?: "ThreadEdge";
  edgeIndex?: Maybe<Scalars["Int"]>;
  graph?: Maybe<ThreadGraph>;
  sourceNode?: Maybe<ThreadNode>;
  targetNode?: Maybe<ThreadNode>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * ThreadGraph
 *
 * `ThreadGraph` instances are the main data structure defining a conversation thread.
 * `ThreadNode` and `ThreadEdge` instances are associated with `ThreadGraph` instances to
 * form a graph with directed edges describing the flow of a thread.
 *
 * Thread Graph instances also store a `title` for a graph and whether or not a graph
 * is `published`.
 */
export type ThreadGraph = {
  __typename?: "ThreadGraph";
  language?: Maybe<Scalars["String"]>;
  published?: Maybe<Scalars["Boolean"]>;
  threadEdges?: Maybe<Array<Maybe<ThreadEdge>>>;
  threadNodes?: Maybe<Array<Maybe<ThreadNode>>>;
  title?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type ThreadJson = {
  __typename?: "ThreadJson";
  json?: Maybe<Scalars["Json"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/**
 * ThreadNode
 *
 * `ThreadNode` instances associate content `Card` or automation `Automator` instances
 * with `ThreadGraph` instances.
 *
 * `ThreadNode` instances are associated with each other via `ThreadEdge` instances
 * to form a graph with directed edges describing the flow of a thread.
 */
export type ThreadNode = {
  __typename?: "ThreadNode";
  automator?: Maybe<Automator>;
  card?: Maybe<Card>;
  graph?: Maybe<ThreadGraph>;
  uuid?: Maybe<Scalars["ID"]>;
};

export type TitleStats = {
  __typename?: "TitleStats";
  conversationClosed?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  newUsers?: Maybe<Scalars["Int"]>;
  receivedMessages?: Maybe<Scalars["Float"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
};

/** The possible statuses of transcriptions, captions, or translations */
export enum TranscriptionsCaptionsAndTranslationsStatusType {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
}

/**
 * The result of our attempt to translate messages in a chat. This includes the
 * number of messages that were scheduled for translation and the UUIDs of the
 * messages that failed to translate.
 */
export type TranslateMessagesResult = {
  __typename?: "TranslateMessagesResult";
  failedMessagesUuids: Array<Maybe<Scalars["ID"]>>;
  scheduledMessagesCount: Scalars["Int"];
};

export type Translation = {
  __typename?: "Translation";
  dictionary?: Maybe<Array<KeyValuePair>>;
  languageCode: Scalars["String"];
};

/** Translation option settings for a language */
export type TranslationOption = {
  __typename?: "TranslationOption";
  insertedAt?: Maybe<Scalars["DateTime"]>;
  languageCode?: Maybe<Scalars["String"]>;
  operatorUuid?: Maybe<Scalars["ID"]>;
  option?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uuid?: Maybe<Scalars["ID"]>;
};

/** The result of a translation preview */
export type TranslationPreview = {
  __typename?: "TranslationPreview";
  translatedContent?: Maybe<Scalars["String"]>;
};

/** Represents a stack Trigger, which defines when the stack should execute */
export type Trigger = {
  __typename?: "Trigger";
  crontab?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  expression?: Maybe<Expression>;
  interval?: Maybe<Scalars["String"]>;
  profileFieldName?: Maybe<Scalars["String"]>;
  targetTime?: Maybe<Scalars["Time"]>;
  triggerAt?: Maybe<Scalars["DateTime"]>;
  type: TriggerType;
  until?: Maybe<Scalars["DateTime"]>;
};

export type TriggerIo = {
  crontab?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  expression?: InputMaybe<ExpressionIo>;
  interval?: InputMaybe<Scalars["String"]>;
  profileFieldName?: InputMaybe<Scalars["String"]>;
  targetTime?: InputMaybe<Scalars["Time"]>;
  triggerAt?: InputMaybe<Scalars["DateTime"]>;
  type: TriggerType;
  until?: InputMaybe<Scalars["DateTime"]>;
};

export enum TriggerType {
  CATCH_ALL = "CATCH_ALL",
  CRON = "CRON",
  FIRST_TIME = "FIRST_TIME",
  INBOUND_MESSAGE = "INBOUND_MESSAGE",
  QUICK_REPLY_BUTTON = "QUICK_REPLY_BUTTON",
  RELATIVE_TO_PROFILE_FIELD = "RELATIVE_TO_PROFILE_FIELD",
  SPECIFIC_TIME = "SPECIFIC_TIME",
}

/** All possible types of rules */
export enum TriggerTypeType {
  BUTTON = "BUTTON",
  LIST = "LIST",
  MESSAGE_INBOUND = "MESSAGE_INBOUND",
  MESSAGE_INBOUND_CATCH_ALL = "MESSAGE_INBOUND_CATCH_ALL",
  TIMER = "TIMER",
}

export type UnitPrice = {
  __typename?: "UnitPrice";
  amount: Scalars["String"];
  currencyCode?: Maybe<PaddleCurrencyCode>;
};

export type UpdateDictionaryParameters = {
  __typename?: "UpdateDictionaryParameters";
  key: Scalars["String"];
  reference: Scalars["String"];
  value: Scalars["String"];
};

export type UsersStats = {
  __typename?: "UsersStats";
  data?: Maybe<UsersStatsItem>;
  totalNewUsers?: Maybe<Scalars["Int"]>;
  totalReturnedUsers?: Maybe<Scalars["Int"]>;
  totalUsers?: Maybe<Scalars["Int"]>;
};

export type UsersStatsDetails = {
  __typename?: "UsersStatsDetails";
  count?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
};

export type UsersStatsItem = {
  __typename?: "UsersStatsItem";
  newUsers?: Maybe<Array<Maybe<UsersStatsDetails>>>;
  returnedUsers?: Maybe<Array<Maybe<UsersStatsDetails>>>;
};

/** WhatsApp Business Account (WABA). A WABA owns zero or more numbers. */
export type Waba = {
  __typename?: "Waba";
  accountReviewStatus?: Maybe<WabaReviewStatus>;
  cachedFreeMonthlyConversationsCount: Scalars["Int"];
  cachedMonthlyConversationsCount: Scalars["Int"];
  conversationsWaived: Scalars["Boolean"];
  conversationsWaiverCustomAmount?: Maybe<Scalars["Int"]>;
  /** Facebook Business that owns the WABA */
  facebookBusiness?: Maybe<FacebookBusiness>;
  fbGraphId: Scalars["ID"];
  impactArea?: Maybe<ImpactSectorType>;
  messageTemplateNamespace: Scalars["String"];
  organisationType?: Maybe<OrganisationEntityType>;
  uuid: Scalars["ID"];
};

/** Response to checkimng whether WABA is available or taken by a different organisation */
export type WabaAvailabilityStatus = {
  __typename?: "WabaAvailabilityStatus";
  isAvailable: Scalars["Boolean"];
  wabaGraphId: Scalars["String"];
};

/** The review status of a WABA. */
export enum WabaReviewStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum WabizClientProvisionState {
  NOT_PROVISIONED = "NOT_PROVISIONED",
  PROVISIONED = "PROVISIONED",
  UNKNOWN = "UNKNOWN",
}

/** A Webhook for a number */
export type Webhook = {
  __typename?: "Webhook";
  enabled?: Maybe<Scalars["Boolean"]>;
  hmacDigest?: Maybe<HmacDigestType>;
  hmacSecret?: Maybe<Scalars["String"]>;
  optInOnly?: Maybe<Scalars["Boolean"]>;
  primary?: Maybe<Scalars["Boolean"]>;
  subscriptions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  url?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

export enum WebhookHttpMethodType {
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  TRACE = "TRACE",
}

export enum WebhookModeType {
  ASYNC = "ASYNC",
  SYNC = "SYNC",
}

export type WebhookTelemetry = {
  __typename?: "WebhookTelemetry";
  requestData: Array<Maybe<RequestData>>;
  type: WebhookTelemetryType;
  url: Scalars["String"];
};

export enum WebhookTelemetryType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

/**
 * The WhatsApp tier the number is on. A number's tier decides
 * how many business-initiated conversations the business can start
 * in a rolling 24 hour window.
 */
export enum WhatsappTier {
  TIER_1K = "TIER_1K",
  TIER_10K = "TIER_10K",
  TIER_50 = "TIER_50",
  TIER_100K = "TIER_100K",
  TIER_250 = "TIER_250",
  TIER_UNLIMITED = "TIER_UNLIMITED",
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BillingSpend: ["BillingCountrySpend", "BillingDailySpend", "BillingNumberSpend"],
    ExpressionArg: [
      "ExpressionArgAtom",
      "ExpressionArgAttribute",
      "ExpressionArgBooleanLiteral",
      "ExpressionArgFloatLiteral",
      "ExpressionArgIntegerLiteral",
      "ExpressionArgListOfStringsLiteral",
      "ExpressionArgStringLiteral",
    ],
    ExpressionItem: ["ExpressionConnector", "ExpressionFunction"],
    JourneyDataPayload: ["JourneyDataDictionary", "JourneyDataTable"],
    MessageTemplateComponentParameter: [
      "MessageTemplateComponentParameterDocument",
      "MessageTemplateComponentParameterImage",
      "MessageTemplateComponentParameterPayload",
      "MessageTemplateComponentParameterText",
      "MessageTemplateComponentParameterVideo",
    ],
    MessageTemplateSchemaButton: [
      "MessageTemplateSchemaButtonPhoneNumber",
      "MessageTemplateSchemaButtonQuickReply",
      "MessageTemplateSchemaButtonUrl",
    ],
    MessageTemplateSchemaComponent: [
      "MessageTemplateSchemaComponentBody",
      "MessageTemplateSchemaComponentButtons",
      "MessageTemplateSchemaComponentFooter",
      "MessageTemplateSchemaComponentHeader",
    ],
    NotebookCell: ["NotebookCellCode", "NotebookCellDictionary", "NotebookCellMarkdown", "NotebookCellTable"],
    StackCardItem: [
      "StackCardItemAiAddFunction",
      "StackCardItemAiAddMessage",
      "StackCardItemAiAddMessages",
      "StackCardItemAiAssistant",
      "StackCardItemAiChatCompletion",
      "StackCardItemAiConnect",
      "StackCardItemAiTextClassification",
      "StackCardItemAsk",
      "StackCardItemAudio",
      "StackCardItemButton",
      "StackCardItemDocument",
      "StackCardItemDynamicButton",
      "StackCardItemDynamicList",
      "StackCardItemExpression",
      "StackCardItemFooter",
      "StackCardItemHeader",
      "StackCardItemImage",
      "StackCardItemLelapaConnect",
      "StackCardItemLelapaEntityRecognition",
      "StackCardItemLelapaIntentClassification",
      "StackCardItemLelapaSentimentAnalysis",
      "StackCardItemLelapaTranslate",
      "StackCardItemList",
      "StackCardItemLiteral",
      "StackCardItemLog",
      "StackCardItemOpenaiAddFunction",
      "StackCardItemOpenaiAddImage",
      "StackCardItemOpenaiAddMessage",
      "StackCardItemOpenaiAddMessages",
      "StackCardItemOpenaiAssistant",
      "StackCardItemOpenaiChatCompletion",
      "StackCardItemOpenaiConnect",
      "StackCardItemOpenaiCreateSpeech",
      "StackCardItemOpenaiTranscribe",
      "StackCardItemRunStack",
      "StackCardItemScheduleStack",
      "StackCardItemSendContent",
      "StackCardItemSetChatProperty",
      "StackCardItemSetMessageProperty",
      "StackCardItemText",
      "StackCardItemThen",
      "StackCardItemUpdateContact",
      "StackCardItemUpdateDictionary",
      "StackCardItemVideo",
      "StackCardItemWebhook",
      "StackCardItemWhatsappFlow",
      "StackCardItemWhatsappTemplateMessage",
      "StackCardItemWriteResult",
    ],
  },
};
export default result;
