import gql from "graphql-tag";

import { required } from "api/boundary";

export const EXPRESSION_ARG_ATOM_FRAGMENT = gql`
  fragment ExpressionArgAtomDetails on ExpressionArgAtom {
    type
    atom
  }
`;
export const EXPRESSION_ARG_ATTRIBUTE_FRAGMENT = gql`
  fragment ExpressionArgAttributeDetails on ExpressionArgAttribute {
    type
    attribute
  }
`;

export const EXPRESSION_ARG_LITERAL_FRAGMENT = gql`
  fragment ExpressionArgStringLiteralDetails on ExpressionArgStringLiteral {
    stringLiteral
    type
  }
  fragment ExpressionArgBooleanLiteralDetails on ExpressionArgBooleanLiteral {
    booleanLiteral
    type
  }
  fragment ExpressionArgIntegerLiteralDetails on ExpressionArgIntegerLiteral {
    integerLiteral
    type
  }
  fragment ExpressionArgFloatLiteralDetails on ExpressionArgFloatLiteral {
    floatLiteral
    type
  }
  fragment ExpressionArgListOfStringsLiteralDetails on ExpressionArgListOfStringsLiteral {
    listOfStrings
    type
  }
`;

export const EXPRESSION_CONNECTOR_FRAGMENT = gql`
  fragment ExpressionConnectorDetails on ExpressionConnector {
    connector
  }
`;

export const EXPRESSION_FUNCTION_FRAGMENT = gql`
  fragment ExpressionFunctionDetails on ExpressionFunction {
    hasNegation
    type
    args {
      ...ExpressionArgAtomDetails
      ...ExpressionArgAttributeDetails
      ...ExpressionArgStringLiteralDetails
      ...ExpressionArgBooleanLiteralDetails
      ...ExpressionArgIntegerLiteralDetails
      ...ExpressionArgFloatLiteralDetails
      ...ExpressionArgListOfStringsLiteralDetails
    }
  }
  ${EXPRESSION_ARG_ATOM_FRAGMENT}
  ${EXPRESSION_ARG_ATTRIBUTE_FRAGMENT}
  ${EXPRESSION_ARG_LITERAL_FRAGMENT}
`;

export const EXPRESSION_FRAGMENT = gql`
  fragment ExpressionDetails on Expression {
    items {
      ...ExpressionConnectorDetails
      ...ExpressionFunctionDetails
    }
    raw
    type
  }
  ${EXPRESSION_CONNECTOR_FRAGMENT}
  ${EXPRESSION_FUNCTION_FRAGMENT}
`;

export const STACK_CARD_ITEM_DETAILS_FRAGMENT = gql`
  fragment StackCardItemDetails on StackCardItem {
    variableName
    type
    meta {
      key
      value
      type
    }
    ... on StackCardItemThen {
      then: payload {
        card
        expression {
          ...ExpressionDetails
        }
      }
    }
    ... on StackCardItemText {
      text: payload
    }
    ... on StackCardItemHeader {
      headerText: payload
    }
    ... on StackCardItemFooter {
      footerText: payload
    }
    ... on StackCardItemButton {
      references: payload {
        card
        label
      }
      items {
        type
        ... on StackCardItemText {
          text: payload
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemVideo {
          url: payload
        }
        ... on StackCardItemAudio {
          url: payload
        }
        ... on StackCardItemDocument {
          url: payload
          filename
        }
        ... on StackCardItemHeader {
          headerText: payload
        }
        ... on StackCardItemFooter {
          footerText: payload
        }
      }
    }
    ... on StackCardItemList {
      listReferences: payload {
        callToAction
        listButtons {
          card
          label
        }
      }
      listItems: items {
        type
        variableName
        ... on StackCardItemText {
          text: payload
        }
        ... on StackCardItemThen {
          then: payload {
            card
            expression {
              ...ExpressionDetails
            }
          }
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemHeader {
          headerText: payload
        }
        ... on StackCardItemFooter {
          footerText: payload
        }
      }
    }
    ... on StackCardItemAsk {
      prompt: payload
    }
    ... on StackCardItemExpression {
      expression: payload
    }
    ... on StackCardItemImage {
      url: payload
    }
    ... on StackCardItemVideo {
      url: payload
    }
    ... on StackCardItemAudio {
      url: payload
    }
    ... on StackCardItemDocument {
      url: payload
      filename
    }
    ... on StackCardItemUpdateContact {
      contactFields: payload {
        key
        value
        type
      }
    }
    ... on StackCardItemWriteResult {
      resultFields: payload {
        key
        value
        type
      }
    }
    ... on StackCardItemWebhook {
      webhook: payload {
        body
        method
        query
        url
        variableName
        expression
        headers {
          key
          value
          type
        }
        mode
        timeout
      }
    }
    ... on StackCardItemRunStack {
      runStack: payload {
        flowId
      }
    }
    ... on StackCardItemSendContent {
      sendContent: payload {
        contentUuid
        waitForInput
      }
    }
    ... on StackCardItemSetChatProperty {
      setChatProperty: payload {
        assignedTo
      }
    }
    ... on StackCardItemSetMessageProperty {
      setMessageProperty: payload {
        label
      }
    }
    ... on StackCardItemWhatsappTemplateMessage {
      whatsappTemplateMessage: payload {
        bodyParams
        headerParams
        urlParams
        language
        name
      }
      mediaItems {
        type
        ... on StackCardItemDocument {
          url: payload
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemAudio {
          url: payload
        }
        ... on StackCardItemVideo {
          url: payload
        }
      }
      buttons {
        card
        label
      }
    }
    ... on StackCardItemExpression {
      prompt: payload
    }
    ... on StackCardItemOpenaiConnect {
      config: payload {
        token
        model
      }
    }
    ... on StackCardItemOpenaiChatCompletion {
      config: payload {
        connection
        responseFormat
        jsonSchema
      }
    }
    ... on StackCardItemOpenaiAddMessage {
      config: payload {
        connection
        role
        message
      }
    }
    ... on StackCardItemOpenaiAddMessages {
      config: payload {
        connection
        role
        messages
      }
    }
    ... on StackCardItemOpenaiAddFunction {
      config: payload {
        connection
        description
        name
        parameters
      }
    }
    ... on StackCardItemOpenaiCreateSpeech {
      config: payload {
        connection
        model
        text
        voice
      }
    }
    ... on StackCardItemOpenaiTranscribe {
      config: payload {
        connection
        language
        prompt
        url
      }
    }
    ... on StackCardItemOpenaiAddImage {
      config: payload {
        connection
        maxTokens
        message
        role
        url
      }
    }
    ... on StackCardItemAiConnect {
      config: payload {
        vendor
        model
        token
      }
    }
    ... on StackCardItemAiAssistant {
      config: payload {
        uuid
      }
    }
    ... on StackCardItemAiAddMessage {
      config: payload {
        connection
        role
        message
      }
    }
    ... on StackCardItemAiAddMessages {
      config: payload {
        connection
        role
        messages
      }
    }
    ... on StackCardItemAiAddFunction {
      config: payload {
        connection
        name
        description
        parameters
      }
    }
    ... on StackCardItemAiChatCompletion {
      config: payload {
        connection
        responseFormat
        jsonSchema
      }
    }
    ... on StackCardItemAiTextClassification {
      config: payload {
        connection
        userInput
        systemPrompt
        options
      }
    }
  }
  ${EXPRESSION_FRAGMENT}
`;

export const STACK_CARD_DETAILS_FRAGMENT = {
  query: gql`
    fragment StackCardDetails on StackCard {
      uuid
      condition
      name
      plan {
        primary
        secondary
      }
      opts {
        key
        value
        type
      }
      items {
        ...StackCardItemDetails
      }
    }

    ${STACK_CARD_ITEM_DETAILS_FRAGMENT}
  `,
  parse: {
    uuid: required(),
  },
};

export const TRIGGER_DETAILS_FRAGMENT = {
  query: gql`
    fragment TriggerDetails on Trigger {
      crontab
      enabled
      expression {
        ...ExpressionDetails
      }
      interval
      profileFieldName
      targetTime
      triggerAt
      type
      until
    }
    ${EXPRESSION_FRAGMENT}
  `,
  parse: {
    enabled: required(),
    type: required(),
  },
};

export const STACK_DETAILS_FRAGMENT = {
  query: gql`
    fragment StackDetails on Stack {
      uuid
      name
      opts {
        key
        value
        type
      }
      cards {
        ...StackCardDetails
      }
      languages {
        code
        label
      }
      translations {
        languageCode
        dictionary {
          key
          value
        }
      }
    }

    ${STACK_CARD_DETAILS_FRAGMENT.query}
  `,
  parse: {
    uuid: required(),
    name: required(),
    cards: STACK_CARD_DETAILS_FRAGMENT.parse,
    triggers: TRIGGER_DETAILS_FRAGMENT.parse,
  },
};

export const NOTEBOOK_CELL_TABLE_FRAGMENT = gql`
  fragment NotebookCellTable on Table {
    name
    frontMatter {
      version
      columns {
        key
        type
      }
    }
    headers
    rows {
      key
      value
      type
    }
  }
`;

export const NOTEBOOK_CELL_FRAGMENT = gql`
  fragment NotebookCellFragment on NotebookCell {
    type
    section {
      name
      x
      y
    }
    ... on NotebookCellCode {
      code: value
      cards {
        displayName
        name
        uuid
        condition
        items {
          ...StackCardItemDetails
        }
        opts {
          key
          type
          value
        }
      }
      issues {
        type
        line
        column
        snippet
        description
      }
      triggers {
        ...TriggerDetails
      }
    }
    ... on NotebookCellDictionary {
      dictionary: value {
        name
        frontMatter {
          version
          columns {
            key
            type
          }
        }
        items {
          key
          value
          type
        }
      }
    }
    ... on NotebookCellMarkdown {
      markdown: value
    }
    ... on NotebookCellTable {
      table: value {
        ...NotebookCellTable
      }
    }
  }

  ${NOTEBOOK_CELL_TABLE_FRAGMENT}
  ${STACK_CARD_ITEM_DETAILS_FRAGMENT}
  ${TRIGGER_DETAILS_FRAGMENT.query}
`;

export const STACK_CONTAINER_PREVIEW_MIN_FRAGMENT = gql`
  fragment StackContainerPreviewMin on StackContainerPreview {
    uuid
    name
    isNotebookValid
    enabled
  }
`;

export const STACK_CONTAINER_PREVIEW_FRAGMENT = {
  query: gql`
    fragment StackContainerPreview on StackContainerPreview {
      ...StackContainerPreviewMin
      enabled
      hasUnpublishedChanges
      updatedAt
      averageInteractionSeconds
      completedTimes
      initiatedTimes
      isCanvas
      triggers {
        ...TriggerDetails
      }
    }

    ${STACK_CONTAINER_PREVIEW_MIN_FRAGMENT}
    ${TRIGGER_DETAILS_FRAGMENT.query}
  `,
  parse: {
    triggers: TRIGGER_DETAILS_FRAGMENT.parse,
  },
};

export const NOTEBOOK_MIN_FRAGMENT = gql`
  fragment NotebookMin on NotebookType {
    cells {
      ...NotebookCellFragment
    }
    stacksValid
  }

  ${NOTEBOOK_CELL_FRAGMENT}
`;

export const NOTEBOOK_DETAILS_FRAGMENT = {
  query: gql`
    fragment NotebookDetails on NotebookType {
      ...NotebookMin
      doc
      stacks {
        ...StackDetails
      }
    }

    ${NOTEBOOK_MIN_FRAGMENT}
    ${STACK_DETAILS_FRAGMENT.query}
  `,
  parse: {
    stacks: STACK_DETAILS_FRAGMENT.parse,
  },
};

export const SAMPLE_NOTEBOOK_DETAILS_FRAGMENT = gql`
  fragment SampleNotebookDetails on SampleNotebook {
    name
    notebook {
      ...NotebookMin
    }
  }

  ${NOTEBOOK_MIN_FRAGMENT}
`;

export const STACK_CONTAINER_DETAILS_FRAGMENT = {
  query: gql`
    fragment StackContainerDetails on StackContainer {
      uuid
      enabled
      name
      hasUnpublishedChanges
      productionNotebookRevisionUuid
      isNotebookValid
      isCanvas
      insertedAt
      updatedAt
      averageInteractionSeconds
      completedTimes
      initiatedTimes
      readOnly
      notebook {
        ...NotebookDetails
      }
      triggers {
        ...TriggerDetails
      }
      notebookRevisions(limit: 1, offset: 0) {
        revisions {
          accountUuid
          insertedAt
          uuid
          versionName
        }
      }
    }

    ${NOTEBOOK_DETAILS_FRAGMENT.query}
    ${TRIGGER_DETAILS_FRAGMENT.query}
  `,
  parse: {
    uuid: required(),
    name: required(),
    notebook: NOTEBOOK_DETAILS_FRAGMENT.parse,
    triggers: TRIGGER_DETAILS_FRAGMENT.parse,
  },
};

export const TRIGGER_META_FRAGMENT = gql`
  fragment TriggerMetaDetails on NotebookCellCode {
    meta {
      approximateAudienceSize
    }
    issues {
      type
      line
      column
      snippet
      description
    }
  }
`;

export const SIMULATOR_FRAGMENT = gql`
  fragment SimulatorOutputMessageTypeContentDetails on SimulatorOutputMessageTypeContent {
    contentType
    mimeType
    eventValue
    rawValue
    value
  }

  fragment SimulatorOutputStepDetails on SimulatorOutputStep {
    messageType
    messageTypeContent {
      ...SimulatorOutputMessageTypeContentDetails
    }
  }

  fragment SimulatorOutputStateFragment on SimulatorOutputState {
    kind
    content {
      ...SimulatorOutputStepDetails
    }
  }

  fragment SimulatorFragment on Simulator {
    uuid
    stacks {
      uuid
      name
    }
    currentPosition {
      card {
        uuid
        name
        displayName
        condition
        meta {
          key
          value
          type
        }
      }
      cardItem {
        type
        meta {
          key
          value
          type
        }
      }
    }
    context {
      language
      mode
      log
      vars
      waitingForUserInput
      finished
    }
    history {
      input
      outputs {
        ...SimulatorOutputStateFragment
      }
    }
    lastUserInput
  }
`;
